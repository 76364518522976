export const DEAL_LOCATION_TYPES = {
  local: 'local',
  national: 'national',
};

export const PAGE_QUERY = {
  claimMyGift: 'isClaimMyGift',
};

export const PAGE_TYPES = {
  account: 'account',
  category: 'category',
  checkout: 'checkout',
  claimExperienceGift: 'claim-gift',
  dashboard: 'dashboard',
  deal: 'deal',
  dealPreview: 'dealPreview',
  earlyBird: 'early-bird',
  extendLanding: 'extendLanding',
  giftFinder: 'gift-finder',
  gifts: 'gifts',
  guide: 'guide',
  homepage: 'homepage',
  mapView: 'map-view',
  other: 'other',
  ppc: 'ppc',
  recentlyViewed: 'recentlyViewed',
  recommended: 'recommended',
  root: 'root',
  search: 'search',
  special: 'special',
  travel: 'travel',
  vipCategory: 'vipCategory',
  vipDeal: 'vipDeal',
  vouchers: 'vouchers',
  wellness: 'wellness-collection',
};

export const VIP_QUALIFIERS = [PAGE_TYPES.vipCategory, PAGE_TYPES.vipDeal];

export const NATIONAL_DEAL_QUALIFIERS = ['shop', 'national-deal'];

export const TRAVEL_DEAL_QUALIFIERS = ['travel', 'escapes'];

export const CATEGORY_PAGE_PATH_QUALIFIERS = ['deals', 'near-me'];

export const DEAL_PAGE_PATH_QUALIFIERS = ['deal', 'email-deal', 'email-deals'];
