export const FONTS = {
  arial: 'Arial',
  helvetica: 'Helvetica',
  sansSerif: 'sans-serif',
};

export const BASE_FONTS = `${FONTS.helvetica}, ${FONTS.arial}, ${FONTS.sansSerif}`;

export const COMMON_COLORS = {
  alert: '#ff384f',
  assure: '#2bab21',
  assurelighter: '#01b901',
  basketcount: '#dc3545',
  bgvalidcolor: '#dff0d8',
  black: '#000000',
  blue: '#015ecc',
  buttonpaylight: '#88C1FF',
  buttonprimary: '#3facd7',
  buttonprimaryhover: '#2792bc',
  buttonsecondary: '#5A266A',
  buttonsecondaryhover: '#3B0051',
  buttontertiary: '#3facd7',
  buttontertiaryhover: '#2792bc',
  countdownnumbers: '#ff0000',
  credit: '#92d050',
  cyfbanner: '#692649',
  darkgrey: '#aaaaaa',
  debit: '#eb3f3c',
  dropshadow: 'rgb(0 0 0 / 40%)',
  green: '#e3efe0',
  greendark: '#5b9b4f',
  greycharcoal: '#333333',
  greylighter: '#d8d8d8',
  greypastel: '#cccccc',
  greyshade: '#707070',
  greyshadelighter: '#888888',
  greyx2lighter: '#f5f5f5',
  greyx3lighter: '#fcfcfc',
  greyxlighter: '#e6e6e6',
  infoboxbg: '#eaf4fd',
  infoboxborder: '#2c90e9',
  infoboxtext: '#1f65a3',
  inputdefaultborder: '#d4d4d4',
  inputfocuscolor: '#3facd7',
  inputiconcolor: '#b3b3b3',
  inputinvalidcolor: '#eb3f3c',
  inputlogincolor: '#79bb32',
  inputvalidcolor: '#79bb32',
  invalidbg: '#fae6e8',
  invalidtext: '#bc3230',
  jet: '#000000',
  lightyellow: '#fbf0d5',
  linkhover: '#23527c',
  linkmidblue: '#1a56a4',
  peppermint: `rgba(0, 235, 171, 0.8)`,
  successcolor: '#56a83b',
  validtext: '#49701e',
  white: '#ffffff',
  yellow: '#c5a05f',
};

export const CASE_TYPES = {
  capitalize: 'capitalize',
  lowerCase: 'lowercase',
  upperCase: 'uppercase',
};

export const ORDER = {
  even: 'EVEN',
  odd: 'ODD',
};
