// eslint-disable-next-line filenames/match-exported
import { TRACKING } from '../../config/constants/action-types';

const initialState = {
  trackingDeals: [],
};

const trackingDealsReducer = (state = initialState, { type, deals }) => {
  switch (type) {
    case TRACKING.SET_TRACKING_DEALS: {
      return {
        ...state,
        trackingDeals: deals,
      };
    }
    default:
      return state;
  }
};

export default trackingDealsReducer;
