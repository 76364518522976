/* eslint-disable react/forbid-component-props */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ThemeContext from '../../../../providers/ThemeProvider';
import { DesktopTravelMenu } from './DesktopMenu';
import { MobileTravelMenu } from './MobileMenu';

export const TravelMenu = ({ list }) => {
  const theme = useContext(ThemeContext);

  return (
    <div className="travel-menu-wrapper">
      <MobileTravelMenu list={list} />
      <DesktopTravelMenu list={list} />

      <style global jsx>{`
        .travel-menu-wrapper {
          min-height: 45px;
          border-bottom: 1px solid ${theme.colors.navborder};
          background-color: ${theme.colors.subnavbackground};
          display: none;
        }

        .list .navigation-item {
          display: flex;
        }

        .list .navigation-item--selected > .navigation-link {
          height: 36px;
        }

        .list .nested-list a {
          color: #666;
        }
        .nested-list {
          background-color: ${theme.colors.white};
          column-count: 2;
          column-gap: 50px;
          width: auto !important;
        }
        .nested-list .navigation-link {
          text-transform: none;
        }
        .nested-list,
        .list {
          margin: 0;
          padding: 0;
        }

        .list .navigation-popover {
          top: 35px;
          width: auto;
          box-shadow: none;
          border: 1px solid #e0e0e0;
          z-index: 1000;
          display: block;
        }

        .list {
          display: flex;
          justify-content: start;
          list-style-type: none;
          flex-wrap: wrap;
          height: auto;
        }

        .list li:last-child {
          margin-right: 0;
        }

        .list a {
          margin-bottom: 0;
        }
        .nested-list .navigation-popover {
          width: auto;
        }
        .nested-list .navigation-item:first-child a {
          font-weight: bold;
        }

        .list > .navigation-item {
          border-top: none;
          display: block;
        }

        .list > .navigation-item > a {
          font-size: 1.2rem;
          line-height: 1;
          color: #666;
          padding: 10px 15px;
          display: block;
          font-weight: 300;
          border: 1px solid rgba(0, 0, 0, 0);
        }

        .nested-list > .navigation-item > a {
          font-size: 1.2rem;
        }

        .list > .navigation-item--open > a {
          z-index: 1001 !important;
        }

        .list .navigation-item a {
          text-transform: none;
        }

        .list li {
          position: relative;
        }
        .list .navigation-item--selected > a {
          color: #5a266a;
          background-color: #fff;
        }

        .list > .navigation-item--open > a {
          border: 1px solid #e0e0e0;
          border-bottom: 1px solid white;
        }

        .list .navigation-item--selected {
          border-bottom: 0;
        }

        .travel-menu-accordion .custom-toggle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: transparent;
          border: 0;
          padding: 12px;
          text-transform: capitalize;
          color: #666;
          width: 100%;
          text-align: left;
        }

        .custom-toggle + .show {
          border-bottom: 1px solid #ccc;
        }

        .travel-menu-accordion > div > button {
          font-weight: 700;
        }

        .travel-menu {
          display: none;
        }
        .travel-menu-accordion {
          display: block;
          position: absolute;
          min-height: 40px;
          max-height: calc(100vh - 119px);
          background-color: white;
          width: 100%;
          z-index: 9999;
          overflow: auto;
        }

        .travel-menu-accordion .navigation-link {
          padding: 3px 12px 3px 43px;
          color: #000;
          display: block;
          font-size: 1.4rem;
          text-transform: none;
        }

        .travel-menu-wrapper + .nav-bar {
          box-shadow: none !important;
        }

        @media (min-width: ${theme.breakpoints.mdUp}) {
          .travel-menu-accordion {
            display: none;
          }
          .travel-menu {
            display: block;
          }
          .travel-menu-wrapper {
            display: block;
            min-height: auto;
          }
        }
      `}</style>
    </div>
  );
};

TravelMenu.propTypes = {
  list: PropTypes.array.isRequired,
  pageType: PropTypes.string,
};
