export const isLightBoxAllowed = () => {
  const currentDate = new Date();
  const startDate = new Date(2_023, 10, 24); // Month is 0-indexed, so 10 represents November
  const endDate = new Date(2_023, 10, 28);

  if (currentDate >= startDate && currentDate <= endDate) {
    return false;
  }

  return true;
};
