import React, { createContext, useState } from 'react';

const HeaderContext = createContext();
export default HeaderContext;

export const ProductIdProvider = ({ children }) => {
  const [headerState, setHeaderState] = useState(0);

  return (
    <HeaderContext.Provider value={{ headerState, setHeaderState }}>
      {children}
    </HeaderContext.Provider>
  );
};
