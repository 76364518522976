import { combineReducers } from 'redux';
import basketReducer from './basket';
import bcorReducer from './bcor';
import checkoutReducer from './checkout';
import dealsReducer from './deals';
import emailReducer from './email';
import filtersReducer from './filters';
import footer from './footer';
import giftingReducer from './gifting';
import loadingReducer from './loading';
import locationsReducer from './locations';
import merchantReducer from './merchant';
import navigationReducer from './navigation';
import scrollerReducer from './scroller';
import tilesReducer from './tiles';
import trackingDealsReducer from './trackingDeals';
import userReducer from './user';
import videoReducer from './video';
import walletReducer from './wallet';
import wishlist from './wishlist';

const root = combineReducers({
  basket: basketReducer,
  bcor: bcorReducer,
  checkout: checkoutReducer,
  deals: dealsReducer,
  email: emailReducer,
  filters: filtersReducer,
  footer,
  gifting: giftingReducer,
  locations: locationsReducer,
  merchant: merchantReducer,
  navigation: navigationReducer,
  profile: loadingReducer,
  scroller: scrollerReducer,
  tiles: tilesReducer,
  tracking: trackingDealsReducer,
  user: userReducer,
  video: videoReducer,
  wallet: walletReducer,
  wishlist,
});

export default root;
