import axios from '../../components/_generic/axiosSplunk/axiosSplunk';
import { NAVIGATION } from '../../config/constants/action-types';
import { DEAL_LOCATION_TYPES } from '../../config/constants/page-types';
import {
  API_HEADER_BRAND,
  SITE_FIVEPM,
  URLDEAL,
  URLNAVIGATIONS,
} from '../../config/setup/setup';
import { getAppPlatform } from '../../helpers/device';
import ErrorTrackingService from '../../helpers/errorTrackingService';
import { getLocationShortName } from '../../helpers/location';
import {
  fivePmLocationsNavigations,
  fivePmNavigationOrder,
} from '../../helpers/navigation';
import { parseWowcherPath, stripOrigin } from '../../helpers/url';

export const getNavigation = (location, ssr = false) => async (dispatch) => {
  try {
    const shortName = getLocationShortName(location);
    const url = `${URLNAVIGATIONS}/${shortName}?range=1-30`;

    const resp = await axios(url, {
      headers: {
        'app-platform': getAppPlatform(),
        brand: API_HEADER_BRAND,
        'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
        'sub-brand': '5pm',
      },
      method: 'GET',
    });

    let allOrderedNavigation = resp?.data;
    if (process.env.NEXT_PUBLIC_SITE === SITE_FIVEPM) {
      // remove one local
      const filteredNavigation = resp?.data?.filter(
        (item) => item?.linkText.toLowerCase() !== 'local',
      );
      // add 3 fivepm locations
      const allNavigation = fivePmLocationsNavigations.concat(
        filteredNavigation,
      );
      // put in required order
      allOrderedNavigation = [];
      fivePmNavigationOrder.forEach((orderedNavigation) => {
        const foundNavigation = allNavigation.find(
          (item) =>
            item?.shortName && item?.shortName === orderedNavigation.shortName,
        );
        if (foundNavigation) {
          foundNavigation.showPopover = orderedNavigation.showPopover;
          foundNavigation.showScotland = orderedNavigation.showScotland;
          if (orderedNavigation.showScotland) {
            // make reqired navigations scotland instead of location
            foundNavigation.url = foundNavigation.url.replace(
              shortName,
              'scotland',
            );
            // also sub nav, scotland instead of location
            if (foundNavigation?.subNavigations) {
              foundNavigation.subNavigations.forEach((subNavigation) => {
                subNavigation.url = subNavigation.url.replace(
                  shortName,
                  'scotland',
                );
              });
            }
          }
          allOrderedNavigation.push(foundNavigation);
        }
      });
    }

    const navigation = allOrderedNavigation.map((item) => {
      const itemUrl = stripOrigin(item.url);
      const meta = parseWowcherPath(itemUrl);

      return {
        ...item,
        isLocal:
          process.env.NEXT_PUBLIC_SITE === SITE_FIVEPM
            ? item.shortName.toLowerCase() === shortName
            : item.linkText.toLowerCase() === 'local',
        isLocalLink: meta?.details?.locationType === DEAL_LOCATION_TYPES.local,
        subNavigations: item.subNavigations.map((sub) => {
          return {
            ...sub,
            url: sub.url,
          };
        }),
        url: item.url,
      };
    });

    dispatch({ list: navigation, ssr, type: NAVIGATION.SET_MENU });

    return true;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return false;
  }
};

export const showDefaultNavigation = () => (dispatch) => {
  dispatch({ ssr: false, type: NAVIGATION.SET_SSR });
};

export const getDealPreviews = (url, count) => async (dispatch) => {
  try {
    const sliceAt = url.indexOf('deals/');
    const route = url.slice(sliceAt + 6);
    const isGifts = url.includes('/gifts');
    const cleanRoute = isGifts
      ? 'gifts'
      : route.replace('shop', 'national-deal');
    const requestUrl = `${URLDEAL}/${cleanRoute}?preview=true&page=0&pageSize=${count}`;

    const resp = await axios(requestUrl, {
      headers: {
        'app-platform': getAppPlatform(),
        brand: API_HEADER_BRAND,
        'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
        'sub-brand': '5pm',
        webapp: true,
      },
      method: 'GET',
    });
    dispatch({
      preview: { [url]: resp.data.deals || null },
      type: NAVIGATION.SET_DEAL_PREVIEW,
    });
  } catch {
    dispatch({
      preview: { [url]: null },
      type: NAVIGATION.SET_DEAL_PREVIEW,
    });
  }
};
