import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import { URLUSERSTATUS, USER_STATUS_MAPPING } from '../config/setup/setup';
import httpCommonHeaders from './httpCommonHeaders';

export const getUserStatus = async (email) => {
  try {
    const { data } = await axios({
      cache: false,
      data: { requestSubscriptionDetails: { email } },
      headers: httpCommonHeaders(),
      method: 'POST',
      url: URLUSERSTATUS,
      withCredentials: true,
    });

    const accountStatus = Object.entries(data.data).reduce(
      (accumulator, [key, value]) => {
        const mappedKey = USER_STATUS_MAPPING[key];

        if (mappedKey) {
          accumulator[mappedKey] = value;
        }

        return accumulator;
      },
      {},
    );

    return {
      hasPassword: accountStatus?.hasPassword,
      isLocked: accountStatus.isLocked,
      isNewUser: !accountStatus.isRegistered,
      status: accountStatus,
      tpl: accountStatus?.tpl,
      userEmail: email,
    };
  } catch {
    return null;
  }
};
