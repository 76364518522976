import { useRouter } from 'next/router';
import React, { useState, useEffect, useContext } from 'react';
import { isMobileViewport, useScreenBreakpoint } from '../helpers/screen';
import { parseWowcherPath } from '../helpers/url';
import ThemeContext from '../providers/ThemeProvider';

const Colorwash = () => {
  const theme = useContext(ThemeContext);
  const [isMobile, setIsMobile] = useState(false);
  const breakpoint = useScreenBreakpoint();

  useEffect(() => {
    setIsMobile(isMobileViewport());
  }, [breakpoint]);

  if (!theme.images.bgcolorwash || isMobile) return null;

  return (
    <div className="colourwash">
      <style jsx>{`
        .colourwash {
          z-index: -1;
          width: 100%;
          height: 100%;
          position: fixed;
          background-size: 100% auto;
          background-position: 100% auto;
          background-repeat: none;
          top: 0px;
          left: 0px;
          background-image: url(${theme.images.bgcolorwash});
        }
      `}</style>
    </div>
  );
};

export default Colorwash;
