import { SEARCH_PAGE_SIZE } from '../config/links/links';
import { GUIDESEARCHURL, URLDEAL } from '../config/setup/setup';
import { isSSR } from './ssr';

export const guideUrl = ({
  dataSearchTerm,
  dataSearchTag,
  dataLocation,
  dataCategory,
  dataSubCategory,
  dealMinPrice,
  dealMaxPrice,
  dealOrderBy,
  dealOrderDirection,
  dataCategoryExclude,
  dataSubCategoryExclude,
  dataExcludeDealId,
  dataPageSize,
  userLocation,
}) => {
  let baseUrl;
  let path;
  const searchParameters = new URLSearchParams();
  if (dataSearchTerm) {
    // search - use term from config
    baseUrl = GUIDESEARCHURL;
    path = dataLocation || userLocation;
    searchParameters.set('q', dataSearchTerm);
    if (dataCategory) searchParameters.set('category', dataCategory);
    if (dataSubCategory) searchParameters.set('subccategory', dataSubCategory);
    if (dealOrderBy) searchParameters.set('orderBy', dealOrderBy);
    if (dealOrderDirection) searchParameters.set('order', dealOrderDirection);
    if (dataCategoryExclude)
      searchParameters.set('excludecategory', dataCategoryExclude);
    if (dataSubCategoryExclude)
      searchParameters.set('excludesubcategory', dataSubCategoryExclude);
    if (dataExcludeDealId)
      searchParameters.set('excludedealids', dataExcludeDealId);
  } else if (dataSearchTag) {
    // special - deal/[location]/special/[searchTag]
    baseUrl = URLDEAL;
    path = `${dataLocation || userLocation}/special/${dataSearchTag}`;
  } else if (dataCategory) {
    // deal/[location]/[category] OR
    // deal/[location]/[category]/[subcategory]
    baseUrl = URLDEAL;
    path = [dataLocation || userLocation, dataCategory, dataSubCategory]
      .filter((step) => Boolean(step)) // remove empties
      .join('/');
  } else if (dataLocation) {
    // deal/[location]
    baseUrl = URLDEAL;
    path = dataLocation;
  } else {
    return null;
  }

  // common to all calls
  const pageSizeParameter = dataPageSize ? dataPageSize : SEARCH_PAGE_SIZE;
  searchParameters.set('pageSize', pageSizeParameter);
  if (dealMinPrice) searchParameters.set('minPrice', dealMinPrice);
  if (dealMaxPrice) searchParameters.set('maxPrice', dealMaxPrice);

  return {
    baseUrl,
    path,
    searchParameters,
    toString: () => `${baseUrl}/${path}?${searchParameters.toString()}`,
  };
};

export const guideGetKeyGenerator = (config = {}) => {
  if (isSSR()) return () => null;

  const { baseUrl, path, searchParameters } = guideUrl(config);

  return (startPageNr) => (pageNr, previousPageData) => {
    if (previousPageData && !previousPageData.length) return null;
    searchParameters.set('page', pageNr + startPageNr);

    return [
      `${baseUrl}/${path}?${searchParameters.toString()}`,
      config?.allLocations,
    ];
  };
};
