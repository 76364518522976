import { WALLET } from '../../config/constants/action-types';

const initialState = {
  hasError: false,
  userWallet: [],
};

const walletReducer = (state = initialState, { type, userWallet }) => {
  switch (type) {
    case WALLET.SET_WALLET: {
      return {
        ...state,
        hasError: false,
        userWallet,
      };
    }
    case WALLET.ERROR_WALLET: {
      return {
        ...state,
        hasError: true,
        userWallet: [],
      };
    }
    case WALLET.RESET_WALLET: {
      return {
        ...state,
        hasError: false,
        userWallet: [],
      };
    }
    default:
      return state;
  }
};

export default walletReducer;
