import { DEALS } from '../../config/constants/action-types';
import { DESKTOP_PAGE_SIZE } from '../../config/links/links';

export const setDeals = ({
  mainDeal,
  facetedNavigation,
  deals,
  secondaryDealsArray,
  ssr,
  evergreenUrl,
  canonicalUrl,
  redirectUrl,
  searchResult,
  isPreview = false,
}) => (dispatch) => {
  // We need secondaryDealsArray array for the Preview, but not a mainDeal
  // Otherwise we have a blink with replacing MainDeal FEF-2397
  if (isPreview)
    return dispatch({
      canonicalUrl,
      deals,
      evergreenUrl,
      facetedNavigation,
      mainDeal,
      redirectUrl,
      searchResult,
      secondaryDealsArray,
      ssr,
      type: DEALS.SET_FOR_DEAL_PREVIEW,
    });

  if (mainDeal && Object.keys(mainDeal).length !== 0)
    return dispatch({
      canonicalUrl,
      deals,
      evergreenUrl,
      facetedNavigation,
      mainDeal,
      redirectUrl,
      searchResult,
      secondaryDealsArray,
      ssr,
      type: DEALS.SET_DEALS,
    });

  return dispatch({
    canonicalUrl,
    deals,
    facetedNavigation,
    searchResult,
    ssr,
    type: DEALS.SET_WITHOUT_MAIN_DEAL,
  });
};

export const setExtraDeals = (deals) => (dispatch) => {
  return dispatch({
    newdeals: deals,
    type: DEALS.SET_EXTRA_DEALS,
  });
};

export const setOnlyMainDeal = (mainDeal) => (dispatch) => {
  return dispatch({
    mainDeal,
    type: DEALS.SET_ONLY_MAIN_DEAL,
  });
};

export const setIsDealPreview = (isDealPreview) => (dispatch) => {
  return dispatch({
    isDealPreview,
    type: DEALS.SET_IS_DEAL_PREVIEW,
  });
};

export const setNoMoreDeals = (page) => (dispatch) => {
  return dispatch({
    lastPage: page,
    type: DEALS.SET_NO_MORE_DEALS,
  });
};

export const setLastId = (lastId) => (dispatch) => {
  return dispatch({
    lastId,
    type: DEALS.SET_LAST_ID,
  });
};

export const noMoreDeals = ({ dispatch, page, deals = [] }) => {
  if (!deals.length || deals.length < DESKTOP_PAGE_SIZE) {
    dispatch(setNoMoreDeals(page));
  }
};
