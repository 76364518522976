import {
  BRAND_FIVEPM,
  SITE_FIVEPM,
  SITE_LIVINGSOCIAL,
  SITE_LIVINGSOCIAL_IE,
  SITE_WOWCHER,
  SITE_WOWCHERIE,
} from '../config/setup/setup';

export const isLoginPages = (url) => {
  return url === '/login' || url === '/forgot-password' || url === '/register';
};

export const isMyaccountPages = (url) => {
  return url.indexOf('/myaccount') === 0;
};

// TODO: check how this function is getting used, wowcher ie needed or not
export const isWowcherBrand = () => {
  return Boolean(process.env.NEXT_PUBLIC_SITE === SITE_WOWCHER);
};

export const isFivePM = () => {
  return Boolean(
    process.env.NEXT_PUBLIC_SITE === SITE_FIVEPM ||
      process.env.NEXT_PUBLIC_BRAND === BRAND_FIVEPM,
  );
};
export const isLivingSocialBrand = () => {
  return Boolean(
    process.env.NEXT_PUBLIC_SITE === SITE_LIVINGSOCIAL_IE ||
      process.env.NEXT_PUBLIC_SITE === SITE_LIVINGSOCIAL,
  );
};

export const isIESite = (site = '') => {
  if (site) {
    return Boolean(site === SITE_LIVINGSOCIAL_IE || site === SITE_WOWCHERIE);
  }

  return Boolean(
    process.env.NEXT_PUBLIC_SITE === SITE_LIVINGSOCIAL_IE ||
      process.env.NEXT_PUBLIC_SITE === SITE_WOWCHERIE,
  );
};

export const isWowcherIE = () => {
  return Boolean(process.env.NEXT_PUBLIC_SITE === SITE_WOWCHERIE);
};
