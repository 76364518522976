import { useEffect } from 'react';
import cookie from 'react-cookies';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import COOKIES from '../config/cookies/cookies';
import {
  URLSPONSOREDCUSTOMER,
  URLSPONSOREDIMPRESSION,
} from '../config/setup/setup';
import { setCookie } from './cookieSetter';
import ErrorTrackingService from './errorTrackingService';
import httpCommonHeaders from './httpCommonHeaders';

export const registerSponsoredSearchSessionId = async () => {
  const customerToken = cookie.load(COOKIES.customerToken);
  const sessionId = cookie.load(COOKIES.sessionId);

  try {
    // this endpoint returns a sessionId for a given customer token.
    // if there is no ct, a temporary sessionId is generated
    const { data } = await axios(URLSPONSOREDCUSTOMER, {
      data: { customerToken, sessionId },
      headers: httpCommonHeaders(),
      method: 'POST',
      withCredentials: true,
    });

    setCookie({ expire: 365, key: COOKIES.sessionId, value: data.sessionId });

    return data.sessionId;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return false;
  }
};

export const SponsoredImpressionType = {
  click: 'click',
  impression: 'impression',
};

export const sendSponsoredDealImpressions = async (type, deals) => {
  if (deals.length === 0) {
    // no impressions to send
    return true;
  }

  const sessionId =
    cookie.load(COOKIES.sessionId) ||
    (await registerSponsoredSearchSessionId());

  if (!sessionId) {
    // error already tracked
    return false;
  }

  try {
    await axios(URLSPONSOREDIMPRESSION, {
      data: {
        adIds: deals.map((deal) => deal.adId),
        dealIds: deals.map((deal) => deal.id),
        sessionId,
        type,
      },
      headers: httpCommonHeaders(),
      method: 'POST',
      withCredentials: true,
    });

    return true;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return false;
  }
};

export const sendSponsoredDealTrackingFromAngular = async (id) => {
  const match = 'sponsored_search_';
  const parameters = new URLSearchParams(window.location.search);
  const userSource = parameters.get('usr_src');

  if (userSource?.indexOf(match) === 0) {
    try {
      const entries = localStorage.getItem('sponsoredDeals');
      const sponsoredDeals = JSON.parse(entries);
      const entry = sponsoredDeals?.dealAdIds?.find((deal) => deal.id === id);
      if (entry?.adId) {
        const sessionId =
          cookie.load(COOKIES.sessionId) ||
          (await registerSponsoredSearchSessionId());

        if (!sessionId) {
          // error already tracked
          return false;
        }

        await axios(URLSPONSOREDIMPRESSION, {
          data: {
            adIds: [entry.adId],
            dealIds: [id],
            sessionId,
            type: 'click',
          },
          headers: httpCommonHeaders(),
          method: 'POST',
          withCredentials: true,
        });
      } else {
        throw new Error(`no sponsored deal entry for: ${id}`);
      }
    } catch (error) {
      console.warn(error);
      ErrorTrackingService.logError(error);
    }
  }
};

export const useSponsoredDealTrackingFromAngular = (id) => {
  useEffect(() => {
    sendSponsoredDealTrackingFromAngular(id);
  }, [id]);
};
