import {
  GIFT_RECIPIENT_FIVE,
  GIFT_RECIPIENT_FOUR,
  GIFT_RECIPIENT_ONE,
  GIFT_RECIPIENT_SIX,
  GIFT_RECIPIENT_THREE,
  GIFT_RECIPIENT_TWO,
  GIFT_RECIPIENT_SEVEN,
  GIFT_RECIPIENT_EIGHT,
  GIFT_RECIPIENT_NINE,
  GIFT_RECIPIENT_TEN,
  GIFT_PERSONALITY_EIGHT,
  GIFT_PERSONALITY_FIVE,
  GIFT_PERSONALITY_FOUR,
  GIFT_PERSONALITY_NINE,
  GIFT_PERSONALITY_ONE,
  GIFT_PERSONALITY_SEVEN,
  GIFT_PERSONALITY_SIX,
  GIFT_PERSONALITY_TEN,
  GIFT_PERSONALITY_THREE,
  GIFT_PERSONALITY_TWO,
} from '../config/constants/images';

const brand = process.env.NEXT_PUBLIC_BRAND;

// oldKey - for current api
export const recipientFilters = [
  {
    id: 7,
    imageUrl: GIFT_RECIPIENT_SEVEN[brand],
    linkText: 'Mum',
    oldKey: 'mum',
    searchTag: 'gifts-for-mum',
  },
  {
    id: 8,
    imageUrl: GIFT_RECIPIENT_EIGHT[brand],
    linkText: 'Dad',
    oldKey: 'dad',
    searchTag: 'gifts-for-dad',
  },
  {
    id: 1,
    imageUrl: GIFT_RECIPIENT_ONE[brand],
    linkText: 'Hubby',
    oldKey: 'him',
    searchTag: 'gifts-for-hubby',
    // searchTag: 'gifts-for-him', // old tag name
  },
  {
    id: 2,
    imageUrl: GIFT_RECIPIENT_TWO[brand],
    linkText: 'Wifey',
    oldKey: 'her',
    searchTag: 'gifts-for-wifey',
    // searchTag: 'gifts-for-her', // old tag name
  },
  {
    id: 3,
    imageUrl: GIFT_RECIPIENT_THREE[brand],
    linkText: 'Little Ones',
    oldKey: 'kids',
    searchTag: 'gifts-for-little-ones',
    // searchTag: 'gifts-for-kids', // old tag name
  },
  {
    id: 4,
    imageUrl: GIFT_RECIPIENT_FOUR[brand],
    linkText: 'Teen',
    oldKey: 'teens',
    searchTag: 'gifts-for-teens',
  },
  {
    id: 9,
    imageUrl: GIFT_RECIPIENT_NINE[brand],
    linkText: 'Grandparents',
    oldKey: 'grandparents',
    searchTag: 'gifts-for-grandparents',
  },
  {
    id: 5,
    imageUrl: GIFT_RECIPIENT_FIVE[brand],
    linkText: 'BFF',
    oldKey: 'bffs',
    searchTag: 'gifts-for-bffs',
  },
  {
    id: 6,
    imageUrl: GIFT_RECIPIENT_SIX[brand],
    linkText: 'Colleague',
    oldKey: 'colleagues',
    searchTag: 'gifts-for-colleagues',
  },
];

export const personalityFilters = [
  {
    id: 1,
    imageUrl: GIFT_PERSONALITY_ONE[brand],
    linkText: 'Playful',
    searchTag: 'gifts-for-playful',
  },
  {
    id: 10,
    imageUrl: GIFT_PERSONALITY_TEN[brand],
    linkText: 'Indulgent',
    searchTag: 'gifts-for-indulgent',
  },
  {
    id: 2,
    imageUrl: GIFT_PERSONALITY_TWO[brand],
    linkText: 'Insta-famous',
    searchTag: 'gifts-for-instafamous',
  },
  {
    id: 3,
    imageUrl: GIFT_PERSONALITY_THREE[brand],
    linkText: 'Geeky',
    searchTag: 'gifts-for-geeky',
  },
  {
    id: 4,
    imageUrl: GIFT_PERSONALITY_FOUR[brand],
    linkText: 'Giggly',
    searchTag: 'gifts-for-giggly',
  },
  {
    id: 5,
    imageUrl: GIFT_PERSONALITY_FIVE[brand],
    linkText: 'Gorgeous',
    searchTag: 'gifts-for-gorgeous',
  },
  {
    id: 6,
    imageUrl: GIFT_PERSONALITY_SIX[brand],
    linkText: 'Active',
    searchTag: 'gifts-for-active',
  },
  {
    id: 7,
    imageUrl: GIFT_PERSONALITY_SEVEN[brand],
    linkText: 'Pampered',
    searchTag: 'gifts-for-pampered',
  },
  {
    id: 8,
    imageUrl: GIFT_PERSONALITY_EIGHT[brand],
    linkText: 'Jet Setter',
    searchTag: 'gifts-for-jetsetter',
  },
  {
    id: 9,
    imageUrl: GIFT_PERSONALITY_NINE[brand],
    linkText: 'Daredevil',
    searchTag: 'gifts-for-daredevil',
  },
];

export const budgetIconFilters = [0, 10, 20, 50, 100];
export const getIsNewApi = () => {
  return true;
};
