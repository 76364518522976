import React, { useContext } from 'react';
import ThemeContext from '../../../providers/ThemeProvider';

const LoadingPlaceholderDeal = () => {
  const theme = useContext(ThemeContext);

  return (
    <div>
      <div className="loading-placeholder__box">
        <div className="loading-placeholder__swiper" />
      </div>
      <style jsx>
        {`
          .loading-placeholder__box {
            background-color: #fff;
            width: 100%;
            padding-top: 86%;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            margin: 0 0 20px 0;
          }

          .loading-placeholder__swiper {
            background: linear-gradient(
              45deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 40%,
              rgba(255, 255, 255, 1) 50%,
              rgba(0, 0, 0, 0) 60%,
              rgba(0, 0, 0, 0) 100%
            );
            animation: ease-in-out 1.5s loading-placeholder__shimmer infinite;
            height: 200%;
            width: 200%;
            top: -10%;
            left: 0;
            position: absolute;
            z-index: 1;
          }

          .loading-placeholder__box:before {
            background-color: #f0f0f0;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 23%;
          }

          @keyframes loading-placeholder__shimmer {
            0% {
              transform: translateX(-100%);
            }
            100% {
              transform: translateX(100%);
            }
          }

          .loading-placeholder__box:after {
            background-color: #f0f0f0;
            content: '';
            display: block;
            position: absolute;
            top: 82%;
            left: 70%;
            right: 5%;
            bottom: 5%;
            border-radius: 40px;
          }
          @media (max-width: ${theme.breakpoints.smDown}) {
            .loading-placeholder__box {
              border-radius: 0;
              margin: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingPlaceholderDeal;
