import cookie from 'react-cookies';
import axios from '../../components/_generic/axiosSplunk/axiosSplunk';
import { USER } from '../../config/constants/action-types';
import COOKIES from '../../config/cookies/cookies';
import {
  URLUSER,
  URLLOGIN,
  URLDELETE,
  URLEXPRESSREGISTR,
  URLREGISTR,
  URLLOGOUT,
  COOKIE_SUBSCRIBED_EXPIRES,
} from '../../config/setup/setup';
import commonCookiesOptions from '../../helpers/commonCookiesOptions';
import { removeCookie, setCookie } from '../../helpers/cookieSetter';
import ErrorTrackingService from '../../helpers/errorTrackingService';
import httpCommonHeaders from '../../helpers/httpCommonHeaders';
import { registerSponsoredSearchSessionId } from '../../helpers/sponsoredSearch';
import { setUserVipStatusToLocal } from '../../helpers/user';
import { getUser } from '../../services/user';

export const setUser = (userprofile) => (dispatch) => {
  return dispatch({ type: USER.SET_USER, userprofile });
};

export const setAuthCheckFinished = () => (dispatch) => {
  return dispatch({ type: USER.SET_AUTH_CHECK_FINISHED });
};

// eslint-disable-next-line unicorn/consistent-function-scoping
export const getProfile = () => async (dispatch) => {
  try {
    const response = await getUser();
    // res structure is different in DEV and in PROD
    if (response.email) {
      // user_profile
      cookie.save(
        COOKIES.userProfile,
        JSON.stringify(response),
        commonCookiesOptions,
      );
      setUserVipStatusToLocal(response?.vipUser);
      dispatch(setUser(response));
      dispatch(setAuthCheckFinished());

      return true;
    }
    dispatch(setAuthCheckFinished());

    return false;
  } catch {
    dispatch(setAuthCheckFinished());

    return false;
  }
};

export const logUser = async (login, password) => {
  if (cookie.load(COOKIES.userInfo)) {
    cookie.remove(COOKIES.userInfo, {
      domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
      path: '/',
    });
  }
  if (cookie.load(COOKIES.userEmail)) {
    removeCookie(COOKIES.userEmail);
  }

  setCookie({
    expire: 365,
    key: COOKIES.userEmail,
    value: login,
  });

  try {
    const { data } = await axios({
      _spring_security_remember_me: true,
      cache: false,
      data: {
        loginRequest: {
          j_password: password,
          j_username: login,
        },
      },
      headers: httpCommonHeaders(),
      method: 'POST',
      url: URLLOGIN,
      validateStatus: (status) => {
        return status < 500;
      },
      withCredentials: true,
    });

    return data.response;
  } catch (error) {
    ErrorTrackingService.logError(error);
    throw new Error(error);
  }
};

export const deleteUser = async () => {
  return await axios({
    cache: false,
    headers: httpCommonHeaders(),
    method: 'PUT',
    url: URLDELETE,
    validateStatus: (status) => {
      return status < 500;
    },
    withCredentials: true,
  });
};

export const setLightbox = (lightbox) => (dispatch) => {
  dispatch({ lightbox, type: USER.SET_LIGHTBOX });
};

export const registerUser = async (user, isPasswordless = false) => {
  try {
    const { data } = await axios({
      data: {
        requestRegistration: user,
      },
      headers: httpCommonHeaders(),
      method: 'POST',
      url: isPasswordless ? URLEXPRESSREGISTR : URLREGISTR,
      validateStatus: (status) => {
        return status < 500;
      },
    });

    return data;
  } catch (error) {
    ErrorTrackingService.logError(error);
    throw new Error(error);
  }
};

export const loginSuccess = (data) => async (dispatch) => {
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 1);

  // ct
  cookie.save(COOKIES.customerToken, data.customerToken, {
    ...commonCookiesOptions,
    expires,
  });
  // ${BRAND}-win cookies
  cookie.save(COOKIES.brandCookie, 'registered_user', {
    ...commonCookiesOptions,
    expires,
  });

  // Remove referral friend cookie if it is already there
  if (cookie.load(COOKIES.referralCode)) {
    removeCookie(COOKIES.referralCode);
  }

  // remove delete account cookies
  if (cookie.load(COOKIES.deleteEmail)) {
    removeCookie(COOKIES.deleteEmail);
  }

  await registerSponsoredSearchSessionId();

  dispatch(getProfile());
};

// eslint-disable-next-line unicorn/consistent-function-scoping
export const resetUser = () => (dispatch) => {
  return dispatch({ type: USER.RESET_USER });
};

export const updateUser = async (userprofile) => {
  try {
    return await axios(URLUSER, {
      cache: false,
      data: {
        user: userprofile,
      },
      headers: httpCommonHeaders(),
      method: 'PUT',
      validateStatus: (status) => {
        return status < 500;
      },
      withCredentials: true,
    });
  } catch {
    return false;
  }
};

export const logoutUser = async () => {
  try {
    const { data } = await axios({
      cache: false,
      method: 'POST',
      url: URLLOGOUT,
      validateStatus: (status) => {
        return status < 500;
      },
    });

    return data;
  } catch (error) {
    ErrorTrackingService.logError(error);
    throw new Error(error);
  }
};

export const resetLogin = (email, password) => async (dispatch) => {
  try {
    await logoutUser();
    const response = await logUser(email, password);

    if (response.data) {
      dispatch(loginSuccess(response.data));
      setCookie(
        COOKIES.brandCookie,
        'registered_user',
        COOKIE_SUBSCRIBED_EXPIRES,
      );
      setCookie(COOKIES.userLoggedIn, true);
      setCookie(COOKIES.registered, true, COOKIE_SUBSCRIBED_EXPIRES);
    }
  } catch (error) {
    throw new Error(error);
  }
};
