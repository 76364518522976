/* eslint-disable sonarjs/no-duplicate-string, id-length */
import ReactCookies from 'react-cookies';
import {
  DEFAULT_SUGGGEST_API,
  DESKTOP_PAGE_SIZE,
  LINK_MAIN_HOME,
} from '../links/links';

export const BRAND_WOWCHER = 'wowcher';
export const BRAND_FIVEPM = 'fivepm';
export const BRAND_LIVINGSOCIAL = 'living-social';

export const SITE_WOWCHER = 'wowcher';
export const SITE_WOWCHERIE = 'wowcherie';
export const SITE_LIVINGSOCIAL = 'livingsocial';
export const SITE_LIVINGSOCIAL_IE = 'livingsocialie';
export const SITE_FIVEPM = 'fivepm';

export const API_HEADER_BRAND = 'wowcher';

export const WISHLIST = 'wishlist';
export const GIFT_PACK = 'giftPack';
export const TILES = 'tiles';
export const REFER_A_FRIEND = 'referAFriend';
export const CASHBACK = 'cashback';
export const KLARNA = 'klarna';
export const GPAY = 'gpay';
export const CLEAR_PAY = 'clearpay';
export const BOUGHT_COUNT_CUE = 'boughtCountCue';
export const SOCIAL_CUES = 'boughtSocialCues';
export const BESTSELLER_CUE = 'boughtSocialCue';

export const CLICK_AND_COLLECT = 'Click and Collect';
export const CLICK_AMP_COLLECT = 'Click & Collect';

export const MERCHANT_URL = {
  dev: 'https://myaccount05.devwowcher.co.uk/myaccount/merchant',
  nxt: 'https://myaccount01.nxtwowcher.co.uk/myaccount/merchant',
  prod: 'https://myaccount.wowcher.co.uk/myaccount/merchant',
};

export const FEATURE_FLAGS = {
  // Hidden
  bestsellerCue: false,
  boughtCountCue: true,
  boughtSocialCues: false,

  cashback: false,

  // Disabled
  giftPack: false,

  referAFriend: false,
  // Enabled
  tiles: false,

  wishlist: false,
};

export const allowedPaymentType = {
  clearpay: false,
  gpay: false,
  klarna: false,
};
export const MERCHANT_INSIGHTS = (merchant) =>
  `${process.env.NEXT_PUBLIC_LEGACY_API}/merchant-dashboard-url?merchantType=${merchant}`;

/* CURRENT URLs
Development  https://react-node05.devwowcher.co.uk/
Staging      https://react-node01.nxtwowcher.co.uk/
Production   https://react-node.wowcher.co.uk/
*/

export const URL_SHARE_POINTS = `${process.env.NEXT_PUBLIC_LEGACY_API}/reward/shareDeal`;
export const URL_VIEW_POINTS = `${process.env.NEXT_PUBLIC_LEGACY_API}/reward/viewDeal`;
export const URL_BULK_POINTS = `${process.env.NEXT_PUBLIC_LEGACY_API}/reward/bulkPublishEvents`;
export const URLSTATICSUFFIX = '.html';
export const URLSTATIC = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/staticpage`;
export const URL_GEOLOCATION = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/location`;
export const URLNEARMEHUB = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/fenearmelocation`;
export const URL_NEARME_POPULAR_LOCATIONS = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/nearmelocation`;
export const URL_EARLY_BIRD_URL = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/early-bird-deal`;
export const URL_GIFTS_POPULAR = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/static-page/giftGuides`;
export const URL_GIFTS_SUBCATS = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/category/gifts?subCategory=null&hyperLocation=null`;
export const URL_IMG = 'https://static.wowcher.co.uk/binaries';
export const URL_NEARME_POPULAR_LOCATIONS_IMG = (area, cat) =>
  `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/nearmelocation/area/${area}/${cat}`;
export const URLNEARMELOCATION = (area) =>
  `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/category/near-me/${area}`;
export const HYPER_LOCATION_META_TITLE = (area) =>
  `${area} Deals - Restaurants, Spas, Things to do  - Wowcher`;
export const HYPER_LOCATION_META_DESCRIPTION = (area) =>
  `Check out our deals and offers for things to do in ${area}! From the best places to eat to thrilling activities for the whole family, ${area} is a great place to spend the day or even a relaxing week and with these deals you can experience the best it has to offer!`;
export const URLLOCATIONS = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/location`;
export const SUGGESTEDSEARCHURL =
  DEFAULT_SUGGGEST_API[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'];
export const UNBOXED_SUGGESTED_SEARCH_API = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/suggest`;
export const SEARCHURL =
  ReactCookies.load('unbxd-test') === 'b'
    ? `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/unbxd/search`
    : `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/search`;
export const GUIDESEARCHURL = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/search`;
export const URLUSER = `${process.env.NEXT_PUBLIC_LEGACY_API}/user`;
export const URLDELETE = `${process.env.NEXT_PUBLIC_LEGACY_API}/user/anonymize`;
export const URLUSERSTATUS = `${process.env.NEXT_PUBLIC_LEGACY_API}/user/account-status`;
export const URLUSER_PASSWORD = `${process.env.NEXT_PUBLIC_LEGACY_API}/user/password`;
export const URLLOGIN = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/login?_spring_security_remember_me=true`;
export const URLLOGOUT = `${process.env.NEXT_PUBLIC_LEGACY_API}/logout`;
export const URLREGISTR = `${process.env.NEXT_PUBLIC_LEGACY_API}/register`;
export const URLEXPRESSREGISTR = `${process.env.NEXT_PUBLIC_LEGACY_API}/express-register`;
export const URLFBLOGIN = `${process.env.NEXT_PUBLIC_LEGACY_API}/facebook/authenticate`;
export const URLGOOGLELOGIN = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/logingoogle`;
export const URLGOOGLEREGISTR = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/registergoogleuser`;
export const URLREWARDSDETAILS = `${process.env.NEXT_PUBLIC_LEGACY_API}/reward/details`;
export const URLEXCHANGEPOINTS = `${process.env.NEXT_PUBLIC_LEGACY_API}/reward/exchange-points`;

export const URLBASKET = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/basket`;
export const URLGETBASKET = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/get/basket`;
export const URLBASKETABANDON = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/basketabandon`;
export const URLSUBSCRIBE = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/email`;
export const URLSUBSCRIBEALL = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/cauth`;
export const URLNOTIFYME = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/deal/sold-out-notification`;
export const URLEVERGREEN = `${process.env.NEXT_PUBLIC_EVERGREEN_API}/evergreen-deal-api/v1/evergreen-deals`;
export const URLNAVIGATIONS = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/navigations`;
export const URLDEAL = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/deal`;
export const URLPRODUCTDETAILPAGEDEAL = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/product-detail-page-deal`;
export const URLPRODUCTLISTINGPAGEDEAL = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/product-listing-page-deal`;
export const URLCHECKOUT = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/checkout`;
export const URLSOCIALCUE = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/socialcue/deal`;
export const URLPURCHASEVIEW = `${process.env.NEXT_PUBLIC_LEGACY_API}/deal/purchase/view`;
export const URLCHECKOUTVIEWDATA = `${process.env.NEXT_PUBLIC_LEGACY_API}/checkout/view`;
export const URLVALIDATEVOUCHER = `${process.env.NEXT_PUBLIC_LEGACY_API}/voucher`;
export const URLDEALIMPRESSION = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/deal/##LOCATION##/impressions`;
export const URLBOOKING = `${process.env.NEXT_PUBLIC_LEGACY_API}/booking`;
export const URLFLIGHTS = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/flights`;
export const URLTRAVELPRODUCTS = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/travel/products/deal`;
export const URLTILES = `${process.env.NEXT_PUBLIC_TILES_API}`;
export const URLGIFTTILES = `${process.env.NEXT_PUBLIC_TILES_API_V2}`;
export const URLSPONSOREDIMPRESSION = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/sponsored/impressionAndClick`;
export const URLWISHLIST = `${process.env.NEXT_PUBLIC_WISHLIST_API}/v1/wishlist`;
export const URLSPONSOREDCUSTOMER = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/sponsored/customer`;
export const WISHLISTSUFFIX = `?page=0&pageSize=50&detailedView=false`;
export const URLGENERATEVOUCHER = `${process.env.NEXT_PUBLIC_LEGACY_API}/deal/print-voucher`;
export const URLUPDATEVOUCHER = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/voucher`;
export const URLCREATERETURN = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/create-return-order`;
export const URLRETURNS = `${process.env.NEXT_PUBLIC_LEGACY_API}/v1/returns/returns-label`;
export const URLRETURNSLABEL = `${process.env.NEXT_PUBLIC_LEGACY_API}/v1/returns/pdf`;
export const URLPAYMENTINSTRUMENTS = `${process.env.NEXT_PUBLIC_LEGACY_API}/deal`;
export const URLKLARNASESSION = `${process.env.NEXT_PUBLIC_PAYMENT_URL}/kn/sessions`;
export const URLKLARNAPAYMENT = `${process.env.NEXT_PUBLIC_PAYMENT_URL}/kn/pay`;
export const URLCLEARPAYCREATECHECKOUT = `${process.env.NEXT_PUBLIC_PAYMENT_URL}/cp/checkouts`;
export const URLCLEARPAYSENDPAYMENT = `${process.env.NEXT_PUBLIC_PAYMENT_URL}/cp/pay`;
export const URLPAYBTADDON = `${process.env.NEXT_PUBLIC_PAYMENT_URL}/bt/addon`;
export const URLPAYCSADDON = `${process.env.NEXT_PUBLIC_PAYMENT_URL}/cs/addon`;
export const URLVIPUPDATE = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/update-vip-status`;

export const URLGIFTING = `${process.env.NEXT_PUBLIC_LEGACY_API}/gift/create`;
export const URLADDON = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/order-addon`;
export const URL_PROTECT_MY_VOUCHER_REFUND_DATA = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/voucher/pmv/refund`;
export const URLCOMPLEMENTARYDEALS = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/complementary-deals`;
export const URLAMBDA = `${process.env.NEXT_PUBLIC_USER_LOOKUP}`;

export const VOUCHER_FEED_PAGE_SIZE = 10;
export const FIND_FEED_PAGE_SIZE = 20;
export const URLVOUCHERFEED = (pageIndex) =>
  `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/voucher/${pageIndex}/${VOUCHER_FEED_PAGE_SIZE}?giftPacks=true`;
export const URL_VOUCHER_EXTENSION = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/voucher/vve`;

export const URL_PPC = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/ppc`;

// Kickbox lightbox
export const URLKICKBOX = (email) =>
  `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/email/verify?email=${email}`;

// Deal Preview
export const URLDEALPREVIEW = (uid) =>
  `${process.env.NEXT_PUBLIC_LEGACY_API}/preview-deal/${uid}`;

export const URL_FACETED_NAVIGATION_FOR_TRAVEL_HOMEPAGE = {
  [SITE_FIVEPM]: `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/facetednavigation/wowcher/travel/gb`,
  [SITE_LIVINGSOCIAL]: `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/facetednavigation/living-social/escapes/gb`,
  [SITE_LIVINGSOCIAL_IE]: `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/facetednavigation/living-social/escapes/ie`,
  [SITE_WOWCHER]: `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/facetednavigation/wowcher/travel/gb`,
  [SITE_WOWCHERIE]: `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/facetednavigation/wowcher/travel/ie`,
};

// Second checkout
export const ADDON_PAYMENT_INSTRUMENTS = `${process.env.NEXT_PUBLIC_LEGACY_API}/payment/addon/paymentInstruments`;

// Registration
export const FINDADDRESS = `${process.env.NEXT_PUBLIC_LEGACY_API}/address/postcode/##POSTCODE##`;
export const AUTOCOMPLETE = `${process.env.NEXT_PUBLIC_LEGACY_API}/address/autocomplete`;
export const VALIDATEPOSTCODE = `${process.env.NEXT_PUBLIC_LEGACY_API}/address/validate/postcode/##POSTCODE##`;
// To make it work on localhost, we can pass the apiKey
// export const SELECTADDRESS = `${process.env.NEXT_PUBLIC_LEGACY_API}/address/moniker/##MONIKER##?apiKey=cf522cfec2a4d56d569e60a152040000`;
export const SELECTADDRESS = `${process.env.NEXT_PUBLIC_LEGACY_API}/address/moniker/##MONIKER##`;
export const GET_FORMATTED_ADDRESS = `${process.env.NEXT_PUBLIC_LEGACY_API}/address/format/##GLOBALADDRESSKEY##`;

// Gift
export const URLVALIDATEGIFTCODE = `${process.env.NEXT_PUBLIC_LEGACY_API}/deal/gift-redeem`;

// GIFT CARD
export const URLREDEEMGIFTCARDCODE = `${process.env.NEXT_PUBLIC_LEGACY_API}/gift-card/redeem`;

// WALLET
export const URLWALLET = `${process.env.NEXT_PUBLIC_LEGACY_API}/user/wallet`;

// REQUEST PASSWORD RESET
export const REQUESTPASSWORDRESET = (email) =>
  `${process.env.NEXT_PUBLIC_LEGACY_API}/user/${email}/password_reset_request`;

// PASSWORD RESET
export const URLRESETPASSWORD = `${process.env.NEXT_PUBLIC_LEGACY_API}/user/password_reset`;

// PASSWORD ACTIVATE
export const URLACTIVATEPASSWORD = `${process.env.NEXT_PUBLIC_LEGACY_API}/user/password_activate`;

// User Recommendation
export const URLGETRECOMMENDATION = (userId) =>
  `${URLPRODUCTLISTINGPAGEDEAL}/recommendations/ct/${userId}?limit=40&pageSize=25&page=0`;

// User Bug again
export const URLGETBUYAGAIN = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/buy-again/deals?page=0&pageSize=10`;

// deal event service
export const URLEVENTDEAL = (location, eventName) =>
  `${URLPRODUCTLISTINGPAGEDEAL}/${location}/special/${eventName}`;
export const EVENT_NAME_NEW_PRODUCTS = 'new-products';

// live deal service
export const URLLIVEDEAL = `${URLPRODUCTLISTINGPAGEDEAL}/live`;

// URL Track RAF SHARE
export const URLTRACKRAFSHARE = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/user/raf/stat/share`;
// URL Track RAF Open Link
export const URLTRACKOPENRAFLINK = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/user/raf/stat/open`;

// Promo Code Service
export const URLPROMOCATEGORY = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/promocode`;
export const URLPROMOSUBCATEGORY = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/promocode/subcategory`;

// COUNTDOWN TIMER
// Show/hide the Countdown timer by brand
export const COUNTDOWN_BRAND = {
  fivepm: true,
  livingsocial: true,
  livingsocialie: true,
  wowcher: true,
  wowcherie: true,
};

// Default locations
export const DEFAULT_LOCATION = {
  _score: 'NaN',
  brand: 'wowcher',
  countryCode: 'gb',
  description: null,
  domain: null,
  id: 277_173,
  latLon: { lat: 55.833_332_061_767_58, lon: -4.25 },
  name: 'Glasgow',
  region: 'Scotland',
  shortName: 'glasgow',
  siteGroup: null,
  status: null,
};

export const SPLUNK_TOKEN = {
  dev: '14e6fcee-7c05-4f29-b02a-2f86ceb27728',
  nxt: '14e6fcee-7c05-4f29-b02a-2f86ceb27728',
  prod: '57c95d85-09e3-4fa9-9ddd-3c1fe5241fd2',
};

export const SITE_NAME = {
  fivepm: '5pm',
  livingsocial: 'LivingSocial',
  livingsocialie: 'LivingSocial',
  wowcher: 'Wowcher',
};

// Filters values
export const FILTER_LOCATION = 'location';
export const FILTER_SUBCATEGORY = 'subcategory';
export const FILTER_CATEGORY = 'category';
export const FILTER_TAG = 'searchTag';
export const FILTER_ORDER = 'order';
export const FILTER_SORTBY = 'orderBy';
export const FILTER_MINPRICE = 'minPrice';
export const FILTER_MAXPRICE = 'maxPrice';
export const FILTER_VIP = 'vip';

export const FILTER_ALL = 'all';

// Maps Filters
export const FILTER_CATEGORY_MAPS = 'categories';

// possible values: POPULARITY, DATE, PRICE, POSITION, DISCOUNTPERCENTAGE, RECENTLYADDED, DISTANCE
export const filterPopularity = [
  {
    name: 'Popularity',
    value: 'popularity',
  },
  {
    name: 'Price high to low',
    value: 'price',
  },
  /*
  {
    name: 'Date',
    value: 'date',
  },
  */
  {
    name: 'Discount %',
    value: 'discountPercentage',
  },
  {
    name: 'Recently Added',
    value: 'recentlyAdded',
  },
  {
    name: 'Price low to high',
    value: 'pricelow',
  },
];
export const filterPriceMaps = [
  {
    name: '0',
    value: '0',
  },
  {
    name: '10',
    value: '10',
  },
  {
    name: '20',
    value: '20',
  },
  {
    name: '50',
    value: '50',
  },
  {
    name: '100',
    value: '100',
  },
  {
    name: '200',
    value: '200',
  },
];
export const filterPrice = [
  {
    name: '0',
    value: '0',
  },
  {
    name: '10',
    value: '10',
  },
  {
    name: '20',
    value: '20',
  },
  {
    name: '50',
    value: '50',
  },
  {
    name: '100',
    value: '100',
  },
  {
    name: '1000',
    value: '1000',
  },
];
export const filterPriceTravel = [
  {
    name: '0',
    value: '0',
  },
  {
    name: '50',
    value: '50',
  },
  {
    name: '100',
    value: '100',
  },
  {
    name: '150',
    value: '150',
  },
  {
    name: '200',
    value: '200',
  },
  {
    name: '300',
    value: '300',
  },
  {
    name: '400',
    value: '400',
  },
  {
    name: '500',
    value: '500',
  },
  {
    name: '750',
    value: '750',
  },
  {
    name: '1000',
    value: '1000',
  },
];

export const filterRating = [
  {
    name: 'Rating',
    value: '0',
  },
];
export const filterNew = [
  {
    name: 'New',
    value: '0',
  },
];

export const GIFTING_OPTIONS = {
  // ls only: this sets product.gift = true only.
  E_GIFT: 'e-gift',
  // GIFT: 'gift',
  // // NON-DELIVERABLE wowcher only: does not exist on ls
  // GIFT_PACK: 'gift-pack',

  // // for next day delivery gift pack
  // NEXT_DAY_GIFT_PACK: 'ndd-gpk',

  NONE: 'none',
  // // for standard delivery gift pack
  // STANDARD_DELIVERY_GIFT_PACK: 'std-gpk',
  // WRAPPED: 'wrapped', // DELIVERABLE wowcher only: does not exist on ls
};
export const GIFTING_OPTIONS_DISPLAY = (price = '', isEuro) => {
  return {
    'e-gift': 'Send As E-Gift',
    // gift: 'Gift',
    // 'gift-pack': 'Add Gift-Pack',
    // 'ndd-gpk': `Gift Pack - Next Day Delivery${
    //   price ? ` - ${isEuro ? '€' : '£'}${price}` : ''
    // }`,
    none: 'None',
    // 'std-gpk': `Gift Pack - Standard Delivery${
    //   price ? ` - ${isEuro ? '€' : '£'}${price}` : ''
    // }`,
    // wrapped: 'Send Gift Wrapped',
  };
};
export const SUBSCRIBE_LOCATIONS_5PM = [
  'Aberdeen',
  'Glasgow',
  'Edinburgh',
  'Travel',
];
// Deals
export const MAX_TITLE_DEAL_SIZE = 65;
export const MAX_TEXT_DEAL_SIZE = 195;
export const MAX_TEXT_DEAL_MEDIUM_SIZE = 70;
export const MAX_TITLE_DEAL_CAT_MAIN_SIZE = 45;
export const MAX_SUBTITLE_DEAL_CAT_MAIN_SIZE = 120;
export const MAX_LIMITED_AVAILABILITY = 50;
export const MAX_CATEGORY_DEALS_BLOCK = 9;
export const MAX_SECONDARY_DEALS_BLOCK = 3;
export const MAX_SECONDARY_SMALL_DEALS_BLOCK = 2;
export const MAX_MEDIUM_DEALS_PER_ROW = 3;
export const SHOW_CARROUSEL_ARROWS = true; // show arrows in the carrousel component
export const REVIEWS_TO_SHOW = 3;
export const REVIEWS_PER_PAGE = 10;
export const MIN_REVIEW_AVERAGE = 3.5;
export const SHOW_TRAVEL_REVIEWS = {
  fivepm: true,
  livingsocial: true,
  livingsocialie: true,
  wowcher: true,
};
export const SHOW_REVIEWS = {
  fivepm: true,
  livingsocial: true,
  livingsocialie: true,
  wowcher: true,
};
export const CYF_DEAL_TYPE = 'INTERNATIONAL_TRAVEL';

export const STATIC_SERVER = 'static';
export const IMAGES_SERVER = 'images';
export const DEFAULT_DEAL_IMG_WIDTH = '727';
export const DEFAULT_DEAL_IMG_HEIGHT = '487';
export const DEFAULT_MAIN_DEAL_IMG_WIDTH = '727';
export const DEFAULT_MAIN_DEAL_IMG_HEIGHT = '487';
export const DEFAULT_DEAL_IMG_WIDTH_TWO_BY_TWO = '727';
export const DEFAULT_DEAL_IMG_HEIGHT_TWO_BY_TWO = '487';
export const DEFAULT_DEAL_IMG_WIDTH_SECONDARY = '727';
export const DEFAULT_DEAL_IMG_HEIGHT_SECONDARY = '487';
export const DEFAULT_DEAL_IMG_WIDTH_MOBILE = '535';
export const DEFAULT_DEAL_IMG_HEIGHT_MOBILE = '358';
export const DEFAULT_DEAL_IMG_WIDTH_MOBILE_LG = '575';
export const DEFAULT_DEAL_IMG_HEIGHT_MOBILE_LG = '385';
export const DEFAULT_MEDIUM_DEAL_IMG_WIDTH = '727';
export const DEFAULT_MEDIUM_DEAL_IMG_HEIGHT = '487';
export const DEFAULT_DEAL_IMG_WIDTH_THUMB = '54';
export const DEFAULT_DEAL_IMG_HEIGHT_THUMB = '43';
export const TRAVEL_DEAL_IMG_WIDTH_THUMB = '120';
export const TRAVEL_DEAL_IMG_HEIGHT_THUMB = '82';
export const DEFAULT_DEAL_BUSINESS_IMG_HEIGHT = '73';
export const DEFAULT_DEAL_BUSINESS_IMG_WIDTH = '161';
export const DESKTOP_TILE_IMG_HEIGHT = '220';
export const DESKTOP_TILE_IMG_WIDTH = '264';
export const MOBILE_TILE_IMG_HEIGHT = '110';
export const MOBILE_TILE_IMG_WIDTH = '132';

const environment = process.env.NEXT_PUBLIC_ENVIRONMENTNO || 'dev04';

const environmentNo = environment.replace(/\D/g, '');
export const IMAGES_DEAL_URL = {
  dev: `https://images${environmentNo}.devwowcher.co.uk`,
  nxt: 'https://images01.nxtwowcher.co.uk',
  prod: 'https://images.wowcher.co.uk',
};

export const STATIC_IMAGES_URL = {
  dev: `https://static.wowcher.co.uk`,
  nxt: `https://staticnxt01.wowcher.co.uk`,
  prod: `https://static.wowcher.co.uk`,
};

export const USER_STATUS_MAPPING = {
  a: 'hasAddress',
  d: 'DED',
  f: 'isFacebook',
  fo: 'FBO',
  hp: 'hasPassword',
  l: 'isLocked',
  r: 'isRegistered',
  s: 'isSubscribed',
  tpl: 'tpl', // third party login
};

// Splunk
export const SPLUNK_ACTIVATED = true; // Send info to the Splunk Server true/false
export const SPLUNK_SENT_WINDOW_INFORMATION = true; // Send extra info from the client request

// Meta Data
export const TITLE_DEALS_LOCATION = '##CATEGORY## - ##LOCATION## - ';
export const TITLE_DEALS_NO_LOCATION = '##CATEGORY## - ';
export const TITLE_DEALS_NO_CATEGORY =
  '##LOCATION## Deals - Up to 80% off Vouchers & Offers - ';
export const TITLE_DEALS_NO_CATEGORY_NOLOCATION =
  'Shop - Online Deals & Vouchers of up to 80% off - ';
export const TITLE_ESCAPES_NO_CATEGORY_NOLOCATION =
  'Escapes - Online Deals & Vouchers of up to 80% off - ';
export const TITLE_VIP_HUB = `Exclusive Deals, VIP Offers & Discounts - Up to 80% Off -`;

export const TTILE_WELLNESS_DEALS = 'Wellness Collection deals | ';

export const META_DESCRIPTION = {
  default:
    'Huge discounts on the latest and greatest products. Shop now and save up to 80% on hundreds of amazing deals.',
  'gift-finder': {
    default:
      "Save up to 80% on the latest and greatest gifts in ##LOCATION## and shopping. We've got massages, afternoon tea, toys, jewellery and loads more.",
    'national-deal':
      "Save up to 80% on the latest and greatest gifts in shopping. We've got perfumes, toys, jewellery and loads more.",
    travel: `Save up to 80% on the latest and greatest gifts in ##TRAVELESCAPE## and shopping. We've got spa breaks, city breaks, toys, jewellery and loads more.`,
  },
  local: {
    activity: `Looking for something fun to do in ##LOCATION##? From paintballing to bungee jumping and supercars to bubble football, you're sure to find an adrenaline fueled activity on ##BRAND##. Save up to 80%.`,
    adult:
      "For adult eyes only! Browse adult products and naughty things to do in ##LOCATION##. Save up to 80%. Sex toys, enhancers and adult themed shows - don't miss out!",
    beauty: `Time for a pamper? You'll find the latest and greatest ##LOCATION## beauty treatments on ##BRAND## . From haircuts to facials, we're always looking out for the next thing that'll make us feel fabulous.`,
    default: `Save up to 80% on deals in ##LOCATION## – From restaurants and beauty to entertainment and fitness, ##BRAND## has hundreds of deals in your area.`,
    entertainment: `Concerts, Sporting events, Tarot Reading, Cinemas and more. Whether you're trying to impress someone or planning a weekend with friends, check out ##BRAND## for great events in ##LOCATION##. Save up to 80%.`,
    'food-drink':
      "Save up to 80% on the best restaurants in ##LOCATION##. From fine dining to quick eats, we've got an ever-changing range of amazing food and drink deals for you. Bon appetit.",
    'health-fitness':
      "Find the best health and fitness deals that ##LOCATION## has to offer. If gym passes and bootcamps don't do it for you, shop our amazing fitness products. Save up to 80%.",
    kids:
      "From toys and games to learning aids, we're sure to have something to keep the little ones occupied. Keep an eye out for exciting things for the kids to do in ##LOCATION##. Save up to 80%.",
    learning:
      "We're always looking for that next thing to add to our CV. From interior design to Excel training, we've got a wide range of vocational and recreational online and ##LOCATION## classroom based courses. Save up to 80%.",
    travel: `Save up to 80% on ##TRAVELESCAPE## deals – From short breaks in the UK and relaxing spa breaks to beach holidays and long haul adventures, ##BRAND## has hundreds of cheap ##TRAVELESCAPE## deals.`,
  },
  shop: {
    default:
      'Huge discounts on the latest and greatest products. Shop now and save up to 80% on hundreds of amazing deals.',
    electricals:
      'Save up to 80% on cheap electronics and accessories. Computers, laptops, android tablets, in-car accessories and more.',
    'fashion-jewellery':
      "Save up to 80% on the latest fashion trends. Jewellery, watches, fashion and accessories - we've got hundreds of products to make you look fabulous. Happy shopping!",
    'home-essentials':
      'Save up to 80% on household essentials. Toilet rolls, toothbrush heads, dish-washing tablets and more.',
    'home-garden':
      "Home sweet home! Save up to 80% on furniture, art, bedding, mattresses, garden accessories and more. We've got hundreds of deals to help you get the home of your dreams.",
  },
  'special-event': {
    default: `Save up to 80% on ##EVENTNAME## deals in ##LOCATION##. From restaurants and beauty to entertainment and fitness, ##BRAND##  has hundreds of deals in your area.`,
  },
  travel: {
    adventure: `Looking for something a bit different from your holiday? Tours, animals, adventures and the magical Disneyland are but a few options from ##BRAND## . Book now and save up to 50%.`,
    beach: `Feel sand between your toes with a ##BRAND##  beach break. Short-haul including flights, and offers for further afield. Book your summer holiday now.`,
    'city-breaks':
      "Enjoy a weekend away in a choice of amazing European cities. Including flights, hotel only or all-inclusive, there's a deal here for you. Save up to 50%.",
    default: `Save up to 80% on ##TRAVELESCAPE## deals – From short breaks in the UK and relaxing spa breaks to beach holidays and long haul adventures, ##BRAND## has hundreds of cheap ##TRAVELESCAPE##  deals.`,
    family:
      "Holiday fun for all the family! Whether it's Disneyland or a beach holiday, we've got something that'll keep you all entertained. Save up to 50%.",
    'last-minute': `Need a break now? Last minute breaks from ##BRAND##  have discounts of up to 50% for stays starting this weekend! Book now.`,
    romantic:
      "Treat a loved one to a romantic break and save up to 50%. With deals for 2 people, including flights and accommodation, there's a hassle-free weekend away waiting for you!",
    'spa-and-health': `Relax, pamper yourself and save up to 50% while you're doing it. UK and European spa breaks starting from just ##CURRENCY##59. Ahhhh.`,
    'sports-and-activities': `Get-up-and-go holidays from ##BRAND## . Save up to 50% on a holiday you won't forget. Sailing, Skiing, Hiking and Kayaking and just a few ideas to get you started.`,
    'uk-city-breaks':
      "Enjoy a weekend away in the UK. From a relaxing countryside break to the hustle and bustle of the city, there's plenty to choose from. Save up to 50%.",
  },
};

export const WEBAPP_MOBILE = 'WEBAPP-MOBILE';
export const WEBAPP_TABLET = 'WEBAPP-TABLET';
export const WEBAPP_DESKTOP = 'WEBAPP-DESKTOP';

// Page cache
export const PAGE_CACHE_AGE_SEC = 1_200;

// Axios setup
export const AXIOS_RESPONSE_TIMEOUT = 20_000;
export const AXIOS_CONNECT_TIMEOUT = 20_000;

// Time before return 404 when main deal doesnt exist
export const TIMEOUT_CHECK_NOT_FOUND = 500;

// Pagination
export const ALLOW_SSR_PAGINATION = true; // Allow XXX?page=XXX URLs on deals

// Login and registration setup
export const POST_CODE_MAX_LENGTH = 8;
export const PASSWORD_MIN_LENGTH = 6;

export const HEADER_LOGO_MAX = 768;

export const SHOW_LIGHTBOX = {
  livingsocial: false,
  livingsocialie: false,
  wowcher: true,
};

// claim credit update status
export const CLAIM_CREDIT_UPDATE_STATUS = 'ON_DEMAND_REFUND';

// Cookies expires time
export const COOKIE_SUBSCRIBED_EXPIRES = 365;
export const COOKIES_EXPIRES_HALF_YEAR = 182;
export const COOKIES_EXPIRES_MONTH = 30;
export const COOKIES_EXPIRES_WEEK = 7;
export const COOKIES_EXPIRES_DAY = 1;

// Deals
export const NUMBER_OF_DEALS_FIRST_PAGE_ON_CATEGORY = 2 + DESKTOP_PAGE_SIZE; // 2 secondary + 9
export const NUMBER_OF_DEALS_FIRST_PAGE_ON_DEAL = 5 + DESKTOP_PAGE_SIZE; // 5 secondary + 9
export const NUMBER_OF_DEALS_FIRST_PAGE_ON_DEAL_TRAVEL = DESKTOP_PAGE_SIZE;
export const NUMBER_OF_SECONDARY_DEALS_ON_CATEGORY = 2; // number of deals next to the main deal on the category page
export const NUMBER_OF_SECONDARY_DEALS_ON_DEAL = 5; // number of deals next to the main deal on the deal page
export const NUMBER_OF_RECENTLY_VIEWED_DEALS = 4; // max number of recent deals
export const RECENT_DEALS_LOCAL_STORE = `angular${
  process.env.NEXT_PUBLIC_BRAND === 'fivepm'
    ? '5pm'
    : process.env.NEXT_PUBLIC_BRAND === 'wowcher'
    ? 'Wowcher'
    : 'LS'
}.recentDealsLocalStore`; // recent deals storage key
export const CUSTOMER_DEFAULT_TOKEN = 'th1515adumMyt0k3n'; // recent deals storage key
export const LIGHTBOX_APPEARED = 'lightbox-appeared';
export const LOCATION_SELECTED = 'location-selected';
export const ONETAP_APPEARED = 'one-tap-appeared';
export const DEALS_PROMO_TYPES = (newProductionLink = '') => {
  return {
    buyAgain: {
      link: `${LINK_MAIN_HOME}/buy-again`,
      linkName: 'View all',
      title: 'Buy again',
    },
    earlyBird: {
      link: `${LINK_MAIN_HOME}/early-bird`,
      linkName: 'View all',
      title: 'Early Bird Deals',
    },
    newProducts: {
      link: `${LINK_MAIN_HOME}${newProductionLink}`,
      linkName: 'View all',
      title: 'New Products',
    },
    recentlyViewed: {
      link: `${LINK_MAIN_HOME}/recently-viewed`,
      linkName: 'View all',
      title: 'Recently Viewed',
    },
    recommended: {
      link: `${LINK_MAIN_HOME}/recommended-for-you`,
      linkName: 'View all',
      title: 'Recommended for you',
    },
  };
};
export const SEO_LOCATION_TEXT =
  'Save on ##LOCATION## attractions, restaurants and spa days with hundreds of deals on ##SITE_NAME##. We’ve got discounts on everything from day trips and experience days to afternoon teas and nights out in ##LOCATION## for you to browse!';

// Logos
export const MAX_LOGOS_IN_LIST = 3;
export const MAX_LOGOS_PERCENTAGE = 100;

// Calendar
export const CALENDAR_STEP_TWO = 'calendarStepTwo';

// BCOR
export const BCOR_STEP_ONE_EL = 'bcorStepOne';

// Payment
export const PAYMENT_ELEMENT_NAME = 'payment-component-element';
export const PAYMENT_TOP_CTA_ID = 'top-cta-button';

// BRAND_HYPHEN_DOMAIN used in some API calls as 'brand' (in data not header)
export const BRAND_HYPHEN_DOMAIN = {
  fivepm: API_HEADER_BRAND,
  livingsocial: process.env.NEXT_PUBLIC_BRAND,
  livingsocialie: `${process.env.NEXT_PUBLIC_BRAND}-ie`,
  wowcher: process.env.NEXT_PUBLIC_BRAND,
  wowcherie: `${process.env.NEXT_PUBLIC_BRAND}-ie`,
};

export const EMAIL_INPUT_SCROLL_TO_ID = 'emailInputScrollToId';

// SPLAT
export const SPLAT_MAX_WIDTH_PX = 48;

// TODO: update this with correct wowcher ie value
export const LOYALTY_BANNER_CONFIG = {
  [SITE_FIVEPM]: {
    id: 'wfe16e2da1004ab8c4eeef6fb60c4ac08',
    key: '6665313665326461313030346162386334656565663666623630633461633038',
  },
  [SITE_LIVINGSOCIAL]: {
    id: 'wc374469af6462dbec14f4fff38a90412',
    key: '6333373434363961663634363264626563313466346666663338613930343132',
  },
  [SITE_LIVINGSOCIAL_IE]: {
    id: 'w58a5fc2158fc19ccefc3b99aeb92f9f2',
    key: '3538613566633231353866633139636365666333623939616562393266396632',
  },
  [SITE_WOWCHER]: {
    id: 'wfe16e2da1004ab8c4eeef6fb60c4ac08',
    key: '6665313665326461313030346162386334656565663666623630633461633038',
  },
  // [SITE_WOWCHERIE]: {
  //   id: 'wfe16e2da1004ab8c4eeef6fb60c4ac08',
  //   key: '6665313665326461313030346162386334656565663666623630633461633038',
  // },
};

// TODO: update this with correct wowcher ie value
export const LOYALTY_IN_PAGE_BANNER_CONFIG = {
  [SITE_FIVEPM]: {
    id: 'w438e7aa4d0fa8635a8b9a091b9e72125',
    key: '3433386537616134643066613836333561386239613039316239653732313235',
  },
  [SITE_LIVINGSOCIAL]: {
    id: 'wf20c526f0d54f089e7db04c59df2e176',
    key: '6632306335323666306435346630383965376462303463353964663265313736',
  },
  [SITE_LIVINGSOCIAL_IE]: {
    id: 'w6c628e3f170cb482c7d82db1039d4804',
    key: '3663363238653366313730636234383263376438326462313033396434383034',
  },
  [SITE_WOWCHER]: {
    id: 'w438e7aa4d0fa8635a8b9a091b9e72125',
    key: '3433386537616134643066613836333561386239613039316239653732313235',
  },
  [SITE_WOWCHERIE]: {
    id: 'w438e7aa4d0fa8635a8b9a091b9e72125',
    key: '3433386537616134643066613836333561386239613039316239653732313235',
  },
};

export const GIFT_FINDER_FILTER = {
  BUDGET: 'Budget',
  FOR: 'For',
  TYPE: 'Character',
};

export const NEXT_BACK = {
  BACK: 'Back',
  NEXT: 'Next',
};

// Checkout delivery option types
export const STANDARD_DELIVERY_OPTION_TYPE = 1;
export const CLICK_AND_COLLECT_DELIVERY_OPTION_TYPE = 41;
export const VIP_SUBSCRIPTION_DEAL_ID = 18_927_099;

/* AWIN */
export const AWINCHANNEL = 'aw';

// Credit Card Processors
export const BRAINTREE = 'bt';

// Unbxd Keys
export const UNBXD_KEYS = {
  dev:
    'https://libraries.unbxdapi.com/sdk-clients/ss-unbxd-dev-wowcher-en47171696834830/ua-staging/ua.js',
  nxt:
    'https://libraries.unbxdapi.com/sdk-clients/ss-unbxd-stage-wowcher-en47171696834966/ua-staging/ua.js',
  prod:
    'https://libraries.unbxdapi.com/sdk-clients/ss-unbxd-prod-wowcher-en47171696835046/ua/ua.js',
};
export const UNBXD_KEYS_WOWCHER_IE = {
  dev:
    'https://libraries.unbxdapi.com/sdk-clients/ss-unbxd-auk-dev-wowcher-ie47171728987548/ua-staging/ua.js',
  nxt:
    'https://libraries.unbxdapi.com/sdk-clients/ss-unbxd-auk-dev-wowcher-ie47171728987548/ua-staging/ua.js',
  prod:
    'https://libraries.unbxdapi.com/sdk-clients/ss-unbxd-prod-wowcher-en47171696835046/ua/ua.js',
};

// BrandswapCheckout
export const BRANDSWAP_KEY = '6cb7a73fe2b44e75b669e8d9fdc1af46';

// React Carousel Config
export const intervalTime = 9_000;
export const transitionTime = 100;
export const transitionTimeTravel = 0;

export const COMPLIANCE_URL = `${process.env.NEXT_PUBLIC_PUBLIC_API}/v1/compliance`;
