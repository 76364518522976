import orderBy from 'lodash/orderBy';
import { BESTSELLER_CUE, FEATURE_FLAGS } from '../config/setup/setup';
import { OPTIONS, FROM, NOW, SOLD_OUT } from '../config/text/text';
import { formatCurrency } from './currency';

/** get if the row has multiple products to buy */
export const getHasMultiProducts = (deal, rowId) => {
  if (!deal || !deal.products || deal.products.length === 0) {
    return false;
  }
  const products = deal.products.filter((product) => product.rowId === rowId);

  return products.length > 1;
};

/* compares products prices and returns true if they are different */
export const arePricesDifferent = (deal) => {
  if (!deal || !deal.products || deal.products.length === 0) {
    return false;
  }

  const firstPrice = deal?.products[0].price;
  for (let index = 1; index < deal?.products?.length; index++) {
    if (deal?.products[index].price !== firstPrice) {
      return true;
    }
  }

  return false;
};
/** get if the row has vip exclusivce product to buy */
export const checkVipExclusiveForRow = (deal, rowId) => {
  if (!deal || !deal.products || deal.products.length === 0) {
    return false;
  }
  const products = deal.products.filter(
    (product) => product.rowId === rowId && product.vipExclusive,
  );

  return products.length > 0;
};

/** returns the minimum price of all products which match the row */
export const getMinProductPriceForRow = (deal, rowId, isVipUser) => {
  if (!deal || !deal.products || !rowId) {
    return 0;
  }
  const products = deal.products.filter((product) => product.rowId === rowId);

  if (products.length === 0) {
    return 0;
  }

  return products.reduce((min, product) => {
    const finalPrice =
      isVipUser &&
      (product.vipExclusive || deal?.display?.vipDiscountAllowed) &&
      product.vipPrice
        ? product.vipPrice
        : product.price;
    if (min === null || finalPrice < min) {
      return finalPrice;
    } else {
      return min;
    }
  }, null);
};

export const getMinProductPriceForSecondCheckoutMultiProduct = (
  deal,
  rowId,
  isVipUser,
) => {
  if (!deal || !deal.products) {
    return 0;
  }

  if (deal?.products.length === 0) {
    return 0;
  }

  return deal?.products.reduce((min, product) => {
    const finalPrice =
      isVipUser &&
      (product.vipExclusive || deal?.display?.vipDiscountAllowed) &&
      product.vipPrice
        ? product.vipPrice
        : product.price;
    if (min === null || finalPrice < min) {
      return finalPrice;
    } else {
      return min;
    }
  }, null);
};

/** Check for daily/weekly purchase cap  */
export const isPurchaseCap = (product) => {
  return product.dailyPurchaseCapReached || product.weeklyPurchaseCapReached;
};

/** get if there are available products for this row */
export const getIsRowAvailable = (deal, rowId) => {
  if (!deal || !deal.products || !rowId) {
    return false;
  }
  const availableProducts = deal.products.filter(
    (product) =>
      product.rowId === rowId && !product.soldOut && !isPurchaseCap(product),
  );

  return availableProducts.length > 0;
};
export const getIsSecondCheckoutMultiProductAvailable = (deal, rowId) => {
  if (!deal || !deal.products) {
    return false;
  }

  return deal.products.length > 0;
};

export const getMaxRevenueItem = (products) => {
  let maxBought = orderBy(products, 'totalRevenue', 'desc');

  if (maxBought[1] && maxBought[0].totalRevenue === maxBought[1].totalRevenue) {
    maxBought = orderBy(maxBought, 'totalRevenue totalRemaining', 'desc');
    if (maxBought[0].totalBought === 0) return;
  }

  return maxBought;
};

// get the bestselling item for row
export const checkBestsellerForRow = (deal, rowId) => {
  if (!FEATURE_FLAGS[BESTSELLER_CUE]) {
    return false;
  }
  const products = deal?.products?.filter(
    (product) => product.rowId === rowId && product.bestSellerClaim,
  );

  return products.length > 0;
};

// get the bestselling item for column
export const checkBestsellerForCol = (deal, columnId) => {
  if (!FEATURE_FLAGS[BESTSELLER_CUE]) {
    return false;
  }
  const products = deal?.products?.filter(
    (product) => product.columnId === columnId && product.bestSellerClaim,
  );

  return products.length > 0;
};

/** get the row items available for purchase */
export const getRowItems = (deal, isVipUser, isProductPage) => {
  if (
    !deal ||
    !deal.productDisplay ||
    !deal.productDisplay.row ||
    !deal.productDisplay.row.items
  ) {
    return [];
  }

  return deal.productDisplay.row.items.map((row, index) => {
    const minPrice = getMinProductPriceForRow(deal, row.id, isVipUser);
    const isAvailable = getIsRowAvailable(deal, row.id);
    const hasMultiProducts = getHasMultiProducts(deal, row.id);
    const isBestseller = checkBestsellerForRow(deal, row.id);
    const isVipExclusiveProduct = checkVipExclusiveForRow(deal, row.id);
    const suffix = isAvailable
      ? `${hasMultiProducts ? FROM : NOW} ${formatCurrency(
          deal.currency,
          minPrice,
          true,
          false,
        )}`
      : SOLD_OUT;

    return {
      disabled: !isAvailable,
      displayAs: isProductPage ? row.header : `${row.header} - ${suffix}`,
      id: row.id,
      isBestseller,
      vipExclusive: isVipExclusiveProduct,
    };
  });
};

export const getSecondCheckoutMultiProductItems = (
  deal,
  isVipUser,
  isProductPage,
) => {
  if (!deal || !deal.productDisplay) {
    return [];
  }

  return deal.products.map((row, index) => {
    const finalPrice =
      isVipUser &&
      (row.vipExclusive || deal?.display?.vipDiscountAllowed) &&
      row.vipPrice
        ? row.vipPrice
        : row.price;

    const isAvailable = getIsSecondCheckoutMultiProductAvailable(deal, row.id);
    const hasMultiProducts = getHasMultiProducts(deal, row.id);
    const isBestseller = checkBestsellerForRow(deal, row.id);
    const isVipExclusiveProduct = checkVipExclusiveForRow(deal, row.id);
    const suffix = isAvailable
      ? `${hasMultiProducts ? FROM : NOW} ${formatCurrency(
          deal.currency,
          finalPrice,
          true,
          false,
        )}`
      : SOLD_OUT;

    return {
      disabled: !isAvailable,
      displayAs: isProductPage
        ? row?.friendlyName
        : `${row.friendlyName} - ${suffix}`,
      id: row.id,
      isBestseller,
      vipExclusive: isVipExclusiveProduct,
    };
  });
};

/** get the row data in an easily displayable form */
export const getRow = (deal, isVipUser, isProductPage) => {
  if (!deal || !deal.productDisplay || !deal.productDisplay.row) {
    return { items: [], label: '' };
  }
  deal.products.map(function (item) {
    item.totalRevenue = item.totalBought * item.price;
  });

  return {
    items: getRowItems(deal, isVipUser, isProductPage),
    label: deal.productDisplay.row.header || OPTIONS,
  };
};

export const getSecondCheckoutRow = (deal, isVipUser, isProductPage) => {
  if (!deal || !deal.productDisplay) {
    return { items: [], label: '' };
  }

  deal.products.map(function (item) {
    item.totalRevenue = item.totalBought * item.price;
  });

  return {
    items: getSecondCheckoutMultiProductItems(deal, isVipUser, isProductPage),
    label: deal.productDisplay?.row?.header || OPTIONS,
  };
};

export const getIsColumnAvailable = (deal, rowId, columnId) => {
  if (!deal || !deal.products || !rowId || !columnId) {
    return false;
  }
  const availableProducts = deal.products.filter(
    (product) =>
      product.rowId === rowId &&
      product.columnId === columnId &&
      !product.soldOut &&
      !isPurchaseCap(product),
  );

  return availableProducts.length > 0;
};

export const checkVipExclusiveForCol = (deal, rowId, columnId) => {
  const products = deal.products.filter((product) => {
    if (
      product.vipExclusive &&
      product.rowId === rowId &&
      getIsColumnAvailable(deal, rowId, product.columnId)
    )
      return product;
  });

  return (
    getMaxRevenueItem(products) &&
    getMaxRevenueItem(products)[0]?.columnId === columnId
  );
};

/** get the column items that are available for the selected row */
export const getColumnItems = (deal, rowId, isVipUser, isProductPage) => {
  if (
    !deal ||
    !deal.productDisplay ||
    !deal.productDisplay.column ||
    !deal.productDisplay.column.items ||
    !deal.products ||
    !rowId
  ) {
    return [];
  } else {
    // filter only products with the specific rowId
    const products = deal.products.filter((product) => product.rowId === rowId);
    // for each match we find the corresponding column value
    return products.map((product) => {
      const column = deal.productDisplay.column.items.find(
        (item) => item.id === product.columnId,
      );
      const isAvailable = getIsColumnAvailable(deal, rowId, column.id);
      const isBestseller = checkBestsellerForCol(deal, column.id);
      const isVipExclusiveProduct = checkVipExclusiveForCol(
        deal,
        rowId,
        column.id,
      );
      const hasMultiProducts = getHasMultiProducts(deal, column.id);
      const finalPrice =
        isVipUser &&
        (product.vipExclusive || deal?.display?.vipDiscountAllowed) &&
        product.vipPrice
          ? product.vipPrice
          : product.price;

      const suffix = isAvailable
        ? `${hasMultiProducts ? FROM : NOW} ${formatCurrency(
            deal.currency,
            finalPrice,
            true,
            false,
          )}`
        : SOLD_OUT;

      return {
        disabled: !isAvailable,
        displayAs: isProductPage
          ? column.header
          : `${column.header} - ${suffix}`,
        id: column.id,
        isBestseller,
        vipExclusive: isVipExclusiveProduct,
      };
    });
  }
};

/** get the column data in an easily displayable way for the selected row */
export const getColumn = (deal, rowId, isVipUser, isProductPage) => {
  if (!deal || !deal.productDisplay || !deal.productDisplay.column || !rowId) {
    return { items: [], label: '' };
  }

  deal.products.map(function (item) {
    item.totalRevenue = item.totalBought * item.price;
  });

  return {
    items: getColumnItems(deal, rowId, isVipUser, isProductPage),
    label: deal.productDisplay.column.header || OPTIONS,
  };
};

/** get an image based on the selected row and column */
export const getImageForVariant = (
  deal,
  rowId,
  columnId,
  productId,
  isChooseYourProductDeal,
) => {
  if (!deal) {
    return null;
  }
  const match = deal?.products?.find((item) =>
    isChooseYourProductDeal
      ? item.id === Number.parseInt(productId)
      : item.rowId == rowId && item.columnId === columnId,
  );
  if (match && match?.images && match?.images?.length > 0) {
    return match.images[0];
  } else {
    return null;
  }
};

export const getImageIndexForVariant = (
  deal,
  rowId,
  columnId,
  productId,
  isChooseYourProductDeal,
) => {
  if (!deal) {
    return null;
  }
  const match = deal?.products?.find((item) =>
    isChooseYourProductDeal
      ? item.id === Number.parseInt(productId)
      : item.rowId === rowId && item.columnId === columnId,
  );

  let matchIndex = 0;
  if (match?.images) {
    matchIndex = deal?.images?.findIndex(
      (item) => item?.id === match?.images[0]?.id,
    );
  }

  return matchIndex;
};

export const allVariantsHasImages = (deal) => {
  if (!deal && !deal?.products) return null;

  return deal?.products.every((product) => {
    if (!product?.images) {
      return false;
    }

    return true;
  });
};

/** get the discount data for optional popup */
export const getDiscount = (deal, rowId, columnId) => {
  if (!deal.display?.discount) return deal;

  const discount = deal.products.find((item) => {
    if (item.rowId === rowId && item.columnId === columnId) {
      item.discountPercentage = item.discountPercentage || null;
      item.priceIndicative = false;
      item.display = {};
      item.display.discount = true;

      return item;
    }
  });

  return discount || deal;
};
