import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import { URLUSER, URLUSER_PASSWORD } from '../config/setup/setup';
import httpCommonHeaders from '../helpers/httpCommonHeaders';
import httpUserCommonHeaders from '../helpers/httpUserCommonHeaders';

export const getUser = async () => {
  const { data } = await axios({
    headers: httpUserCommonHeaders(),
    method: 'GET',
    url: URLUSER + `?origin=${location.origin}`,
    validateStatus: (status) => {
      return status < 500;
    },
    withCredentials: true,
  });

  return data;
};

export const getUserRafInfo = async () => {
  const { data } = await axios({
    headers: httpUserCommonHeaders(),
    method: 'GET',
    url: `${URLUSER}/raf/status` + `?origin=${location.origin}`,
    validateStatus: (status) => {
      return status < 500;
    },
    withCredentials: true,
  });

  return data;
};

export const updateUser = async (userDetails) => {
  const { data } = await axios({
    cache: false,
    data: {
      user: userDetails,
    },
    headers: httpUserCommonHeaders(),
    method: 'PUT',
    url: URLUSER,
    validateStatus: (status) => {
      return status < 500;
    },
    withCredentials: true,
  });

  return data;
};

export const updatePassword = async (passwordChange) => {
  return await axios({
    data: { passwordChange },
    headers: httpCommonHeaders(),
    method: 'patch',
    url: URLUSER_PASSWORD,
    validateStatus: (status) => {
      return status < 500;
    },
    withCredentials: true,
  });
};
