import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const DownloadAppLink = ({ src, href, alt, ariaLabel, inline }) => {
  return (
    <>
      <a
        aria-label={ariaLabel}
        className={classNames('download-link', {
          'download-link--inline': inline,
        })}
        href={href}
        rel="noreferrer noopener"
        target="_blank"
      >
        <img
          alt={alt}
          className="download-link__img"
          loading="lazy"
          src={src}
        />
      </a>
      <style jsx>{`
        .download-link {
          position: relative;
          display: block;
          margin-bottom: 5px;
        }
        .download-link--inline {
          display: inline-block;
          margin-bottom: 0;
        }
        .download-link--inline:first-child {
          width: calc(47% - 3px);
        }
        .download-link--inline:last-child {
          width: calc(53% - 3px);
        }
        .download-link__img {
          height: 100%;
          width: 100%;
          max-width: 160px;
        }
      `}</style>
    </>
  );
};

DownloadAppLink.propTypes = {
  alt: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default DownloadAppLink;
