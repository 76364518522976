export const centerItemInNav = (element) => {
  if (element) {
    const nav = document.querySelector('nav');
    if (nav) {
      const center = Math.ceil(nav.offsetWidth / 2);
      const { offsetLeft, offsetWidth } = element;
      const left = offsetLeft - (center - Math.ceil(offsetWidth / 2));
      nav.scrollTo({ behavior: 'smooth', left });
    }
  }
};

export const getURLs = ({ currentPage, lastPage, url }) => {
  const cleanUrl = url.split('/').pop().split('?')[0];
  const previousPage =
    currentPage === 2 ? 0 : currentPage === 0 ? null : currentPage - 1;
  const nextPage =
    currentPage === lastPage ? null : currentPage === 0 ? 2 : currentPage + 1;
  const previousUrl =
    currentPage === 2
      ? cleanUrl
      : currentPage === 0
      ? null
      : `${cleanUrl}?page=${currentPage - 1}`;
  const nextUrl =
    currentPage === lastPage
      ? null
      : currentPage === 0
      ? `${cleanUrl}?page=2`
      : `${cleanUrl}?page=${currentPage + 1}`;

  return { cleanUrl, nextPage, nextUrl, previousPage, previousUrl };
};

export const fivePmLocationsNavigations = [
  {
    externalLink: false,
    id: 1_000_001,
    isLocationPlaceholder: true,
    linkText: 'Glasgow',
    position: 1,
    shortName: 'glasgow',
    subNavigations: [
      {
        id: 2_000_001,
        linkText: 'Restaurants in Glasgow',
        shortName: 'restaurants-food',
        url: '/deals/glasgow/restaurants-food',
      },
      {
        id: 2_000_002,
        linkText: 'Things to do in Glasgow',
        shortName: 'activities-entertainment',
        url: '/deals/glasgow/activities-entertainment',
      },
      {
        id: 2_000_003,
        linkText: 'Afternoon Tea in Glasgow',
        shortName: 'afternoon-tea',
        url: '/deals/glasgow/food-drink/afternoon-tea',
      },
      {
        id: 2_000_004,
        linkText: 'Spa Days in Glasgow',
        shortName: 'spa',
        url: '/deals/glasgow/beauty/spa',
      },
      {
        id: 2_000_005,
        linkText: 'Beauty in Glasgow',
        shortName: 'beauty',
        url: '/deals/glasgow/beauty',
      },
      {
        id: 2_000_006,
        linkText: 'Fitness Classes in Glasgow',
        shortName: 'health-fitness',
        url: '/deals/glasgow/health-fitness',
      },
      {
        id: 2_000_007,
        linkText: 'Courses in Glasgow',
        shortName: 'learning',
        url: '/deals/glasgow/learning',
      },
      {
        id: 2_000_008,
        linkText: 'Food in Glasgow',
        shortName: 'food-drink',
        url: '/deals/glasgow/food-drink',
      },
    ],
    url: '/deals/glasgow',
  },
  {
    externalLink: false,
    id: 1_000_002,
    isLocationPlaceholder: true,
    linkText: 'Edinburgh',
    position: 2,
    shortName: 'edinburgh',
    subNavigations: [
      {
        id: 3_000_001,
        linkText: 'Restaurants in Edinburgh',
        shortName: 'restaurants-food',
        url: '/deals/edinburgh/restaurants-food',
      },
      {
        id: 3_000_002,
        linkText: 'Things to do in Edinburgh',
        shortName: 'activities-entertainment',
        url: '/deals/edinburgh/activities-entertainment',
      },
      {
        id: 3_000_003,
        linkText: 'Afternoon Tea in Edinburgh',
        shortName: 'afternoon-tea',
        url: '/deals/edinburgh/food-drink/afternoon-tea',
      },
      {
        id: 3_000_004,
        linkText: 'Spa Days in Edinburgh',
        shortName: 'spa',
        url: '/deals/edinburgh/beauty/spa',
      },
      {
        id: 3_000_005,
        linkText: 'Beauty in Edinburgh',
        shortName: 'beauty',
        url: '/deals/edinburgh/beauty',
      },
      {
        id: 3_000_006,
        linkText: 'Fitness Classes in Edinburgh',
        shortName: 'health-fitness',
        url: '/deals/edinburgh/health-fitness',
      },
      {
        id: 3_000_007,
        linkText: 'Courses in Edinburgh',
        shortName: 'learning',
        url: '/deals/edinburgh/learning',
      },
      {
        id: 3_000_008,
        linkText: 'Food in Edinburgh',
        shortName: 'food-drink',
        url: '/deals/edinburgh/food-drink',
      },
    ],
    url: '/deals/edinburgh',
  },
  {
    externalLink: false,
    id: 1_000_003,
    isLocationPlaceholder: true,
    linkText: 'Aberdeen',
    position: 3,
    shortName: 'aberdeen',
    subNavigations: [
      {
        id: 4_000_001,
        linkText: 'Restaurants in Aberdeen',
        shortName: 'restaurants-food',
        url: '/deals/aberdeen/restaurants-food',
      },
      {
        id: 4_000_002,
        linkText: 'Things to do in Aberdeen',
        shortName: 'activities-entertainment',
        url: '/deals/aberdeen/activities-entertainment',
      },
      {
        id: 4_000_003,
        linkText: 'Afternoon Tea in Aberdeen',
        shortName: 'afternoon-tea',
        url: '/deals/aberdeen/food-drink/afternoon-tea',
      },
      {
        id: 4_000_004,
        linkText: 'Spa Days in Aberdeen',
        shortName: 'spa',
        url: '/deals/aberdeen/beauty/spa',
      },
      {
        id: 4_000_005,
        linkText: 'Beauty in Aberdeen',
        shortName: 'beauty',
        url: '/deals/aberdeen/beauty',
      },
      {
        id: 4_000_006,
        linkText: 'Fitness Classes in Aberdeen',
        shortName: 'health-fitness',
        url: '/deals/aberdeen/health-fitness',
      },
      {
        id: 4_000_007,
        linkText: 'Courses in Aberdeen',
        shortName: 'learning',
        url: '/deals/aberdeen/learning',
      },
      {
        id: 4_000_008,
        linkText: 'Food in Aberdeen',
        shortName: 'food-drink',
        url: '/deals/aberdeen/food-drink',
      },
    ],
    url: '/deals/aberdeen',
  },
];

export const fivePmNavigationOrder = [
  { shortName: 'glasgow', showPopover: true, showScotland: false },
  { shortName: 'edinburgh', showPopover: true, showScotland: false },
  { shortName: 'aberdeen', showPopover: true, showScotland: false },
  { shortName: 'travel', showPopover: true, showScotland: false },
  { shortName: 'shop', showPopover: true, showScotland: false },
  { shortName: 'restaurants-food', showPopover: true, showScotland: true },
  { shortName: 'afternoon-tea', showPopover: true, showScotland: true },
  { shortName: 'spa', showPopover: false, showScotland: true },
  {
    shortName: 'activities-entertainment',
    showPopover: false,
    showScotland: true,
  },
  { shortName: 'christmas', showPopover: true, showScotland: false },
  { shortName: 'kids', showPopover: true, showScotland: false },
  // { shortName: 'gifts', showPopover: true, showScotland: false },
  { shortName: 'home', showPopover: true, showScotland: false },
  { shortName: 'electricals', showPopover: true, showScotland: false },
  { shortName: 'jewellery', showPopover: true, showScotland: false },
  { shortName: 'fashion', showPopover: true, showScotland: false },
  { shortName: 'beauty', showPopover: true, showScotland: true },
  {
    shortName: 'fitness-leisure-camping',
    showPopover: true,
    showScotland: false,
  },
  {
    shortName: 'beauty-products-equipment',
    showPopover: true,
    showScotland: false,
  },
  { shortName: 'menswear', showPopover: true, showScotland: false },
  { shortName: 'luggage-travel', showPopover: true, showScotland: false },
  {
    shortName: 'mystery-deals-competitions',
    showPopover: true,
    showScotland: false,
  },
  { shortName: 'garden', showPopover: true, showScotland: false },
  { shortName: 'health-fitness', showPopover: true, showScotland: true },
  { shortName: 'gift-finder', showPopover: true, showScotland: true },
  { shortName: 'personalised-gifts', showPopover: true, showScotland: false },
  { shortName: 'pets', showPopover: true, showScotland: false },
  { shortName: 'home-essentials', showPopover: true, showScotland: false },
  { shortName: 'learning', showPopover: true, showScotland: true },
  { shortName: 'food-drink', showPopover: true, showScotland: true },
  { shortName: 'adult', showPopover: true, showScotland: false },
];
