import capitalize from 'lodash/capitalize';
import memoize from 'lodash/memoize';
import { useMemo } from 'react';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import {
  DEAL_LOCATION_TYPES,
  PAGE_TYPES,
  PAGE_QUERY,
  DEAL_PAGE_PATH_QUALIFIERS,
  CATEGORY_PAGE_PATH_QUALIFIERS,
  NATIONAL_DEAL_QUALIFIERS,
  TRAVEL_DEAL_QUALIFIERS,
} from '../config/constants/page-types';
import { GUIDE_PAGE_SIZE, LINKS_GLOBAL_5PM } from '../config/links/links';
import {
  URLSTATIC,
  URLSTATICSUFFIX,
  URLNEARMEHUB,
  URLNEARMELOCATION,
  DEFAULT_LOCATION,
  API_HEADER_BRAND,
} from '../config/setup/setup';
import { getAppPlatform } from './device';
import ErrorTrackingService from './errorTrackingService';
import { guideUrl } from './guides';

export const getStaticContent = async ({
  context,
  withDealFeed,
  userLocation,
  isguidePage,
}) => {
  // We need to remove the .html that may can appear
  // URLSTATIC[environment].replace(REPLACE_BRAND, mainTheme).replace(REPLACE_DOMAIN, DOMAINS[domain.toLowerCase()]) +
  const url = `${URLSTATIC}/${context.query.slug
    .toString()
    .replace(/.html$/gi, '')}${URLSTATICSUFFIX}`;

  try {
    // page config
    const response = await axios(url, {
      headers: {
        'app-platform': getAppPlatform(),
        brand: API_HEADER_BRAND,
        'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
        'sub-brand': '5pm',
        webapp: true,
      },
      method: 'GET',
    });

    // deals feed if configured
    let deals = [];

    const dealsFeed = response?.data?.dealsFeed;

    if (dealsFeed && isguidePage) {
      dealsFeed.totalDeals = dealsFeed.dataPageSize;
      dealsFeed.dataPageSize = GUIDE_PAGE_SIZE;
    }

    const redirectUrl = response?.data?.redirectUrl;
    if (redirectUrl) {
      return {
        redirect: {
          destination: redirectUrl,
          statusCode: 301,
        },
      };
    }
    if (withDealFeed && dealsFeed) {
      const feedUrl = guideUrl({
        ...dealsFeed,
        userLocation: userLocation ? userLocation : DEFAULT_LOCATION.shortName,
      });
      if (feedUrl) {
        const feedResponse = await axios(feedUrl.toString(), {
          headers: {
            'all-locations': dealsFeed.allLocations || 'false',
            'app-platform': getAppPlatform(),
            brand: API_HEADER_BRAND,
            'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
            'sub-brand': '5pm',
            webapp: true,
          },
          method: 'GET',
        });
        deals = feedResponse?.data?.deals || [];
      } else {
        // If we can't build a feed url force the feed to be hidden.
        response.data.dealsFeed = null;
      }
    }

    return {
      // will be passed to the page component as props
      props: {
        additionalText: response.data.additionalText,
        deals,
        description: response.data.description || '',
        feedConfig: response.data.dealsFeed || null,
        listicleDeals: response.data.listicleDeals || null,
        listicleView: response.data.listicleView,
        text: response.data.text,
        title: response.data.displayName,
      },
    };
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    const { res } = context;
    if (res) res.statusCode = 404;

    return {
      // if url or data.data.text doesn't exist we will shown the 404 page
      props: { errorCode: true },
    };
  }
};

export const getNearMeHub = async (context) => {
  const url = URLNEARMEHUB;

  try {
    const { data } = await axios(url, {
      method: 'GET',
    });

    return { props: { data } };
  } catch {
    const { res } = context;
    res.statusCode = 404;
  }
};

export const getRafFaqs = async (context) => {
  const staticPageId =
    process.env.NEXT_PUBLIC_SITE === 'wowcherie' ? '1005036' : '1004351';
  const url = `${URLSTATIC}/${staticPageId}`;

  try {
    const { data } = await axios(url, {
      headers: {
        'sub-brand': '5pm',
      },
      method: 'GET',
    });

    return { props: { data } };
  } catch {
    const { res } = context;
    res.statusCode = 404;
  }
};

export const getNearMeLocation = async (context) => {
  const area = context.query.path;
  const url = URLNEARMELOCATION(area);

  try {
    const data = await axios(url, {
      method: 'GET',
    });

    return { props: { data: data.data, location: area } };
  } catch {
    const { res } = context;
    res.statusCode = 404;
  }
};

export const redirectToURL = (url, router, as) => {
  // router/Link only works for internal URLs
  // This function check if the url is external to redirect through document.location
  if (url && url.toLowerCase().startsWith('http')) {
    document.location.href = url;

    return;
  }
  if (!router || !url) return;
  if (as && as.length) {
    router.push(url, as);

    return;
  }
  router.push(url);
};

export const redirectToRoute = (url, router) => {
  if (!router || !url) return;
  router.push(url);
};

/**
 * Select the longest navigation link that matches the current url path
 * TODO: Is there a better way of getting selection when pages setup?
 */
export const useNavigationSelection = (navigation, path) => {
  const [pathString] = path.split(/\?|#/g);
  const pathArray = pathString.split('/').filter((item) => item.length);
  const { pageType } = parseWowcherPath(path);

  const pathWithoutDealTitle =
    pageType === PAGE_TYPES.deal ? pathArray.slice(0, -1) : pathArray;

  return useMemo(() => {
    const filtered = navigation.filter((item) =>
      pathWithoutDealTitle.includes(item.shortName),
    );

    const found = filtered.reduce((selection, item) => {
      if (selection === null || item.url.length > selection.url.length) {
        selection = item;
      }

      return selection;
    }, null);

    return found === null ? null : found.id;
  }, [navigation, path]);
};

export const findLinkMatchingPath = (linkCollection, path) => {
  return useMemo(() => {
    return linkCollection.find((link) => path.includes(link.href));
  }, [linkCollection, path]);
};

export const makeUrlRelativeToRoot = (url) => {
  if (typeof url === 'string' || url instanceof String) {
    return url.indexOf('/') === 0 ? url : `/${url}`;
  } else {
    return '/';
  }
};

export const makeUrlAbsolute = (url) => {
  const rootUrl =
    LINKS_GLOBAL_5PM[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'];
  const relativeUrl = makeUrlRelativeToRoot(url);

  return `${rootUrl}${relativeUrl}`;
};

// eslint-disable-next-line id-match
export const _stripOrigin = (url) => {
  if (typeof url === 'string' && url.startsWith('http')) {
    const { pathname, search } = new URL(url);

    return `${pathname}${search.length ? search : ''}`;
  } else return url;
};

export const stripOrigin = memoize(_stripOrigin);

const getLocalDealLocation = ([firstItem, secondItem, ...rest]) => {
  if (
    [...NATIONAL_DEAL_QUALIFIERS, ...TRAVEL_DEAL_QUALIFIERS].includes(
      secondItem,
    )
  ) {
    return null;
  }

  if (firstItem === 'near-me') {
    return rest[rest.length - 1];
  } else {
    return secondItem;
  }
};

export const getLocationType = ([, secondItem]) => {
  if (NATIONAL_DEAL_QUALIFIERS.includes(secondItem)) {
    return DEAL_LOCATION_TYPES.national;
    // eslint-disable-next-line no-negated-condition
  } else if (!TRAVEL_DEAL_QUALIFIERS.includes(secondItem)) {
    return DEAL_LOCATION_TYPES.local;
  } else {
    return null;
  }
};

const getDealIdOrEvergreenIndex = (pathArray) =>
  pathArray.findIndex((item) => /^(\d+|e)$/.test(item));

const getDealPageCategories = (pathArray) => {
  const dealIndex = pathArray.findIndex((item) =>
    DEAL_PAGE_PATH_QUALIFIERS.includes(item),
  );
  const dealIdOrEvergreenIndex = getDealIdOrEvergreenIndex(pathArray);

  let category;
  let subCategory;
  let subSubCategory;

  if (dealIndex !== -1 && dealIdOrEvergreenIndex !== -1) {
    [
      category = null,
      subCategory = null,
      subSubCategory = null,
    ] = pathArray.slice(dealIndex + 2, dealIdOrEvergreenIndex);
  }

  return {
    category,
    subCategory,
    subSubCategory,
  };
};

const getDealPageBlurb = (pathArray) => {
  const dealIdOrEvergreenIndex = getDealIdOrEvergreenIndex(pathArray);
  if (
    dealIdOrEvergreenIndex !== -1 &&
    pathArray.length > dealIdOrEvergreenIndex
  ) {
    return pathArray[dealIdOrEvergreenIndex + 1] ?? null;
  } else {
    return null;
  }
};

const getCategoryPageCategories = (pathArray) => {
  const isHyperlocal = pathArray[0] === 'near-me';

  if (isHyperlocal) {
    const [category = null, subCategory = null] = pathArray.slice(1, -1);

    return {
      category,
      subCategory,
    };
  } else {
    const [
      category = null,
      subCategory = null,
      subSubCategory = null,
    ] = pathArray.slice(2);

    return { category, subCategory, subSubCategory };
  }
};

export const getPageType = (pathArray, searchParameters) => {
  const [firstItem, secondItem, thirdItem] = pathArray;

  switch (true) {
    // deal-preview/[slug]?dealPreview=xxxxx
    case Boolean(searchParameters.get('dealPreview')):
      return PAGE_TYPES.dealPreview;

    // guides/[slug]?isClaimMyGift=true
    case firstItem === 'guides' &&
      Boolean(searchParameters.get(PAGE_QUERY.claimMyGift)):
      return PAGE_TYPES.claimExperienceGift;

    // guides/[slug]
    case firstItem === 'guides':
      return PAGE_TYPES.guide;

    // page '/'
    case !firstItem:
      return PAGE_TYPES.root;

    // deal/[location]/special/[slug]
    case thirdItem === 'special':
      return PAGE_TYPES.special;

    // deal/[location]/gift-finder
    case thirdItem === 'gift-finder':
      return PAGE_TYPES.giftFinder;

    // Welcome ppc
    case firstItem === 'gifts':
      return PAGE_TYPES.gifts;

    // recommended-for-you
    case firstItem === 'recommended-for-you':
      return PAGE_TYPES.recommended;

    // early-bird
    case firstItem === 'early-bird':
      return PAGE_TYPES.earlyBird;

    // recently-viewed
    case firstItem === 'recently-viewed':
      return PAGE_TYPES.recentlyViewed;

    // deals/vip-[location]
    case secondItem?.slice(0, 3) === 'vip' &&
      DEAL_PAGE_PATH_QUALIFIERS.includes(firstItem):
      return PAGE_TYPES.vipDeal;

    case secondItem?.slice(0, 3) === 'vip' &&
      CATEGORY_PAGE_PATH_QUALIFIERS.includes(firstItem):
      return PAGE_TYPES.vipCategory;

    // Homepage is /deals/shop
    case firstItem === 'deals' &&
      NATIONAL_DEAL_QUALIFIERS.includes(secondItem) &&
      pathArray.length === 2:
      return PAGE_TYPES.homepage;

    case CATEGORY_PAGE_PATH_QUALIFIERS.includes(firstItem):
      return PAGE_TYPES.category;

    case DEAL_PAGE_PATH_QUALIFIERS.includes(firstItem):
      return PAGE_TYPES.deal;

    case firstItem === 'search':
      return PAGE_TYPES.search;

    // Welcome ppc
    case firstItem === 'welcome':
      return PAGE_TYPES.ppc;

    default:
      return PAGE_TYPES.other;

    // Insights Dashboard
    case firstItem === 'insights':
      return PAGE_TYPES.dashboard;

    // Map View
    case firstItem === 'map-view':
      return PAGE_TYPES.mapView;
  }
};

const getSpecialPageDetails = (pathArray) => {
  return {
    location: getLocalDealLocation(pathArray),
    locationType: getLocationType(pathArray),
    slug: pathArray[pathArray.length - 1],
  };
};

const getVipPageDetails = (pathArray) => {
  return {
    dealId: pathArray.find((item) => /^\d+$/.test(item)) ?? null,
    vipLocation: getLocalDealLocation(pathArray),
  };
};

const getDealPageDetails = (pathArray) => {
  const { category, subCategory, subSubCategory } = getDealPageCategories(
    pathArray,
  );
  const dealIdIndex = pathArray?.findIndex((item) => /^\d+$/.test(item));
  const productIdIndex = pathArray?.findIndex(
    (item, index) => index !== dealIdIndex && /^\d+$/.test(item),
  );

  const productId = productIdIndex !== -1 ? pathArray[productIdIndex] : null;

  return {
    blurb: getDealPageBlurb(pathArray),
    category,
    dealId: pathArray.find((item) => /^\d+$/.test(item)) ?? null,
    isEmailDeal: ['email-deal', 'email-deals'].includes(pathArray[0]) ?? false,
    isEvergreen: pathArray.includes('e') ?? false,
    isTravel: TRAVEL_DEAL_QUALIFIERS.includes(pathArray[1]),
    localDealLocation: getLocalDealLocation(pathArray),
    locationType: getLocationType(pathArray),
    productId: productId ? productId : null,
    subCategory,
    subSubCategory,
  };
};

const getCategoryPageDetails = (pathArray) => {
  const { category, subCategory, subSubCategory } = getCategoryPageCategories(
    pathArray,
  );

  return {
    category,
    isHyperlocal: pathArray[0] === 'near-me' ?? false,
    isTravel: TRAVEL_DEAL_QUALIFIERS.includes(pathArray[1]),
    isTravelHomepage:
      pathArray.length === 2 && TRAVEL_DEAL_QUALIFIERS.includes(pathArray[1]),
    localDealLocation: getLocalDealLocation(pathArray),
    locationType: getLocationType(pathArray),
    subCategory,
    subSubCategory,
  };
};

const getPPCPageDetails = (pathArray) => {
  return {
    urlHasLocation: Boolean(pathArray.length > 2),
  };
};

const getDashboardPageDetails = (pathArray) => {
  const [firstItem, secondItem] = pathArray;

  return {
    firstItem,
    secondItem,
  };
};

export const pathLocationInfo = (path, locations) => {
  const [pathString] = path.split(/\?|#/g);
  const pathArray = pathString.split('/').filter((item) => item.length);
  const [firstItem, secondItem] = pathArray;

  const locationInPath = locations.find(
    (item) => item.shortName === secondItem,
  );

  const foundLocationShortName = locationInPath?.shortName
    ? locationInPath?.shortName
    : '';
  const foundLocationName = locationInPath?.name ? locationInPath?.name : '';

  const hasLocation =
    locations &&
    firstItem === 'deals' &&
    !TRAVEL_DEAL_QUALIFIERS.includes(secondItem) &&
    Boolean(foundLocationShortName);

  const isLocationPage = hasLocation && pathArray.length === 2;

  return {
    foundLocationName,
    foundLocationShortName,
    hasLocation,
    isLocationPage,
  };
};

// eslint-disable-next-line id-match
export const _parseWowcherPath = (inputPath) => {
  if (!inputPath.length) throw new Error('Empty string received');
  if (inputPath.startsWith('http'))
    throw new Error(
      'This function does not accept full URL. ' +
        'Strip domain / origin before passing a URL to this function ' +
        '(eg. with stripOrigin())',
    );

  // Strip Next.js json calls / paths
  const path = inputPath.includes('/_next/data')
    ? inputPath.replace(/_next\/data\/.+?\/|\.json/g, '')
    : inputPath;

  const [pathString, queryString] = path.split(/\?|#/g);
  const pathArray = pathString.split('/').filter((item) => item.length);
  const searchParameters = new URLSearchParams(queryString);
  // for deal-preview use deals as base path
  const index = pathArray.indexOf('deal-preview');
  if (index !== -1) {
    pathArray[index] = 'deals';
  }

  const pageType = getPageType(pathArray, searchParameters);

  let details;
  switch (true) {
    case pageType === PAGE_TYPES.special:
      details = getSpecialPageDetails(pathArray);
      break;

    case pageType === PAGE_TYPES.vipCategory:
    case pageType === PAGE_TYPES.vipDeal:
      details = getVipPageDetails(pathArray);
      break;

    case pageType === PAGE_TYPES.deal:
      details = getDealPageDetails(pathArray);
      break;

    case [
      PAGE_TYPES.category,
      PAGE_TYPES.homepage,
      PAGE_TYPES.dealPreview,
    ].includes(pageType):
      details = getCategoryPageDetails(pathArray);
      break;

    case pageType === PAGE_TYPES.ppc:
      details = getPPCPageDetails(pathArray);
      break;

    case pageType === PAGE_TYPES.dashboard:
      details = getDashboardPageDetails(pathArray);
      break;

    default:
      details = {};
  }

  return {
    details,
    pageType,
    pathArray,
    searchParams: searchParameters,
  };
};

export const parseWowcherPath = memoize(_parseWowcherPath);

export const slugAsTitle = (slug = '') => {
  return slug
    .split('-')
    .map((word) => capitalize(word))
    .join(' ');
};

export const slugAsSource = (slug = '') => {
  return slug
    .split('-')
    .map((word, index) => (index === 0 ? word : capitalize(word)))
    .join('');
};

export const getIsShopLayout = (pageType) =>
  pageType === PAGE_TYPES.homepage || pageType === PAGE_TYPES.vipCategory;

export const getAnchorFromUrl = (url) => {
  return url?.split('#')[1];
};

export const copyLinkToClipboard = async (link) => {
  await navigator.clipboard.writeText(link);
};

export const getBinaryUrl = (url) => {
  if (!url) return null;
  // Replacement mappings (patterns -> replacements)
  const replacements = {
    'Christmas-Gift-Me2': '5pm-Gift-Me',
    'Discreet-Packaging': '5pm-Discreet',
    'next-day-delivery-overlay': '5pm-next-day',
    'SPEEDY_DELIVERY-RH': '5pm-speedy',
    'spread-the-cost-RH': '5pm-spread-cost',

    // Note: Keep these patterns always on bottom
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    'WOW-': '5pm-',
    'Wow-': '5pm-',
  };

  for (const pattern in replacements) {
    if (url.includes(pattern)) {
      const replacement = replacements[pattern];

      // Using RegExp for dynamic patterns
      return url.replace(pattern, replacement);
    }
  }
};
