import cookie from 'react-cookies';
import COOKIES from '../config/cookies/cookies';
import {
  CLUB_WOWCHER_LEVELS,
  sendBulkDealsPoints,
} from '../services/clubWowcher';
import { trackError } from './analytics';

export const clubWowcherLevelToColor = (level) => {
  switch (level) {
    case CLUB_WOWCHER_LEVELS.Bronze:
      return '#D58836';
    case CLUB_WOWCHER_LEVELS.Silver:
      return '#9CA0A2';
    case CLUB_WOWCHER_LEVELS.Gold:
      return '#D1B53D';
    case CLUB_WOWCHER_LEVELS.Platinum:
      return '#6B7173';
    case CLUB_WOWCHER_LEVELS.Pink:
    default:
      return '#5A266A';
  }
};

export const getDealPointsData = (
  actionType,
  publisherSource = '',
  dealId,
  locationSite,
) => {
  let data = {
    customerToken: cookie.load(COOKIES.customerToken),
    dealId,
  };

  if (actionType === 'shareDeal') {
    data = {
      ...data,
      publisherSource,
    };
  } else {
    data = {
      ...data,
      locationSite,
    };
  }

  return data;
};

export const getStorageData = (dataType) =>
  JSON.parse(localStorage.getItem(dataType) || '[]');

export const updateLocalStorageDeals = (dataType, currentDealId, source) => {
  const localStorageData = getStorageData(dataType);
  const dataTypeProperty =
    dataType === 'viewDealData' ? 'locationSite' : 'publisherSource';

  const isDataSavedInLocalStorage = localStorageData?.length > 0;

  if (isDataSavedInLocalStorage) {
    const isCurrentDealSavedInLocalStorage = localStorageData.find(
      (deal) =>
        deal.dealId === currentDealId && deal[dataTypeProperty] === source,
    );

    if (!isCurrentDealSavedInLocalStorage) {
      localStorageData.push({
        [dataTypeProperty]: source,
        dealId: Number.parseInt(currentDealId),
      });
      localStorage.setItem(dataType, JSON.stringify(localStorageData));
    }
  } else {
    const data = [{ [dataTypeProperty]: source, dealId: currentDealId }];
    localStorage.setItem(dataType, JSON.stringify(data));
  }
};

export const sendBulkDataWhenAuthenticated = (isAuthenticated) => {
  const localStorageViewDealsData = getStorageData('viewDealData');

  const localStorageShareDealsData = getStorageData('shareDealData');

  const isDataInLocalStorage =
    localStorageViewDealsData?.length > 0 ||
    localStorageShareDealsData?.length > 0;

  const convertLocalStorageIdsToNumber = (data) =>
    data.map((deal) => ({
      ...deal,
      dealId: Number.parseInt(deal.dealId),
    }));

  if (isAuthenticated && isDataInLocalStorage) {
    const bulkData = {
      customerToken: cookie.load(COOKIES.customerToken),
      downloadToolbar: [],
      shareDeal: convertLocalStorageIdsToNumber(localStorageShareDealsData),
      viewDeal: convertLocalStorageIdsToNumber(localStorageViewDealsData),
    };

    try {
      sendBulkDealsPoints(bulkData);
      localStorage.removeItem('viewDealData');
      localStorage.removeItem('shareDealData');
    } catch (error) {
      trackError(error);
    }
  }
};
