import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import { URLAMBDA } from '../config/setup/setup';
import ErrorTrackingService from './errorTrackingService';

export const reSubscribeUsers = async (ct) => {
  try {
    return await axios({
      cache: false,
      method: 'GET',
      url: `${URLAMBDA}/user?ct=${ct}`,
    });
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);
  }
};
