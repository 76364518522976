/* eslint-disable react/no-unknown-property */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { getPopupWindowParameters } from '../../helpers/share';
import ThemeContext from '../../providers/ThemeProvider';

const SocialMediaShareButton = ({
  className,
  icon,
  href,
  backgroundColor,
  customOnClick,
  ariaLabel,
  popup,
}) => {
  const theme = useContext(ThemeContext);

  const size = icon.iconName === 'instagram' ? 23 : 20;

  const onClick = (event) => {
    if (popup) {
      event.preventDefault();
      window.open(href, 'share-popup', getPopupWindowParameters());
    }
    customOnClick();
  };

  return (
    <>
      <a
        aria-label={ariaLabel}
        className={classNames('share-button', className)}
        href={href}
        onClick={onClick}
        rel="noreferrer noopener"
        role="button"
        tabIndex={0}
        target="_blank"
      >
        <FontAwesomeIcon icon={icon} style={{ height: size, width: size }} />
      </a>
      <style jsx>{`
        .share-button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: white;
          background-color: ${backgroundColor};
          width: 30px;
          height: 30px;
          margin-right: 15px;
          border-radius: 4px;
        }
        .share-button.compact {
          margin-right: 4px;
        }
        .share-button.margin {
          margin-bottom: 4px;
        }
        .share-button.size--md {
          width: 36px;
          height: 36px;
        }
        .share-button.shape--square {
          border-radius: 0px;
        }
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .share-button.mobile-friendly {
            height: 48px;
            width: 48px;
            margin-right: 8px;
          }
          :global(.share-button.mobile-friendly svg) {
            transform: scale(1.5);
          }
        }
        .share-button:last-child {
          margin-right: 0;
        }
      `}</style>
    </>
  );
};

SocialMediaShareButton.propTypes = {
  ariaLabel: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  customOnClick: PropTypes.func,
  href: PropTypes.string,
  icon: PropTypes.object,
  popup: PropTypes.bool,
};

export default SocialMediaShareButton;
