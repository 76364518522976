// eslint-disable-next-line filenames/match-exported
import { GIFTING } from '../../config/constants/action-types';

const initialState = {
  giftWrap: null,
  nddGiftPack: null,
  stdGiftPack: null,
};
// eslint-disable-next-line default-param-last
const loading = (
  state = initialState,
  { type, giftWrap, nddGiftPack, stdGiftPack },
) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (type) {
    case GIFTING.RESET_GIFTING: {
      return {
        ...state,
        giftPack: null,
        giftWrap: null,
        nddGiftPack: null,
        stdGiftPack: null,
      };
    }
    case GIFTING.SET_NDD_GIFTING: {
      return {
        ...state,
        giftWrap,
        nddGiftPack,
      };
    }
    case GIFTING.SET_STD_GIFTING: {
      return {
        ...state,
        giftWrap,
        stdGiftPack,
      };
    }
    default:
      return state;
  }
};

export default loading;
