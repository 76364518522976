/**
 * We must have a deal that is walletAllowed and has a non-disabled product
 */
export const findWalletAllowed = (deals) => {
  if (Object.keys(deals).length === 0) {
    return false;
  }
  const walletAllowed = deals.find((deal) => {
    return deal.walletAllowed;
  });

  return Boolean(
    walletAllowed &&
      walletAllowed.products.find((product) => {
        return !product.disabled;
      }),
  );
};

/**
 * Get quantity for basket icon, no giftPack or giftWrap counted
 */
export const getBasketQuantity = (products) => {
  return products.reduce(
    (total, product) =>
      product.giftPack === false &&
      product.giftWrap === false &&
      product.visibility !== false
        ? total + product.quantity
        : total,
    0,
  );
};

export const setBasketParameters = (
  basketToken,
  customerToken,
  giftPacks,
  isEligibleForVip,
) => {
  const query = new URLSearchParams();
  if (basketToken) {
    query.set('basketToken', basketToken);
  }
  if (customerToken) {
    query.set('customerToken', customerToken);
  }
  if (giftPacks) {
    query.set('giftPacks', giftPacks);
  }

  if (typeof isEligibleForVip === 'boolean') {
    query.set('eligibleForVip', Boolean(isEligibleForVip));
  }

  return query.toString();
};
