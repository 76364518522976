import { CYF_DEAL_TYPE } from '../config/setup/setup';
import { makeUrlAbsolute } from './url';

/**
 * Calculates the max purchase quantity a user is alowed to select.
 */
export function getMaxPurchaseQuantity(isAuthenticated, deal, product) {
  if (deal.purchaseCap) {
    return isAuthenticated && product.userDealInfo
      ? Math.min(deal.purchaseCap, product.userDealInfo.maxQuantityUserCanBuy)
      : Math.min(deal.purchaseCap, product.purchaseCap);
  } else if (isAuthenticated && product.userDealInfo) {
    return product.userDealInfo.maxQuantityUserCanBuy;
  } else {
    return product.totalRemainingForDay
      ? Math.min(product.totalRemainingForDay, product.purchaseCap)
      : product.purchaseCap;
  }
}

/**
 * Generates an array of numbers from min to max inclusive.
 */
export function getSelectValues(min, max) {
  const out = [];
  for (let index = min; index <= max; index++) {
    out.push(index);
  }

  return out;
}

export function isOdd(number) {
  return number % 2 !== 0;
}

/**
 * @function - Returns the quantity of products marked as having giftpack from deals
 * @param {deals} deals from checkout API call
 */
export function getNumberProductsWithGiftpack(deals, giftPackId) {
  if (!deals) return 0;
  let totalGiftPacks = 0;
  deals.forEach((deal) => {
    deal.products.forEach((product) => {
      if (
        product.gifting &&
        product.gifting.gift === true &&
        product.gifting.giftPackProductId === giftPackId
      )
        totalGiftPacks += product.quantity;
    });
  });

  return totalGiftPacks;
}

/**
 * @function - Returns the quantity giftwrap from deals
 * @param {deals} deals from checkout API call
 */
export function getNumberOfGiftwrap(deals) {
  if (!deals) return 0;
  let quantity = 0;
  deals.forEach((deal) => {
    deal.products.forEach((product) => {
      if (
        product.gifting &&
        product.gifting.gift === true &&
        product.gifting.giftProductId
      )
        quantity += product.quantity;
    });
  });

  return quantity;
}

/**
 * Get the gift pack entry if it exists in the basket.
 */

export function getGiftPack(deals) {
  const totalGiftPacks = [];
  for (const deal of deals) {
    for (let ii = 0; ii < deal.products.length; ii++) {
      const product = deal.products[ii];
      if (product.giftPack) {
        totalGiftPacks.push(product);
      }
    }
  }

  return totalGiftPacks;
}

export const getGiftPackDetails = (deals, productId) => {
  for (const deal of deals) {
    for (let ii = 0; ii < deal.products.length; ii++) {
      const product = deal.products[ii];
      if (product.giftPack && product.id === productId) {
        return product;
      }
    }
  }

  return null;
};

/**
 * Get the gift wrap entry if it exists in the basket.
 */
export function getGiftWrap(deals) {
  for (const deal of deals) {
    for (let ii = 0; ii < deal.products.length; ii++) {
      const product = deal.products[ii];
      if (product.giftWrap) {
        return product;
      }
    }
  }
}

export function getDeliveryOptionsFromProducts(deals) {
  for (const deal of deals) {
    for (let ii = 0; ii < deal.products.length; ii++) {
      const product = deal.products[ii];
      if (product.productDeliveryOptions.length > 1) {
        return true;
      }
    }
  }
}

/**
 * Get the gift wrap entry from basket products
 */
export function getGiftWrapFromProducts(products) {
  for (const product of products) {
    if (product.giftWrap) {
      return product;
    }
  }
}

/**
 * Return true if all deal or product has reached purchase cap
 *
 * @param {object} deals
 * @returns {boolean}
 */
export const isAllPurchaseCapReached = (deals) => {
  if (!deals || deals.length === 0) return false;

  return deals.every((deal) => {
    return (
      deal.purchaseCap === 0 ||
      deal.products.some((product) => {
        return product?.userDealInfo?.maxQuantityUserCanBuy === 0;
      })
    );
  });
};

/**
 * Get basket products
 */
export const getSpikedOrSoldOutProducts = (deals) => {
  const products = [];
  deals.forEach((deal) => {
    deal.products.forEach((product) => {
      if (
        product.status === 'spiked' ||
        deal.status === 'spiked' ||
        product.soldOut ||
        deal.soldOut
      )
        products.push(product);
    });
  });

  return products;
};

/**
 * Get basket products
 */
export const getBasketProducts = (deals) => {
  const products = [];
  deals.map((deal) => {
    return deal.products.map((product) => {
      product.dealId = deal.id;
      product.closingDate = deal.closingDate;
      product.finalDealOrderAmount = deal.finalDealOrderAmount;
      product.highFraudRisk = deal.highFraudRisk;
      product.imageUrl = `${deal?.image?.imageUrl}.${deal?.image?.extension}`;
      product.url = makeUrlAbsolute(deal.shareUrl);
      product.business = deal.business.name;
      product.category = deal.category.name;
      product.subcategory = deal.subCategory.id;

      return products.push(product);
    });
  });

  return products;
};

/**
 * Get total quantity of items in the basket.
 */
export const getTotalQuantityItems = (deals) => {
  if (!deals) return 0;
  let quantity = 0;
  deals.map((deal) => {
    quantity += deal.products.reduce(
      (total, product) => total + product.quantity,
      0,
    );
  });

  return quantity;
};

/**
 * Get total quantity of items in the basket with out gift pack quantity.
 */
export const getTotalQuantityItemsForWithoutGiftPack = (deals) => {
  if (!deals) return 0;
  let quantity = 0;
  deals.map((deal) => {
    if (deal.visibility) {
      quantity += deal.products.reduce(
        (total, product) => (total + !product.giftPack ? product.quantity : 0),
        0,
      );
    }
  });

  return quantity;
};

export function isAtolProtected(deals) {
  for (const deal of deals) {
    if (deal.dealType === CYF_DEAL_TYPE) {
      return true;
    }
  }

  return false;
}

export function getIsVipSubscriptionInBasket(deals, vipSubscriptionDetails) {
  if (!deals || !vipSubscriptionDetails) return false;

  for (const deal of deals) {
    if (deal.id === vipSubscriptionDetails.dealId) {
      return true;
    }
  }

  return false;
}

export function getIsExclusiveProductInBasket(deals) {
  if (!deals) return false;
  for (const deal of deals) {
    if (!deal.products) return false;
    for (const product of deal.products) {
      if (product?.vipExclusive) {
        return true;
      }
    }
  }

  return false;
}

export function getIsExclusiveDealInBasket(deals) {
  if (!deals) return false;
  for (const deal of deals) {
    for (const product of deal.products) {
      if (product?.vipExclusive) {
        return true;
      }
    }
  }

  return false;
}

export function getGiftClaimInBasket(deals) {
  if (!deals) return false;

  const dealClaim = [];

  deals.forEach((deal) => {
    deal.products.forEach((product) => {
      if (product.giftClaim) dealClaim.push(product.giftClaim);
    });
  });

  return dealClaim.length > 0;
}

export function isGiftClaimableInBasket(deals) {
  if (!deals) return false;

  const dealClaim = [];

  deals.forEach((deal) => {
    deal.products.forEach((product) => {
      if (product.giftClaim) dealClaim.push(product.giftClaim);
    });
  });

  return dealClaim.length === deals.length;
}

export function getIsHighFraudRiskDealInBasket(deals) {
  if (!deals) return false;

  for (const deal of deals) {
    if (deal.highFraudRisk === true) {
      return true;
    }
  }

  return false;
}

export const addProfilingScript = (paymentDeviceProfile) => {
  if (!paymentDeviceProfile || !document) return;
  const { enabled, merchantId, orgId, sessionId } = paymentDeviceProfile;
  const SCRIPT_ID = 'profilingScript';
  const profilingScript = document && document.getElementById(SCRIPT_ID);
  if (enabled && !profilingScript) {
    let profileDomain = '';
    if (
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev' ||
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'nxt'
    ) {
      profileDomain = 'https://h.online-metrix.net';
    }
    const script = document.createElement('script');
    script.src = `${profileDomain}/fp/tags.js?org_id=${orgId}&session_id=${merchantId}${sessionId}`;
    script.id = SCRIPT_ID;
    document.body.appendChild(script);
  }
};

// is choose your flight
export function hasInternationalTravel(deals) {
  return deals.some((deal) => deal.dealType === CYF_DEAL_TYPE);
}

export const basketHasHighRiskDeal = (deals, isPostCheckout = false) => {
  if (isPostCheckout) return false;

  return deals.some((deal) => deal.highFraudRisk === true);
};

export const basketHasGamblingDeal = (deals, isClearpay = false) => {
  const noShowSubCats = isClearpay ? [197, 200, 218, 711] : [218, 711];

  return deals.some((deal) => noShowSubCats.includes(deal.subcategory));
};

const basketHasRestrictedDeal = (deals) => {
  const restrictedSubCats = [14, 17, 386, 398, 585, 586];

  return deals.some((deal) => restrictedSubCats.includes(deal.subcategory));
};

export const allowPayPalOneClick = (products, amount) => {
  return !basketHasRestrictedDeal(products) || amount < 50;
};
