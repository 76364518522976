import { COMMON_COLORS, BASE_FONTS, CASE_TYPES } from '../constants/common';
import {
  LIGHT_BOX_MODAL,
  ERROR_TYPES,
  FLAGS,
  CYF_BANNER,
  CYF_BANNER_MOBILE,
} from '../constants/images';

const fivepm = {
  bootstrap: {
    modalbackdropclassname: 'modal-backdrop-wowcher',
  },
  boxshadow: `${COMMON_COLORS.dropshadow} 0 3px 6px`,
  breakpoints: {
    lgDown: '991.98px',
    lgUp: '992px',
    mdDown: '767.98px',
    mdUp: '768px',
    smDown: '575.98px',
    smUp: '576px',
    xlDown: '1199.98px',
    xlUp: '1200px',
    xs: '450px',
    xxs: '320px',
  },
  colors: {
    arrowbackground: '#5A266A',
    arrowcolor: COMMON_COLORS.white,
    background: '#ffffff',
    basketcount: COMMON_COLORS.basketcount,
    bgvalidcolor: COMMON_COLORS.bgvalidcolor,
    bidomicolor: COMMON_COLORS.white,
    bodytext: COMMON_COLORS.greycharcoal,
    borderbottom: '#006ffd',
    breadcrumb: '#000',
    breadcrumbhover: '#5A266A',
    breadcrumbpale: COMMON_COLORS.greyshade,
    buttonbordercolor: '#520031',
    buttonprimary: COMMON_COLORS.buttonprimary,
    buttonprimaryhover: COMMON_COLORS.buttonprimaryhover,
    buttonsecondary: COMMON_COLORS.buttonsecondary,
    buttonsecondaryhover: COMMON_COLORS.buttonsecondaryhover,
    buttontertiary: COMMON_COLORS.buttontertiary,
    buttontertiaryhover: COMMON_COLORS.buttontertiaryhover,
    buttontext: COMMON_COLORS.white,
    carouselAdds: COMMON_COLORS.peppermint,
    charcoal: 'rgba(51, 51, 51, 1)',
    checkoutlinkhover: COMMON_COLORS.linkhover,
    checkoutloginlink: '#5A266A',
    checkoutloginlinkactive: '#ab004c',
    checkoutmodulebg: COMMON_COLORS.white,
    countdownnumbers: COMMON_COLORS.countdownnumbers,
    countdowntext: '#5A266A',
    cyfbanner: '#692649',
    dealavailability: '#E21B85',
    dealbackgroundcolor: COMMON_COLORS.white,
    dealboughtcounttext: '#5A266A',
    dealline: COMMON_COLORS.darkgrey,
    dealmobiletitle: COMMON_COLORS.white,
    dealtext: COMMON_COLORS.greycharcoal,
    dealvideoicon: COMMON_COLORS.white,
    dealviewbutton: '#0000EB',
    didomibackgroundcolor: COMMON_COLORS.jet,
    dropshadow: COMMON_COLORS.dropshadow,
    emptydeal: COMMON_COLORS.greylighter,
    emptysearbackground: COMMON_COLORS.white,
    emptysearchmessage: '#666666',
    errorboxbg: COMMON_COLORS.invalidbg,
    errorboxborder: COMMON_COLORS.inputinvalidcolor,
    errorboxtext: COMMON_COLORS.invalidtext,
    filtermodal: COMMON_COLORS.white,
    filterslider: '#5A266A',
    filtervipswitch: '#5B9AD5',
    footerbackground: COMMON_COLORS.jet,
    footerlegaltext: COMMON_COLORS.white,
    footerlink: COMMON_COLORS.white,
    giftBannerBackground: '#B285C0',
    giftButton: '#5A266A',
    giftButtonArrow: '#FFFFFF',
    headerbackground: '#5A266A',
    headergiftfinderbackground: '#5A266A',
    headergiftfindertext: '#ffffff',
    headerlocationbackgroundactive: '#5A266A',
    headerlocationtext: '#666666',
    headerlocationtextactive: '#ffffff',
    headerprimary: '#ffffff',
    headingtext: '#000000',
    ihealthtext: COMMON_COLORS.greyshade,
    imagelink: '#5A266A',
    infoboxbg: COMMON_COLORS.infoboxbg,
    infoboxborder: COMMON_COLORS.infoboxborder,
    infoboxtext: COMMON_COLORS.infoboxtext,
    infoyellow: '#d6a700',
    infoyellowbg: '#f8df8680',
    inputdefaultborder: COMMON_COLORS.inputdefaultborder,
    inputfocuscolor: COMMON_COLORS.inputfocuscolor,
    inputiconcolor: COMMON_COLORS.inputiconcolor,
    inputinvalidcolor: COMMON_COLORS.inputinvalidcolor,
    inputlogincolor: COMMON_COLORS.inputlogincolor,
    inputvalidcolor: COMMON_COLORS.inputvalidcolor,
    lightboxbuttonbackground: COMMON_COLORS.white,
    lightboxbuttonborderbottom: '1px solid eb008c',
    lightboxbuttoncolor: '#5A266A',
    lightboxcontainerborder: '1px solid rgba(0, 0, 0, 0.2)',
    lightboxcontainerboxshadow: '0 3px 9px rgba(0, 0, 0, 0.5)',
    lightboxgrayborders: '#c7c7c7',
    lightboxgreenborders: '#6bba70',
    lightboxlegaltextcolor: '#65bcdf',
    lightboxlegaltextcolor768: '#65bcdf',
    lightboxredborders: '#5A266A',
    lightboxsubmitbuttonbackground:
      'linear-gradient(180deg, #00b8fe 0, #006ffd)',
    lightboxsubmitbuttonborder: 'none',
    linealgradient1: '#00b8fe',
    linealgradient2: '#006ffd',
    linkhover: COMMON_COLORS.linkhover,
    loginmenubutoncolor: '#ffffff',
    minimenu: '#b285c0',
    mobilemenubg: COMMON_COLORS.white,
    navbackground: '#f0f0f0',
    navborder: '#d2d2d2',
    navlink: '#666666',
    navlinkactive: '#5A266A',
    navmorebackground: '#ffffff',
    navmoreheader: '#999999',
    notificationblue: '#2c90e9',
    notificationbluedark: '#4a90e2',
    notificationbluelight: '#eaf4fd',
    pandpcolor: '#e8d4f1',
    paymentbuttonbg: COMMON_COLORS.greyxlighter,
    primary: '#5A266A',
    primarydark: '#3B0051', // #8d2fb1
    primaryLight: '#8e2fb2',
    primaryLightVersion1: '#8e2fb2cc',
    primaryLightVersion2: '#8e2fb2a6',
    primaryLightVersion3: '#8e2fb270',
    primaryonwhite: '#5A266A',
    primarypale: '#e8d4f1',
    primarypromotion: '#5A266A',
    referfriendbanner: '#f7a0c6',
    searchbg: '#e8d4f1',
    searchbgfocused: '#ffffff',
    searchborder: '#5A266A',
    searchborderfocused: '#000000',
    searchbuttonbg: 'transparent',
    searchbuttonbgfocused: '#000000',
    searchbuttonfocused: '#ffffff',
    searchplaceholder: '#aaaaaa',
    searchtext: '#000000',
    secondary: '#00EBD9',
    socialcuecolor: {
      alert: COMMON_COLORS.alert,
      assure: COMMON_COLORS.assure,
      assurelighter: COMMON_COLORS.assurelighter,
      brandcolor: '#5A266A',
      primary: '#00EBD9',
      textonbrandcolor: '#ffffff',
      textonprimary: '#000000',
    },
    stepactivecolor: '#5a26aa',
    stepcompletedcolor: 'rgb(1, 185, 1)',
    stepinactivecolor: '#5a26aa30',
    subnavbackground: '#fff',
    subscribebutton: '#5A266A',
    successboxbg: COMMON_COLORS.bgvalidcolor,
    successboxborder: COMMON_COLORS.inputvalidcolor,
    successboxtext: COMMON_COLORS.validtext,
    successgreen: COMMON_COLORS.green,
    sucessgreenborder: COMMON_COLORS.greendark,
    textonprimary: '#ffffff',
    textonsecondary: '#ffffff',
    tilebordercolor: '#5A266A',
    toggleOffColor: '#9f9f9f',
    toggleOnColor: '#4ed064',
    trustpilotbackground: COMMON_COLORS.white,
    white: 'rgba(255, 255, 255, 1)',
  },
  commoncolors: COMMON_COLORS,
  fonts: {
    base: BASE_FONTS,
    theme: '',
  },
  images: {
    cyf: {
      banner: CYF_BANNER,
      bannermobile: CYF_BANNER_MOBILE,
    },
    dealsplat: 'https://resources.wowcher.co.uk/assets/img/5pm-squircle.svg',
    dealvipsplat: 'https://resources.wowcher.co.uk/images/splatVIP.svg',
    error404: ERROR_TYPES.brands.wowcher[404],
    flags: {
      headerflagie: FLAGS.ireland,
      headerflaguk: FLAGS.unitedKingdom,
    },
    headerlogo: 'https://resources.wowcher.co.uk/assets/img/5pm_white_OL.png',
    headerlogodefheight: '150',
    headerlogodefwidth: '233',
    headerlogolgwidth: '92px',
    headerlogomdwidth: '88px',
    headerLogoReversed:
      'https://resources.wowcher.co.uk/assets/img/5pm_logo_white_600_247.png',
    lightbox: {
      popeye: LIGHT_BOX_MODAL.brands.wowcher.background.popeye,
      subscribe: LIGHT_BOX_MODAL.brands.wowcher.background.subscribe,
    },
    vipheaderlogo:
      'https://resources.wowcher.co.uk/assets/img/5pm_White_VIP_Logo.svg', // update this
  },
  label: 'Wowcher',
  padding: {
    trustpilotbottom_mdup: '0',
  },
  styles: {
    breadcrumbtextshadow: 'none',
  },
  switches: {
    navvisibilitycount: {
      lg: 7,
      md: 5,
      xl: 10,
    },
    showCareersImageLink: true,
    showFlags: true,
  },
  text: {
    cardbutton: {
      fontsize: `2.5vw`,
      lineheight: `2.5vw`,
    },
    cta: {
      button: CASE_TYPES.upperCase,
    },
    error404: 'inherit',
    error404placeholder: 'inherit',
    lightbox: CASE_TYPES.upperCase,
    logintitle: {
      transform: 'none',
    },
    navigation: {
      size: 13,
      transform: CASE_TYPES.upperCase,
    },
    search: 'inherit',
    transform: CASE_TYPES.capitalize,
  },
};

export default fivepm;
