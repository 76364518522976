import axios from 'axios';
import { URLBASKET, URLPRODUCTDETAILPAGEDEAL } from '../config/setup/setup';
import {
  fetchBasketWithURL,
  replaceBasket,
  removeBasketProduct,
  upadateVipStatusCall,
} from '../services/basket';
import { basketCreator, mergeProducts } from './basketCreator';
import { isLightPage } from './browser';
import ErrorTrackingService from './errorTrackingService';
import httpCommonHeaders from './httpCommonHeaders';
import { isIESite } from './pages';

export const STATUS_ELIGIBLE = 'ELIGIBLE';
export const STATUS_NOT_ELIGIBLE = 'NOT_ELIGIBLE';
export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_RENEW = 'RENEW';
export const NOT_CHECKED = 'NOT_CHECKED';
export const VIP_LIMIT = 60;
export const MAX_VIP_COST = 4.99;

export const getIsHideCheckbox = ({
  finalOrderAmount,
  isAuthenticated,
  isExclusiveDealInBasket,
  userVipStatus,
}) => {
  return (
    (userVipStatus === STATUS_NOT_ELIGIBLE && isAuthenticated) ||
    (userVipStatus === STATUS_ACTIVE && isAuthenticated) ||
    (!isExclusiveDealInBasket && finalOrderAmount > VIP_LIMIT + MAX_VIP_COST) ||
    !isAuthenticated
  );
};

export const getIsEligibleForVip = (isAuthenticated, userVipStatus) => {
  return (
    isAuthenticated &&
    (userVipStatus === STATUS_ELIGIBLE ||
      userVipStatus === STATUS_RENEW ||
      userVipStatus === NOT_CHECKED)
  );
};

export const removeVipSubscriptionFromBasket = async (
  bt,
  vipSubscriptionDetails,
) => {
  return await removeBasketProduct(bt, vipSubscriptionDetails.productId);
};

export const addVipSubscriptionToBasket = async ({
  bt,
  isAuthenticated,
  locationStore,
  userVipStatus,
  vipSubscriptionDetails,
}) => {
  const basket = await fetchBasketWithURL(`${URLBASKET}/${bt}?giftPacks=true`);
  const deal = {
    id: vipSubscriptionDetails.dealId,
    products: [
      {
        discount: 0,
        discountPercentage: 0,
        displayDiscount: false,
        hasDeposit: false,
        id: vipSubscriptionDetails.productId,
        originalPrice: vipSubscriptionDetails.originalPrice,
        postagePrice: 0,
        price: vipSubscriptionDetails.price,
        purchaseCap: 1,
        quantity: 1,
        title: vipSubscriptionDetails.title,
      },
    ],
  };
  let products = basketCreator(deal, locationStore, 0, false, false);
  if (basket?.products) {
    products = mergeProducts(basket.products, products);
  }

  return await replaceBasket(
    bt,
    products,
    isAuthenticated,
    getIsEligibleForVip(isAuthenticated, userVipStatus),
  );
};

export const handleVipProduct = ({
  bt,
  checked,
  isAuthenticated,
  loadCheckoutData,
  locationStore,
  setIsChecked,
  trackEvent,
  userVipStatus,
  vipSubscriptionDetails,
}) => async (dispatch) => {
  if (!bt || !vipSubscriptionDetails) return;
  try {
    const response = checked
      ? await addVipSubscriptionToBasket({
          bt,
          isAuthenticated,
          locationStore,
          userVipStatus,
          vipSubscriptionDetails,
        })
      : await removeVipSubscriptionFromBasket(bt, vipSubscriptionDetails);

    if (response) setIsChecked(checked);
    trackEvent(checked ? 'wvip_tick' : 'wvip_untick');
    dispatch(loadCheckoutData(bt));
  } catch (error) {
    ErrorTrackingService.logError(
      new Error(
        `handleVIPError: ${error || 'error handling VIP product on basket'}`,
      ),
    );
  }
};

// Check or uncheck the vip subscribe box based on the final order amount in
export const addOrRemoveVip = ({
  finalOrderAmount,
  isAuthenticated,
  isVipSubscriptionInBasket,
  userVipStatus,
  isExclusiveDealInBasket,
}) => {
  if (
    (isVipSubscriptionInBasket &&
      finalOrderAmount > VIP_LIMIT + MAX_VIP_COST &&
      !isExclusiveDealInBasket) ||
    isLightPage()
  ) {
    return false;
  }
  if (
    !isVipSubscriptionInBasket &&
    (finalOrderAmount < VIP_LIMIT || isExclusiveDealInBasket) &&
    !isLightPage()
  ) {
    return true;
  }

  return null;
};

export const upadateVipStatus = async (isChecked) => {
  const status = isChecked ? STATUS_ELIGIBLE : NOT_CHECKED;

  return await upadateVipStatusCall(status);
};

export const getVipDeal = async () => {
  let vipNumber;
  if (isIESite()) {
    vipNumber = 20_543_436;
  } else {
    vipNumber = 18_927_099;
  }
  try {
    return await axios({
      headers: httpCommonHeaders(),
      method: 'GET',
      url: `${URLPRODUCTDETAILPAGEDEAL}/${vipNumber}`,
      validateStatus: (status) => {
        return status < 500;
      },
      withCredentials: true,
    });
  } catch (error) {
    console.warn(error);

    return null;
  }
};
