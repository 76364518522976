import { WISHLIST } from '../../config/constants/action-types';

const initialState = {
  quantity: '',
  wishlist: [],
};

const wishlist = (state = initialState, { type, wishlist }) => {
  switch (type) {
    case WISHLIST.SET_WISHLIST: {
      const quantity = wishlist.length;

      return {
        ...state,
        quantity: quantity ? quantity.toString() : '',
        wishlist,
      };
    }
    case WISHLIST.RESET_WISHLIST: {
      return {
        ...state,
        quantity: '',
        wishlist: [],
      };
    }
    default:
      return state;
  }
};

export default wishlist;
