import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FAVICON } from '../../config/constants/images';
import {
  BRAND_WOWCHER,
  SITE_LIVINGSOCIAL,
  SITE_NAME,
} from '../../config/setup/setup';
import { FACEBOOK_ID } from '../../config/social/config';
import HeaderContext from '../../providers/ProductIdProvider';

const HeaderTags = ({
  canonicalUrl = '',
  description = '',
  image = '',
  imageUrl = null,
  robotsMeta = 'INDEX, FOLLOW',
  productId,
  mainDeal,
  title = '',
  path,
}) => {
  const headerState = useContext(HeaderContext);
  let updatedProductUrl;
  if (headerState?.headerState === 0) {
    updatedProductUrl = `${process.env.NEXT_PUBLIC_BASE_ROOT_URL}${path}`;
  } else {
    updatedProductUrl = headerState?.headerState;
  }
  const Environment = process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER;
  const fav = FAVICON[Environment];
  const router = useRouter();
  let extraData = null;
  let extraCanonicalUrl = null;
  let isGoogleShopURL = false;
  if (typeof router?.query?.slug === 'object') {
    isGoogleShopURL = Boolean(
      router?.query?.slug?.find((element) => element === 'gshop'),
    );
  }
  if (isGoogleShopURL) {
    extraData = router?.query?.slug[router?.query?.slug?.length - 1];
    extraCanonicalUrl = canonicalUrl.split('/gshop/')[0];
  }

  let finalCanonicalUrl;
  let finalTitle;
  if (isGoogleShopURL) {
    finalCanonicalUrl = extraCanonicalUrl;
    finalTitle = `${title} - ${extraData}`;
  } else if (productId) {
    // Use replace method to update the URL with productId
    finalCanonicalUrl = updatedProductUrl.split('?')[0];
    const regex = /\/(\d+)\/(\d+)\//;
    const match = updatedProductUrl.match(regex);

    let productionId;
    if (match && match.length >= 3) {
      productionId = Number.parseInt(match[2]);
    }
    const filteredProduct = mainDeal?.products?.filter(
      (product) => product.id === productionId,
    );
    if (filteredProduct?.length > 0) {
      finalTitle = filteredProduct[0].friendlyName;
    }
  } else {
    finalCanonicalUrl = canonicalUrl;
    finalTitle = title;
  }
  // FOR TESTING PURPOSES ONLY, WILL REMOVE AFTER PROD TEST
  const isLivingSocialUK = process.env.NEXT_PUBLIC_SITE === SITE_LIVINGSOCIAL;
  let isDeal = false;
  if (typeof router?.query?.slug === 'object') {
    isDeal = Boolean(
      router?.query?.slug?.find((element) => element === '28376947'),
    );
  }
  const overrideNoIndex = isLivingSocialUK && isDeal;
  // FOR TESTING PURPOSES ONLY, WILL REMOVE AFTER PROD TEST

  return (
    <Head>
      <title>{finalTitle}</title>
      <link href={fav} rel="icon" />
      {imageUrl && <link as="image" href={imageUrl} rel="preload" />}
      <meta
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        name="viewport"
      />
      <meta content={finalTitle} name="title" />
      <meta content={description} name="description" />
      <meta content={robotsMeta} name="robots" />
      <meta content="!" name="fragment" />
      <meta
        content={SITE_NAME[process.env.NEXT_PUBLIC_SITE]}
        property="og:site_name"
      />
      <meta content={finalTitle} property="og:title" />
      <meta content="website" property="og:type" />
      <meta content={description} property="og:description" />
      <meta content={image} property="og:image" />
      <meta content={canonicalUrl} property="og:url" />
      <meta
        content={
          FACEBOOK_ID[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
            process.env.NEXT_PUBLIC_SITE || 'wowcher'
          ]
        }
        property="og:app_id"
      />
      <meta content="profile email" name="google-signin-scope" />
      <link href={finalCanonicalUrl} rel="canonical" />
      <link href={`/${Environment}/manifest.json`} rel="manifest" />

      {isGoogleShopURL && (
        <meta content={overrideNoIndex ? 'index' : 'noindex'} name="robots" />
      )}
    </Head>
  );
};

HeaderTags.propTypes = {
  canonicalUrl: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imageUrl: PropTypes.string,
  robotsMeta: PropTypes.string,
  title: PropTypes.string,
};

export default HeaderTags;
