import blackfriday from './blackfriday';
import christmas from './christmas';
import fivepm from './fivepm';
import livingsocial from './livingsocial';
import vip from './vip';
import wellness from './wellness';
import wowcher from './wowcher';

const themes = {
  fivepm,
  'fivepm-christmas': christmas.fivepm,
  'living-social': livingsocial,
  'livingsocial-blackfriday': blackfriday.livingsocial,
  'livingsocial-christmas': christmas.livingsocial,
  vip,
  wellness,
  wowcher,
  'wowcher-blackfriday': blackfriday.wowcher,
  'wowcher-christmas': christmas.wowcher,
};

export default themes;
