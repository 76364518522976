import Cookies from 'react-cookies';
import COOKIES from '../config/cookies/cookies';
import { LINK_LOGGED_USER, ANGULAR_HOME_URL } from '../config/links/links';
import { getProfile, setUser } from '../redux/actions/user';
import commonCookiesOptions from './commonCookiesOptions';
import { getLocationShortName } from './location';
import { redirectToURL } from './url';
import { getUserStatus } from './userStatus';

export const showPostCheckoutModal = async (isAuthenticated, userEmail) => {
  if (!isAuthenticated || !userEmail) return false;
  const { hasPassword, tpl } = await getUserStatus(userEmail);

  // password not set and not for Google/Facebook login
  return hasPassword === false && tpl === false;
};

export const showActivatePasswordModal = async (isAuthenticated, userEmail) => {
  const cookieValue = Cookies.load(COOKIES.confirmEmailRequired);

  return (
    (cookieValue === undefined || cookieValue === 'false') &&
    (await showPostCheckoutModal(isAuthenticated, userEmail))
  );
};

export const showConfirmEmailModal = async (isAuthenticated, userEmail) => {
  const cookieValue = Cookies.load(COOKIES.confirmEmailRequired);

  return (
    cookieValue === 'true' &&
    (await showPostCheckoutModal(isAuthenticated, userEmail))
  );
};

const isUserInAbTest = (testCookieName) => {
  const testCookieValue = Cookies.load(testCookieName);

  return (
    typeof testCookieValue === 'string' && testCookieValue.toLowerCase() === 'b'
  );
};

export const isUserInPasswordlessCheckoutTest = () => {
  return isUserInAbTest(COOKIES.passwordlessCheckoutTest);
};

export const isUserInBookingCalendarOnRedemptionTest = () => {
  // Booking calendar on redemption, old 'bc-test'
  return false;
};

export const isUserInBookingCalendarOnRedemptionGiftTest = () => {
  // Booking calendar on redemption
  return isUserInAbTest(COOKIES.abBookingCalendarOnRedemptionGift);
};

export const getAutocompleteTestValue = () => {
  const cookieValue = Cookies.load(COOKIES.abAutocomplete);
  if (
    typeof cookieValue === 'string' &&
    (cookieValue.toLowerCase() === 'b' || cookieValue.toLowerCase() === 'c')
  )
    return cookieValue;

  return 'a';
};

export const hasUserSetPassword = () => {
  return Cookies.load(COOKIES.hasPassword) === 'true';
};

export const resetPasswordToken = () => {
  return Cookies.load(COOKIES.passwordResetToken);
};

export const isUserAuthenticated = (user) => {
  if (typeof user === 'undefined')
    throw new Error('user object was not passed');

  if (user?.isAuthenticated && user?.userprofile) {
    return true;
  }

  return false;
};

export const redirectUserToLoginPage = async (userState, router, dispatch) => {
  const redirectLocal = Cookies.load(COOKIES.redirectLocal) || '';
  const redirectUrl = Cookies.load(COOKIES.redirectUrl) || '';
  const postLoginUrl = Cookies.load(COOKIES.postLoginUrl) || '';
  let result = false;
  if (!userState) {
    result = await dispatch(getProfile());
  }
  if (result || userState) {
    if (redirectLocal) {
      Cookies.remove(COOKIES.redirectLocal, {
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
        path: '/',
      });
      window.location.href =
        window.location.protocol + '//' + window.location.host + redirectLocal;

      return;
    }

    if (redirectUrl) {
      Cookies.remove(COOKIES.redirectUrl, {
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
        path: '/',
      });
      redirectToURL(`${ANGULAR_HOME_URL}${redirectUrl}`);

      return;
    }

    if (postLoginUrl) {
      Cookies.remove(COOKIES.postLoginUrl, {
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
        path: '/',
      });
      redirectToURL(postLoginUrl);

      return;
    }

    redirectToURL(LINK_LOGGED_USER, router);
  }
};

export const getUserLocationShortName = () => {
  const locationCookie = Cookies.load(COOKIES.location);

  return getLocationShortName(locationCookie);
};

export const updateUserDetails = (data) => (dispatch) => {
  const dodUserInfoObject = {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
  };

  Cookies.remove(COOKIES.userInfo, {
    domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
    path: '/',
  });
  Cookies.save(COOKIES.userInfo, dodUserInfoObject, commonCookiesOptions);

  Cookies.remove(COOKIES.userProfile, {
    domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
    path: '/',
  });
  Cookies.save(COOKIES.userProfile, data, commonCookiesOptions);

  setUserVipStatusToLocal(data?.vipUser);
  dispatch(setUser(data));
};

export const setUserVipStatusToLocal = (userVipStatus) => {
  if (userVipStatus) {
    localStorage.setItem('isUserVIP', 'VIP');
  } else if (userVipStatus === false) {
    localStorage.setItem('isUserVIP', 'Non - VIP');
  } else {
    localStorage.removeItem('isUserVIP');
  }
};
