/* eslint-disable filenames/match-exported */
import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

/**
 * A context to store information about the users last purchase.
 * This is used when transitioning from basket/quick buy to the
 * my 5pm page and showing the users purchsed items.
 */
const PurchaseContext = createContext([]);

export const PurchaseProvider = ({ children, testValue }) => {
  const orderIdState = useState(testValue || null);

  return (
    <PurchaseContext.Provider value={orderIdState}>
      {children}
    </PurchaseContext.Provider>
  );
};

PurchaseProvider.propTypes = {
  children: PropTypes.any,
  testValue: PropTypes.number,
};

export default PurchaseContext;
