// Booking Calendar on Redemption
// eslint-disable-next-line filenames/match-exported
import { BCOR } from '../../config/constants/action-types';

const initialState = {
  currentDealId: null,
  filteredProducts: null,
  hasError: false,
  nightOptions: null,
  products: null,
  quantity: 1,
  selectedNights: null,
  selection: null,
};

const bcorReducer = (
  state = initialState,
  {
    type,
    currentDealId,
    filteredProducts,
    nightOptions,
    products,
    quantity,
    selectedNights,
    selection,
  },
) => {
  switch (type) {
    case BCOR.SET_FILTERED_PRODUCTS: {
      return {
        ...state,
        filteredProducts,
        hasError: false,
      };
    }
    case BCOR.SET_CURRENT_DEAL_ID: {
      return {
        ...state,
        currentDealId,
      };
    }
    case BCOR.SET_NIGHT_OPTIONS: {
      return {
        ...state,
        nightOptions,
      };
    }
    case BCOR.SET_PRODUCTS: {
      return {
        ...state,
        products,
      };
    }
    case BCOR.SET_QUANTITY: {
      return {
        ...state,
        quantity,
      };
    }
    case BCOR.SET_SELECTION: {
      return {
        ...state,
        selection,
      };
    }
    case BCOR.SET_SELECTED_NIGHTS: {
      return {
        ...state,
        selectedNights,
      };
    }
    case BCOR.ERROR_BCOR: {
      return {
        ...state,
        hasError: true,
      };
    }
    case BCOR.RESET_BCOR: {
      return initialState;
    }
    default:
      return state;
  }
};

export default bcorReducer;
