import {
  FILTER_SUBCATEGORY,
  FILTER_CATEGORY,
  FILTER_SORTBY,
  FILTER_MINPRICE,
  FILTER_MAXPRICE,
  FILTER_ALL,
  FILTER_TAG,
} from '../config/setup/setup';
import { VIP } from '../config/text/text';
import { isSSR } from './ssr';

export const getNavigationCategoriesWithFaceted = (facetedNavigation) => {
  if (
    !facetedNavigation ||
    !facetedNavigation.navigations ||
    !facetedNavigation.navigations.length
  )
    return [];

  return facetedNavigation.navigations.map((item) =>
    Object.assign(
      {},
      {
        isActive: item.isActive,
        name: item.linkText,
        url: item.url,
        value: item.shortName || FILTER_ALL,
      },
    ),
  );
};

export const assignSliderValue = (value, options, defaultValue) => {
  const index = options.findIndex((entry) => entry.value === value);
  if (index > -1) {
    return index;
  } else {
    return defaultValue;
  }
};

export const getGiftUrlWithFilterQuery = ({
  categories,
  sortBy,
  sliderValues,
  filterPrice,
  giftsFor,
  searchTags,
}) => {
  const query = new URLSearchParams(window.location.search);

  if (categories) {
    query.delete(FILTER_CATEGORY);
    categories.forEach((category) => {
      query.append(FILTER_CATEGORY, category);
    });
  }

  if (giftsFor) {
    query.delete('giftFor');
    query.set('giftFor', giftsFor);
  }

  if (searchTags) {
    query.delete('searchTag');
    searchTags.forEach((tag) => {
      query.append(FILTER_TAG, tag);
    });
  }

  if (giftsFor === '') query.delete('giftFor');

  if (sortBy === '-1') {
    query.delete(FILTER_SORTBY);
  } else {
    query.set(FILTER_SORTBY, sortBy);
  }

  const [minIndex, maxIndex] = sliderValues;
  if (minIndex > 0) {
    query.set(FILTER_MINPRICE, filterPrice[minIndex].value);
  } else {
    query.delete(FILTER_MINPRICE);
  }
  if (maxIndex < filterPrice.length - 1) {
    query.set(FILTER_MAXPRICE, filterPrice[maxIndex].value);
  } else {
    query.delete(FILTER_MAXPRICE);
  }

  const search = query.toString();

  return `${window.location.pathname}${search.length ? '?' + search : ''}`;
};

export const getUrlWithFilterQuery = ({
  details,
  filterPrice,
  categories,
  subCat,
  sortBy,
  sliderValues,
  vipSearchFilter,
}) => {
  const query = new URLSearchParams(window.location.search);

  if (vipSearchFilter) {
    query.set(VIP.toLowerCase(), vipSearchFilter);
  } else {
    query.delete(VIP.toLowerCase());
  }

  if (categories) {
    query.delete(FILTER_CATEGORY);
    categories.forEach((category) => {
      query.append(FILTER_CATEGORY, category);
    });
  }

  if (
    // if the page has a subcat, we can't just delete the filter subcat else it will default to the subcat of the page
    (subCat === FILTER_ALL && !details.subCategory) ||
    // delete when the chosen subcat is the page subcat
    (subCat !== FILTER_ALL && subCat === details.subCategory)
  ) {
    query.delete(FILTER_SUBCATEGORY);
  } else {
    query.set(FILTER_SUBCATEGORY, subCat);
  }

  if (sortBy === '-1') {
    query.delete(FILTER_SORTBY);
  } else {
    query.set(FILTER_SORTBY, sortBy);
  }

  const [minIndex, maxIndex] = sliderValues;
  if (minIndex > 0) {
    query.set(FILTER_MINPRICE, filterPrice[minIndex].value);
  } else {
    query.delete(FILTER_MINPRICE);
  }
  if (maxIndex < filterPrice.length - 1) {
    query.set(FILTER_MAXPRICE, filterPrice[maxIndex].value);
  } else {
    query.delete(FILTER_MAXPRICE);
  }

  const search = query.toString();

  return `${window.location.pathname}${search.length ? '?' + search : ''}`;
};

export const isFiltered = () => {
  const query = new URLSearchParams(isSSR() ? '' : window.location.search);

  const filters = [
    FILTER_CATEGORY,
    FILTER_SUBCATEGORY,
    FILTER_SORTBY,
    FILTER_MINPRICE,
    FILTER_MAXPRICE,
  ];

  for (const filter of filters) {
    if (query.has(filter)) {
      const value = query.get(filter);
      // popularity is the default so we can consider it as not a filter.
      if (filter === FILTER_SORTBY && value === 'popularity') {
        continue;
      }

      return true;
    }
  }

  return false;
};
