export const SHOW_PRICE_TYPES = {
  hidden: 'PRICE_IS_HIDDEN',
  showDiscount: 'SHOW_DISCOUNT',
  showDiscountIndicative: 'SHOW_DISCOUNT_INDICATIVE',
  showOriginalPrice: 'SHOW_ORIGINAL_PRICE',
  showPrice: 'SHOW_PRICE',
  showVipDiscountPrice: 'SHOW_VIP_DISCOUNT_PRICE',
};

export const DEAL_CATEGORY_LOCATIONS = {
  shop: 'shop',
};
