/* eslint-disable react-hooks/exhaustive-deps */
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { COUNTDOWN_BRAND, DEFAULT_LOCATION } from '../../config/setup/setup';
import { isIESite } from '../../helpers/pages';
import ThemeContext from '../../providers/ThemeProvider';
import {
  getNavigation,
  showDefaultNavigation,
} from '../../redux/actions/navigation';
import HeaderNavigation from './navigation/HeaderNavigation';

const HeaderItemsLayout = dynamic(() => import('./HeaderItemsLayout'), {
  ssr: false,
});

const CountDown = dynamic(() => import('./countdown/CountDown'), {
  ssr: false,
});

const Header = ({ countdown, path, getMenu }) => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();

  const [location, navigation, ssr] = useSelector((state) => [
    state.locations.location,
    state.navigation.list || [],
    state.navigation.ssr,
  ]);

  const isIrelandSite = isIESite();

  useEffect(() => {
    if (
      location.shortName === DEFAULT_LOCATION.shortName &&
      !getMenu &&
      navigation.length > 0
    ) {
      // we get the default back from ssr so show right away
      dispatch(showDefaultNavigation());
    } else {
      // otherwise get location specific nav
      dispatch(getNavigation(location));
    }
  }, [dispatch, location]);

  const getCountDown = () => {
    if (
      countdown &&
      isIrelandSite &&
      COUNTDOWN_BRAND[process.env.NEXT_PUBLIC_SITE]
    )
      return <CountDown />;

    return null;
  };

  return (
    <>
      <header className="header">
        <div className="items">
          <HeaderItemsLayout
            location={location}
            navigation={navigation}
            path={path}
          />
        </div>
        <HeaderNavigation
          location={location}
          navigation={navigation}
          path={path}
          site={process.env.NEXT_PUBLIC_SITE}
          ssr={ssr}
        />
        <div className="countdown-container">{getCountDown()}</div>
      </header>
      <style jsx>{`
        .countdown-container {
          min-height: ${countdown &&
          COUNTDOWN_BRAND[process.env.NEXT_PUBLIC_SITE]
            ? '40px'
            : '0px'};
        }
        .items {
          background-color: ${theme.colors.headerbackground};
          min-height: ${isMobile ? '82px' : '105px'};
        }
        .margin-right-30 {
          margin-right: 30%;
        }

        @media (max-width: ${theme.breakpoints.mdDown}) {
          .header {
            position: sticky;
            top: 0;
            z-index: 1020;
          }
          .margin-right-30 {
            margin-right: 0;
          }
        }
      `}</style>
    </>
  );
};

Header.propTypes = {
  countdown: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  getMenu: PropTypes.bool,
  path: PropTypes.string,
};

Header.defaultProps = {
  countdown: null,
  getMenu: false,
  path: '',
};

export default Header;
