import { useEffect, useState } from 'react';

export const isSSR = () => {
  return Boolean(typeof window === 'undefined');
};

export const useIsSSR = () => {
  const [ssr, setSsr] = useState(true);

  useEffect(() => {
    setSsr(false);
  }, []);

  return ssr;
};
