import ReactCookies from 'react-cookies';
import { WISHLIST } from '../../config/constants/action-types';
import COOKIES from '../../config/cookies/cookies';
import { URLWISHLIST } from '../../config/setup/setup';
import { getOperationsOnWishlist } from '../../helpers/wishlist';
import {
  addToWishlist,
  fetchWishlistWithURL,
  createWishlist,
  mergeWishlist,
  removeMultipleDeal,
  removeAllDealsWishlist,
  removeOneDealWishlist,
} from '../../services/wishlist';

export const setWishlist = (wishlist) => (dispatch) => {
  return dispatch({ type: WISHLIST.SET_WISHLIST, wishlist });
};

export const resetWishlist = () => (dispatch) => {
  return dispatch({ type: WISHLIST.RESET_WISHLIST });
};

export const getWishlistWithURL = (url) => async (dispatch) => {
  if (!url) {
    throw new Error('URL missing');
  }
  const response = await fetchWishlistWithURL(url);
  if (response?.id) {
    dispatch(setWishlist(response.deals));
  }
};

/**
 * Helper to merge orphan wish lists and customer wish lists if required
 * will be called from checkout on login or if already logged in
 *
 */
export const mergeWishlists = () => async (dispatch) => {
  const ct = ReactCookies.load(COOKIES.customerToken);
  const wishlistId = ReactCookies.load(COOKIES.wishlistId);
  if (!ct || ct.length < 10) {
    return;
  }
  const customerTokenWishlistData = await fetchWishlistWithURL(
    `${URLWISHLIST}?customerToken=${ct}`,
  );

  if (
    wishlistId &&
    customerTokenWishlistData?.id &&
    customerTokenWishlistData?.id === wishlistId
  ) {
    dispatch(setWishlist(customerTokenWishlistData.deals));
    // eslint-disable-next-line no-negated-condition
  } else if (!wishlistId && customerTokenWishlistData) {
    ReactCookies.save(COOKIES.wishlistId, customerTokenWishlistData.id, {
      domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
      maxAge: 60 * 60 * 24 * 7,
      path: '/',
      secure: true,
    });
    dispatch(setWishlist(customerTokenWishlistData.deals));
  } else {
    const response = await mergeWishlist(ct, wishlistId);
    if (response?.id) {
      dispatch(setWishlist(response.deals));
    }
  }
};

/**
 * Helper to remove multiple deals from wish lists once the user successfully makes a purchase
 */
export const removeMultipleDeals = (deals, wishlist) => async (dispatch) => {
  if (!deals?.length) {
    throw new Error('Deals missing');
  }
  if (wishlist?.length) {
    const operations = getOperationsOnWishlist(wishlist, deals);
    if (operations.length) {
      const response = await removeMultipleDeal(operations);
      if (response?.id) {
        dispatch(setWishlist(response.deals));
      }
    }
  }
};

export const getWishlist = () => (dispatch) => {
  const wishlistId = ReactCookies.load(COOKIES.wishlistId);
  const userLoggedIn = ReactCookies.load(COOKIES.userLoggedIn);
  if (userLoggedIn) {
    dispatch(mergeWishlists());
  } else if (wishlistId) {
    dispatch(getWishlistWithURL(`${URLWISHLIST}/${wishlistId}`));
  } else {
    console.error('No Wish List ID');
  }

  return null;
};

/**
 * Generic helper to add a deal to wishlist
 *
 * @param {string} dealId   The new deal to insert
 */
export const addDealToWishlist = (dealId) => async (dispatch) => {
  if (!dealId) {
    throw new Error('args malformed');
  }
  const wishlistId = ReactCookies.load(COOKIES.wishlistId);
  if (wishlistId) {
    const response = await addToWishlist(wishlistId, dealId);
    if (response?.id) {
      dispatch(setWishlist(response.deals));
    } else {
      console.error(response.errorMessage);
    }
  } else {
    const response = await createWishlist(dealId);
    if (response?.id) {
      dispatch(setWishlist(response.deals));
    }
  }
};

/**
 * Generic helper function to empty basket
 *
 */
// eslint-disable-next-line unicorn/consistent-function-scoping
export const emptyWishlist = () => async (dispatch) => {
  const wishlistId = ReactCookies.load(COOKIES.wishlistId);
  if (wishlistId) {
    const response = await removeAllDealsWishlist(wishlistId);
    if (response?.id) {
      dispatch(resetWishlist());
    } else {
      console.error(response.errorMessage);
    }
  }
};

/**
 * Dispatch version of removing a wish list item
 *
 * @param {string} dealId
 */
export const removeWishlistDeal = (dealId) => async (dispatch) => {
  const wishlistId = ReactCookies.load(COOKIES.wishlistId);
  if (!wishlistId || !dealId) {
    throw new Error('args malformed');
  }
  const response = await removeOneDealWishlist(wishlistId, dealId);
  if (response?.id) {
    dispatch(setWishlist(response.deals));
  } else {
    console.error(response.errorMessage);
  }
};
