import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  FOOTER_DEALS_LINKS,
  FOOTER_COMPANY_LINKS,
  FOOTER_CUSTOMER_SERVICE_LINKS,
  LINKS_GLOBAL_5PM,
} from '../../config/links/links';
import {
  DOWNLOAD_THE_APP,
  FOOTER_COMPANY_TITLE,
  FOOTER_DEALS_TITLE,
  FOOTER_CUTOMER_SERVICE_TITLE,
} from '../../config/text/text';
import ThemeContext from '../../providers/ThemeProvider';
import CareersImageLink from './CareersImageLink';
import DownloadAppLinks from './DownloadAppLinks';
import FooterColHeading from './FooterColHeading';
import FooterContainer from './FooterContainer';
import FooterLegalText from './FooterLegalText';
import FooterLinkList from './FooterLinkList';
import SocialMediaShareButtons from './SocialMediaShareButtons';
import FooterCol from './footerCol';

const Footer = ({ site, isSecurePage, locationShortName }) => {
  const theme = useContext(ThemeContext);

  const deals = [...FOOTER_DEALS_LINKS];
  if (site === 'fivepm') {
    deals.unshift({
      href: `${
        LINKS_GLOBAL_5PM[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod']
      }/deals/${locationShortName}/restaurants-food`,
      text: 'Restaurants',
    });
    deals.unshift({
      href: `${
        LINKS_GLOBAL_5PM[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod']
      }/deals/${locationShortName}`,
      text: 'Local',
    });
  }

  return (
    <>
      <FooterContainer>
        <div className="footer__row footer__row--flex footer__row--links">
          <FooterCol>
            <FooterLinkList linksList={deals} title={FOOTER_DEALS_TITLE} />
          </FooterCol>
          <FooterCol>
            <FooterLinkList
              linksList={FOOTER_COMPANY_LINKS}
              title={FOOTER_COMPANY_TITLE}
            />
          </FooterCol>
          <FooterCol>
            <FooterLinkList
              linksList={FOOTER_CUSTOMER_SERVICE_LINKS}
              title={FOOTER_CUTOMER_SERVICE_TITLE}
            />
          </FooterCol>
          {/* <FooterCol className="footer__col--hidden-mobile">
            <FooterColHeading>{DOWNLOAD_THE_APP}</FooterColHeading>
            <DownloadAppLinks site={site} />
          </FooterCol>
          <FooterCol
            className="footer__col--hidden-mobile"
            noRender={site !== 'wowcher' && site !== 'fivepm'}
          >
            <CareersImageLink />
          </FooterCol> */}
        </div>
        <div className="footer__row">
          <FooterLegalText isSecurePage={isSecurePage} site={site} />
        </div>
        <div className="footer__row footer__row--flex footer__row--social">
          <SocialMediaShareButtons site={site} />
        </div>
      </FooterContainer>
      <style jsx>{`
        .footer__row {
          margin-bottom: 12px;
        }
        .footer__row:last-child {
          margin-bottom: 0;
        }
        .footer__row--flex {
          display: flex;
        }
        .footer__row--social {
          justify-content: flex-end;
        }
        @media (max-width: ${theme.breakpoints.mdDown}) {
          .footer__row {
            margin-bottom: 0;
          }
          .footer__row--flex {
            flex-direction: column;
          }
          .footer__row--social {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

Footer.propTypes = {
  isSecurePage: PropTypes.bool,
  locationShortName: PropTypes.string,
  site: PropTypes.string,
};

Footer.defaultProps = {
  isSecurePage: false,
  site: 'fivepm',
};

export default Footer;
