/* eslint-disable filenames/match-regex */
import { COMMON_COLORS, BASE_FONTS, CASE_TYPES } from '../constants/common';
import { LIGHT_BOX_MODAL, ERROR_TYPES, FLAGS } from '../constants/images';

const wellness = {
  bootstrap: {
    modalbackdropclassname: 'modal-backdrop-wowcher',
  },
  boxshadow: `${COMMON_COLORS.dropshadow} 0 3px 6px`,
  breakpoints: {
    lgDown: '991.98px',
    lgUp: '992px',
    mdDown: '767.98px',
    mdUp: '768px',
    smDown: '575.98px',
    smUp: '576px',
    xlDown: '1199.98px',
    xlUp: '1200px',
    xs: '450px',
  },
  colors: {
    arrowbackground: '#368f8a',
    arrowcolor: COMMON_COLORS.white,
    background: '#ffffff',
    basketcount: COMMON_COLORS.basketcount,
    bgvalidcolor: COMMON_COLORS.bgvalidcolor,
    bidomicolor: COMMON_COLORS.white,
    bodytext: COMMON_COLORS.greycharcoal,
    borderbottom: '#006ffd',
    breadcrumb: '#000',
    breadcrumbhover: '#368f8a',
    breadcrumbpale: COMMON_COLORS.greyshade,
    buttonprimary: COMMON_COLORS.buttonprimary,
    buttonprimaryhover: COMMON_COLORS.buttonprimaryhover,
    buttonsecondary: COMMON_COLORS.buttonsecondary,
    buttonsecondaryhover: COMMON_COLORS.buttonsecondaryhover,
    buttontertiary: COMMON_COLORS.buttontertiary,
    buttontertiaryhover: COMMON_COLORS.buttontertiaryhover,
    buttontext: COMMON_COLORS.white,
    charcoal: 'rgba(51, 51, 51, 1)',
    checkoutlinkhover: COMMON_COLORS.linkhover,
    checkoutloginlink: '#368f8a',
    checkoutloginlinkactive: '#ab004c',
    checkoutmodulebg: COMMON_COLORS.white,
    countdownnumbers: COMMON_COLORS.countdownnumbers,
    countdowntext: COMMON_COLORS.jet,
    dealavailability: '#E21B85',
    dealbackgroundcolor: COMMON_COLORS.white,
    dealboughtcounttext: '#368f8a',
    dealmobiletitle: COMMON_COLORS.white,
    dealtext: COMMON_COLORS.greycharcoal,
    dealvideoicon: COMMON_COLORS.white,
    dealviewbutton: '#368f8a',
    didomibackgroundcolor: COMMON_COLORS.jet,
    dropshadow: COMMON_COLORS.dropshadow,
    emptydeal: COMMON_COLORS.greylighter,
    emptysearbackground: COMMON_COLORS.white,
    emptysearchmessage: '#666666',
    errorboxbg: COMMON_COLORS.invalidbg,
    errorboxborder: COMMON_COLORS.inputinvalidcolor,
    errorboxtext: COMMON_COLORS.invalidtext,
    filtermodal: COMMON_COLORS.white,
    filterslider: '#368f8a',
    footerbackground: COMMON_COLORS.jet,
    footerlegaltext: COMMON_COLORS.white,
    footerlink: COMMON_COLORS.white,
    headerbackground: '#ffffff',
    headergiftfinderbackground: '#368f8a',
    headergiftfindertext: '#ffffff',
    headerlocationbackgroundactive: '#368f8a',
    headerlocationtext: '#666666',
    headerlocationtextactive: '#ffffff',
    headerprimary: '#368f8a',
    headingtext: '#000000',
    ihealthtext: COMMON_COLORS.greyshade,
    imagelink: '#368f8a',
    infoboxbg: COMMON_COLORS.infoboxbg,
    infoboxborder: COMMON_COLORS.infoboxborder,
    infoboxtext: COMMON_COLORS.infoboxtext,
    inputdefaultborder: COMMON_COLORS.inputdefaultborder,
    inputfocuscolor: COMMON_COLORS.inputfocuscolor,
    inputiconcolor: COMMON_COLORS.inputiconcolor,
    inputinvalidcolor: COMMON_COLORS.inputinvalidcolor,
    inputlogincolor: COMMON_COLORS.inputlogincolor,
    inputvalidcolor: COMMON_COLORS.inputvalidcolor,
    lightboxbuttonbackground: COMMON_COLORS.white,
    lightboxbuttonborderbottom: '1px solid 368f8a',
    lightboxbuttoncolor: '#368f8a',
    lightboxcontainerborder: '1px solid rgba(0, 0, 0, 0.2)',
    lightboxcontainerboxshadow: '0 3px 9px rgba(0, 0, 0, 0.5)',
    lightboxgrayborders: '#c7c7c7',
    lightboxgreenborders: '#6bba70',
    lightboxlegaltextcolor: '#65bcdf',
    lightboxlegaltextcolor768: '#65bcdf',
    lightboxredborders: '#368f8a',
    lightboxsubmitbuttonbackground:
      'linear-gradient(180deg, #00b8fe 0, #006ffd)',
    lightboxsubmitbuttonborder: 'none',
    linealgradient1: '#00b8fe',
    linealgradient2: '#006ffd',
    linkhover: COMMON_COLORS.linkhover,
    loginmenubutoncolor: '#ffffff',
    minimenu: '#b8006e',
    mobilemenubg: COMMON_COLORS.white,
    navbackground: '#f0f0f0',
    navborder: '#d2d2d2',
    navlink: '#666666',
    navlinkactive: '#368f8a',
    navmorebackground: '#ffffff',
    navmoreheader: '#999999',
    notificationblue: '#2c90e9',
    notificationbluedark: '#4a90e2',
    notificationbluelight: '#eaf4fd',
    paymentbuttonbg: COMMON_COLORS.greyxlighter,
    primary: '#368f8a',
    primarydark: '#b8006e',
    primaryonwhite: '#368f8a',
    primarypale: 'rgba(243, 243, 243, 0.9)',
    searchbg: '#f1faee',
    searchbgfocused: '#ffffff',
    searchborder: '#368f8a',
    searchborderfocused: '#000000',
    searchbuttonbg: 'transparent',
    searchbuttonbgfocused: '#000000',
    searchbuttonfocused: '#ffffff',
    searchplaceholder: '#aaaaaa',
    secondary: '#00EBD9',
    socialcuecolor: {
      alert: COMMON_COLORS.alert,
      assure: COMMON_COLORS.assure,
      assurelighter: COMMON_COLORS.assurelighter,
      brandcolor: '#368f8a',
      primary: '#00EBD9',
      textonprimary: '#000000',
    },
    subnavbackground: '#fff',
    subscribebutton: '#368f8a',
    successboxbg: COMMON_COLORS.bgvalidcolor,
    successboxborder: COMMON_COLORS.inputvalidcolor,
    successboxtext: COMMON_COLORS.validtext,
    textonprimary: '#ffffff',
    textonsecondary: '#ffffff',
    tilebordercolor: '#368f8a',
    white: 'rgba(255, 255, 255, 1)',
  },

  commoncolors: COMMON_COLORS,
  fonts: {
    base: BASE_FONTS,
    theme: '',
  },
  images: {
    banner: 'https://static.wowcher.co.uk/binaries/Wowcher%20banner_v2-140.png',
    dealsplat:
      'https://static.wowcher.co.uk/binaries/WOW-Splat-teal-150-x150%20%281%29.png',
    error404: ERROR_TYPES.brands.wowcher[404],
    flags: {
      headerflagie: FLAGS.ireland,
      headerflaguk: FLAGS.unitedKingdom,
    },
    headerlogo:
      'https://static.wowcher.co.uk/binaries/Wowcher_logo%20duo%20green.png',
    headerlogodefheight: '62',
    headerlogodefwidth: '62',
    headerlogolgwidth: '59.06px',
    headerlogomdwidth: '59.06px',
    headerLogoReversed:
      'https://resources.wowcher.co.uk/images/wowcher_logo_white.png',
    lightbox: {
      popeye: LIGHT_BOX_MODAL.brands.wowcher.background.popeye,
      subscribe: LIGHT_BOX_MODAL.brands.wowcher.background.subscribe,
    },
  },
  label: 'Wowcher',
  switches: {
    navvisibilitycount: {
      lg: 7,
      md: 5,
      xl: 10,
    },
    showCareersImageLink: true,
    showFlags: false,
  },
  text: {
    cardbutton: {
      fontsize: `2.5vw`,
      lineheight: `2.5vw`,
    },
    cta: {
      button: CASE_TYPES.upperCase,
    },
    error404: 'inherit',
    error404placeholder: 'inherit',
    lightbox: CASE_TYPES.upperCase,
    logintitle: {
      transform: 'none',
    },
    navigation: {
      size: 13,
      transform: CASE_TYPES.upperCase,
    },
    search: 'inherit',
    transform: CASE_TYPES.capitalize,
  },
};

export default wellness;
