// Function generats unique ID, currently used only for Toasts

export const generateUEID = () => {
  let first = (Math.random() * 46_656) | 0;
  let second = (Math.random() * 46_656) | 0;
  first = ('000' + first.toString(36)).slice(-3);
  second = ('000' + second.toString(36)).slice(-3);

  return first + second;
};
