import ReactCookies from 'react-cookies';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import COOKIES from '../config/cookies/cookies';
import {
  COOKIE_SUBSCRIBED_EXPIRES,
  URLWISHLIST,
  WISHLISTSUFFIX,
  URLLIVEDEAL,
} from '../config/setup/setup';
import commonCookiesOptions from '../helpers/commonCookiesOptions';
import ErrorTrackingService from '../helpers/errorTrackingService';
import httpCommonHeaders from '../helpers/httpCommonHeaders';

/**
 * API call to add a deal id to a wish list with a given wish list id and a deal id
 *
 * @param {string} wishlistId wishlist id
 * @param {string} dealId wishlist deal id
 * @returns {object} - wishlist data
 */
export const addToWishlist = async (wishlistId, dealId) => {
  if (!wishlistId) return null;
  try {
    const { data } = await axios({
      headers: httpCommonHeaders(),
      method: 'POST',
      url: `${URLWISHLIST}/${wishlistId}/deal/${dealId}`,
    });

    return data;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return null;
  }
};

/**
 * API call to create a wishlist with a given deal id
 *
 * @param {string} dealId wishlist deal id
 * @returns {object} - wishlist data
 */
export const createWishlist = async (dealId) => {
  try {
    const { data } = await axios(`${URLWISHLIST}/deal/${dealId}`, {
      headers: httpCommonHeaders(),
      method: 'POST',
    });
    if (data?.id) {
      const expire = new Date();
      expire.setDate(expire.getDate() + COOKIE_SUBSCRIBED_EXPIRES);
      ReactCookies.save(COOKIES.wishlistId, data.id, {
        ...commonCookiesOptions,
        expire,
      });
    }

    return data;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return null;
  }
};

/**
 * API call to fetch a wishlist from a given URL
 *
 * @param {string} url wishlist url
 * @returns {object} - wishlist data
 */
export const fetchWishlistWithURL = async (url) => {
  try {
    const { data } = await axios({
      headers: httpCommonHeaders(),
      method: 'GET',
      url,
    });
    if (data.id) {
      if (!ReactCookies.load(COOKIES.wishlistId)) {
        ReactCookies.save(COOKIES.wishlistId, data.id, {
          domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
          maxAge: 60 * 60 * 24 * 365,
          path: '/',
        });
      }

      return data;
    } else {
      console.error(data.errorMessage);

      return null;
    }
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return null;
  }
};

/**
 * API call to get the information of the deal ids saved on the wish given a string with concatenated deal ids
 *
 * @param {string} deals concatenated deal ids coming from helpers/wishlist {getConcatDeals}
 * @returns {object} - wishlist data
 */
export const getWishlistDeals = async (deals) => {
  if (!deals) return null;
  const url = `${URLLIVEDEAL}${WISHLISTSUFFIX}${deals}`;
  try {
    const { data } = await axios({
      headers: httpCommonHeaders(),
      method: 'GET',
      url,
    });

    return data;
  } catch (error) {
    ErrorTrackingService.logError(error);
    throw new Error(error);
  }
};
/**
 * API call to merge two wishlist with a given customer token and a wish list id
 *
 * @param {string} ct customer token
 * @param {string} wishlistId wishlist id
 * @returns {object} - wishlist data
 */
export const mergeWishlist = async (ct, wishlistId) => {
  if (!wishlistId) return null;
  try {
    const { data } = await axios({
      data: {
        operations: [
          {
            op: 'merge',
            path: '/customerToken',
            value: ct,
          },
        ],
      },
      headers: httpCommonHeaders(),
      method: 'PATCH',
      url: `${URLWISHLIST}/${wishlistId}`,
    });
    if (data.id) {
      ReactCookies.save(COOKIES.wishlistId, data.id, {
        domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
        maxAge: 60 * 60 * 24 * 7,
        path: '/',
        secure: true,
      });
    }

    return data;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return null;
  }
};

/**
 * API call to remove all deal ids attached to a wish list with a given wish list id
 *
 * @param {string} wishlistId wishlist id
 * @returns {object} - wishlist data
 */
export const removeAllDealsWishlist = async (wishlistId) => {
  if (!wishlistId) return null;
  try {
    const { data } = await axios({
      headers: httpCommonHeaders(),
      method: 'DELETE',
      url: `${URLWISHLIST}/${wishlistId}/deal/all`,
    });

    return data;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return null;
  }
};

/**
 * API call to remove multiple deals from a wishlist given an array of deal ids to remove
 *
 * @param {Array} operations array of deal ids to be removed alongside the proper operations expected by the API
 * @returns {object} - wishlist data
 */
export const removeMultipleDeal = async (operations) => {
  const wishlistId = ReactCookies.load(COOKIES.wishlistId);
  if (!wishlistId) return null;
  try {
    const { data } = await axios({
      data: {
        operations,
      },
      headers: httpCommonHeaders(),
      method: 'PATCH',
      url: `${URLWISHLIST}/${wishlistId}`,
    });

    return data;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return null;
  }
};

/**
 * API call to remove a single deal id attached to a wish list with a given wish list id and a deal id
 *
 * @param {string} wishlistId wishlist id
 * @param {string} dealId wishlist deal id
 * @returns {object} - wishlist data
 */
export const removeOneDealWishlist = async (wishlistId, dealId) => {
  if (!wishlistId) return null;
  try {
    const { data } = await axios({
      headers: httpCommonHeaders(),
      method: 'DELETE',
      url: `${URLWISHLIST}/${wishlistId}/deal/${dealId}`,
    });

    return data;
  } catch (error) {
    console.warn(error);
    ErrorTrackingService.logError(error);

    return null;
  }
};
