import ReactCookies from 'react-cookies';
import axios, {
  logToSplunk,
} from '../../components/_generic/axiosSplunk/axiosSplunk';
import { BASKET } from '../../config/constants/action-types';
import COOKIES from '../../config/cookies/cookies';
import {
  URLBASKET,
  BRAND_HYPHEN_DOMAIN,
  GIFTING_OPTIONS,
  COOKIES_EXPIRES_WEEK,
} from '../../config/setup/setup';
import { trackEvent } from '../../helpers/analytics';
import {
  getGiftPack,
  getGiftWrap,
  getSpikedOrSoldOutProducts,
  getNumberProductsWithGiftpack,
  getNumberOfGiftwrap,
  getGiftPackDetails,
} from '../../helpers/checkout';
import commonCookiesOptions from '../../helpers/commonCookiesOptions';
import httpCommonHeaders from '../../helpers/httpCommonHeaders';
import {
  fetchBasketWithURL,
  fetchBasketWithURLWithCT,
  removeBasketProduct,
} from '../../services/basket';
import { CHECKOUT_STATUS } from '../reducers/checkout';
import { getCheckoutData, setCheckoutStatus } from './checkout';

export const createBasket = async (
  products,
  isEligibleForVip,
  customerToken = null,
) => {
  // if the customerToken is not provided it will always create an orphan basket for the user
  try {
    const response = await axios(URLBASKET, {
      data: {
        brand: BRAND_HYPHEN_DOMAIN[process.env.NEXT_PUBLIC_SITE],
        customerToken,
        eligibleForVip: isEligibleForVip,
        products,
      },
      headers: {
        vipVersion: 'v1.1',
        ...httpCommonHeaders({ isGPK: true }),
      },
      method: 'POST',
      withCredentials: true,
    });

    if (response.headers.location) {
      const headersLocation = response.headers.location.split('/');
      const code = headersLocation.slice(-1);
      const expire = new Date();
      expire.setDate(expire.getDate() + COOKIES_EXPIRES_WEEK);
      // bt
      if (code[0].length > 10) {
        ReactCookies.save(COOKIES.basketToken, code[0], {
          ...commonCookiesOptions,
          expire,
        });

        return code[0];
      }

      logToSplunk({
        bt: code[0],
        ct: customerToken,
        description:
          'basket token retrieved from basket POST call is inaccurate ',
      });

      return null;
    }

    return null;
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const setBasket = (basket) => (dispatch) => {
  return dispatch({ basket, type: BASKET.SET_BASKET });
};

export const getBasketWithURL = (url) => async (dispatch) => {
  const responseData = await fetchBasketWithURL(url);
  if (responseData) dispatch(setBasket(responseData));
};

export const getBasket = (isAuthenticated = false) => (dispatch) => {
  try {
    const basketToken = ReactCookies.load(COOKIES.basketToken);

    if (basketToken) {
      // We try the Basket Token
      dispatch(getBasketWithURL(`${URLBASKET}/${basketToken}?giftPacks=true`));
    } else if (isAuthenticated) {
      // We try with the Customer Token ct
      const customerToken = ReactCookies.load(COOKIES.customerToken);
      if (customerToken) {
        dispatch(
          getBasketWithURL(
            `${URLBASKET}?customerToken=${customerToken}&giftPacks=true`,
          ),
        );
      }
    }

    return null;
  } catch (error) {
    console.error(error);

    return null;
  }
};

export const resetBasket = () => (dispatch) => {
  return dispatch({ type: BASKET.RESET_BASKET });
};

export const fetchBasket = async (
  basketToken,
  customerToken,
  isAuthenticated = false,
) => {
  if (customerToken && isAuthenticated) {
    return fetchBasketWithURLWithCT(
      `${URLBASKET}?customerToken=${customerToken}&giftPacks=true`,
    );
  } else if (basketToken && basketToken.length > 10) {
    return fetchBasketWithURL(`${URLBASKET}/${basketToken}?giftPacks=true`);
  }

  return null;
};

/**
 * Generic helper to add a product to a specified basket
 *
 * @param {string} basketToken     basket token to update
 * @param {object} data   The new product to insert
 */
export const addBasketProduct = async (basketToken, data) => {
  if (!basketToken || !data) {
    //  TODO: Don't throw errors if they are not captured
    // throw new Error('args malformed');
    return null;
  }
  const url = `${URLBASKET}/${basketToken}/product`;
  try {
    await axios({
      data,
      headers: {
        id: basketToken,
        vipVersion: 'v1.1',
        ...httpCommonHeaders({ isGPK: true }),
      },
      method: 'POST',
      url,
      withCredentials: true,
    });

    return basketToken;
  } catch (error) {
    console.error(error);

    return null;
  }
};

/**
 * Generic helper function to patch a basket product with new values
 *
 * @param {string} basketToken           // the basket id
 * @param {string} productId    // the product to update
 * @param {object} data         // partial data to patch the product with
 */
export async function updateBasketProduct(basketToken, productId, data) {
  if (!basketToken || !productId || !data) {
    //  TODO: Don't throw errors if they are not captured
    // throw new Error('args malformed');
    return null;
  }
  const url = `${URLBASKET}/${basketToken}/product/${productId}`;
  await axios({
    data,
    headers: {
      vipVersion: 'v1.1',
      ...httpCommonHeaders({ isGPK: true }),
    },
    method: 'PATCH',
    url,
    withCredentials: true,
  });

  return true;
}

/**
 * Generic helper function to empty basket
 *
 * @param {string} basketToken
 */
export const emptyBasketProducts = async (basketToken) => {
  if (!basketToken) {
    //  TODO: Don't throw errors if they are not captured
    // throw new Error('args malformed');
    return null;
  }
  const url = `${URLBASKET}/${basketToken}/product`;
  await axios({
    headers: {
      vipVersion: 'v1.1',
      ...httpCommonHeaders({ isGPK: true }),
    },
    method: 'DELETE',
    url,
    withCredentials: true,
  });

  return true;
};

/**
 * set gift wrap quantity
 *
 * @param {string} basketToken         id of Basket to update
 * @param {object} giftWrap    existing giftWrap object
 * @param {number} newQuantity    set the gift wrap quantity to this
 * @param {object} [toast]    toast object to show a message
 */
export const updateGiftWrapQuantity = async (
  basketToken,
  giftWrap,
  newQuantity,
  toast,
) => {
  const quantityToSet = Math.max(newQuantity, 0);

  if (quantityToSet === 0) {
    // remove the gift wrap
    await removeBasketProduct(basketToken, giftWrap.id);
  }
  if (quantityToSet > 0 && giftWrap.quantity === 0) {
    // add a new gift wrap with correct quantity to the basket
    await addBasketProduct(basketToken, {
      ...giftWrap,
      quantity: quantityToSet,
    });
    if (toast) toast.addToast('Gift Wrap Added', 'toast-success', 'top-right');
  }
  if (quantityToSet > 0 && giftWrap.quantity !== 0) {
    // update gift wrap quantity
    await updateBasketProduct(basketToken, giftWrap.id, {
      giftWrap: true,
      quantity: quantityToSet,
    });
  }
};

/**
 * dispatch to update the quantity of a specific product in a basket
 */
export const updateProductQuantity = (
  basketToken,
  product,
  quantity,
  oldQuantity,
  giftWrap,
) => async (dispatch, getState) => {
  dispatch(setCheckoutStatus(CHECKOUT_STATUS.UPDATING));

  // if (product.gifting && product.gifting.type === GIFTING_OPTIONS.WRAPPED) {
  //   // remove/decrement/increment the gift wrap if assigned to this product
  //   const quantityDiff = quantity - oldQuantity;
  //   const newQuantity = giftWrap.quantity + quantityDiff;
  //   await updateGiftWrapQuantity(basketToken, giftWrap, newQuantity);
  // }

  await updateBasketProduct(basketToken, product.id, {
    quantity,
  });
  const checkoutData = await getCheckoutData(
    basketToken,
    getState().checkout.queryParams,
  );
  dispatch(checkoutData);
  dispatch(setCheckoutStatus(CHECKOUT_STATUS.READY));
};

/**
 * Add a gift pak to the basket if it doesn't exist or increments quantity if already present.
 *
 * @param {string} bt         id of Basket to update
 * @param {object} giftPack    Either the current gift pack from the basket or the default gift pack product
 * @param {object} toast    toast object to show a message
 */
export const addGiftPackToBasket = async (
  bt,
  toast,
  giftPackDetails,
  checkoutData,
  totalProductQuantity,
) => {
  const addedGiftPack = getGiftPackDetails(
    checkoutData.deals,
    giftPackDetails.productId,
  );
  if (addedGiftPack) {
    await updateBasketProduct(bt, addedGiftPack.id, {
      giftPack: true,
      quantity: addedGiftPack.quantity + totalProductQuantity,
    });
  } else {
    const giftPackObject = {
      dealId: giftPackDetails.dealId,
      deliveryOptionType: giftPackDetails.deliveryOptionType,
      gift: false,
      giftPack: true,
      giftWrap: false,
      id: giftPackDetails.productId,
      payDeposit: false,
      quantity: totalProductQuantity,
    };
    await addBasketProduct(bt, giftPackObject);
  }
  toast.addToast('Gift Pack Added', 'toast-success', 'top-right');
};

/**
 * remove a gift pack from the basket if it no longer exist or decrements quantity if still needed by other products.
 *
 * @param {string} basketToken         id of Basket to update
 * @param {object} giftPack    Either the current gift pack from the basket or the default gift pack product
 */
export const removeGiftPackFromBasket = async (
  basketToken,
  giftPack,
  totalProductQuantity,
) => {
  if (!giftPack || giftPack.quantity <= 0) {
    // no gift pack to remove
    return true;
  } else if (giftPack.quantity === totalProductQuantity) {
    // add a new gift pack to the basket
    await removeBasketProduct(basketToken, giftPack.id);
  } else {
    // update the existing gift pack quantity
    // -- for some reason giftPack: true is overwritten on PATCH so define explicitly
    await updateBasketProduct(basketToken, giftPack.id, {
      giftPack: true,
      quantity: giftPack.quantity - totalProductQuantity,
    });
  }
};

/**
 * set gift pack quantity
 *
 * @param {string} basketToken         id of Basket to update
 * @param {object} giftPack    giftPack object with quantity set to the required number
 */
export const updateGiftPackQuantity = async (basketToken, giftPack) => {
  if (giftPack.quantity === 0) {
    // remove
    await removeBasketProduct(basketToken, giftPack.id);
  } else {
    // update quantity
    await updateBasketProduct(basketToken, giftPack.id, {
      giftPack: true,
      quantity: giftPack.quantity,
    });
  }
};

const checkForGiftPack = (product) => {
  return (
    product.gifting &&
    (product.gifting.type === GIFTING_OPTIONS.NEXT_DAY_GIFT_PACK ||
      product.gifting.type === GIFTING_OPTIONS.STANDARD_DELIVERY_GIFT_PACK)
  );
};
/**
 * update the gifting option of a specific product in a basket
 * none | e-gift | gift-pack
 *
 * On livingsocial there are only two options (none | e-gift). This is toggled by a checkbox but uses the same
 * update mechanism
 */
export const updateProductGifting = (
  basketToken,
  giftWrap,
  product,
  giftingOption,
  toast,
  giftPackPreset,
  checkoutData,
) => async (dispatch, getState) => {
  dispatch(setCheckoutStatus(CHECKOUT_STATUS.UPDATING));
  const totalProductQuantity = product.quantity;
  switch (giftingOption) {
    case GIFTING_OPTIONS.NONE: {
      // if it is currently gift-pack we need to remove the gift pack
      // if (checkForGiftPack(product)) {
      //   const addedGiftPack = getGiftPackDetails(
      //     checkoutData.deals,
      //     product.gifting.giftPackProductId,
      //   );
      //   await removeGiftPackFromBasket(
      //     basketToken,
      //     addedGiftPack,
      //     totalProductQuantity,
      //   );
      // }
      // if it is currently gift-wrap we need to remove the gift wrap
      // if (product.gifting && product.gifting.type === GIFTING_OPTIONS.WRAPPED) {
      //   // remove gift wrap quantity same as product removed quantity
      //   const newQuantity = giftWrap.quantity - product.quantity;
      //   await updateGiftWrapQuantity(basketToken, giftWrap, newQuantity);
      // }
      // clear the gifting
      await updateBasketProduct(basketToken, product.id, {
        gift: false,
        gifting: {
          gift: false,
          giftPackProductId: null,
          giftProductId: null,
          type: GIFTING_OPTIONS.NONE,
        },
      });
      break;
    }
    // case GIFTING_OPTIONS.GIFT: {
    //   // no need to remove gift pack for this option. LS only on which gift packs don't exist.
    //   await updateBasketProduct(basketToken, product.id, {
    //     gift: true,
    //   });
    //   break;
    // }
    case GIFTING_OPTIONS.E_GIFT: {
      // if it is currently gift-pack we need to remove the gift pack
      if (checkForGiftPack(product)) {
        const addedGiftPack = getGiftPackDetails(
          checkoutData.deals,
          product.gifting.giftPackProductId,
        );
        await removeGiftPackFromBasket(
          basketToken,
          addedGiftPack,
          totalProductQuantity,
        );
      }
      // if it is currently gift-wrap we need to remove the gift wrap
      // if (product.gifting && product.gifting.type === GIFTING_OPTIONS.WRAPPED) {
      //   await removeBasketProduct(basketToken, giftWrap.id);
      // }
      // change gifting to e-gift
      await updateBasketProduct(basketToken, product.id, {
        gift: true,
        gifting: {
          gift: true,
          giftPackProductId: null,
          giftProductId: null,
          type: GIFTING_OPTIONS.E_GIFT,
        },
      });
      break;
    }

    // case GIFTING_OPTIONS.NEXT_DAY_GIFT_PACK: {
    //   // add/update the gift pack

    //   if (product.gifting.giftPackProductId) {
    //     const addedGiftPack = getGiftPackDetails(
    //       checkoutData.deals,
    //       product.gifting.giftPackProductId,
    //     );
    //     await removeGiftPackFromBasket(
    //       basketToken,
    //       addedGiftPack,
    //       totalProductQuantity,
    //     );
    //   }
    //   await addGiftPackToBasket(
    //     basketToken,
    //     toast,
    //     giftPackPreset['ndd-gpk'],
    //     checkoutData,
    //     totalProductQuantity,
    //   );

    //   // remove gift wrap if any
    //   if (product.gifting && product.gifting.type === GIFTING_OPTIONS.WRAPPED) {
    //     await removeBasketProduct(basketToken, giftWrap.id);
    //   }

    //   // change gifting to gift-pack ndd
    //   await updateBasketProduct(basketToken, product.id, {
    //     gift: true,
    //     gifting: {
    //       gift: true,
    //       giftPackProductId: giftPackPreset['ndd-gpk'].productId,
    //       type: GIFTING_OPTIONS.NEXT_DAY_GIFT_PACK,
    //     },
    //   });
    //   break;
    // }
    // case GIFTING_OPTIONS.STANDARD_DELIVERY_GIFT_PACK: {
    //   // add/update the gift pack
    //   if (product.gifting.giftPackProductId) {
    //     const addedGiftPack = getGiftPackDetails(
    //       checkoutData.deals,
    //       product.gifting.giftPackProductId,
    //     );
    //     await removeGiftPackFromBasket(
    //       basketToken,
    //       addedGiftPack,
    //       totalProductQuantity,
    //     );
    //   }
    //   await addGiftPackToBasket(
    //     basketToken,
    //     toast,
    //     giftPackPreset['std-gpk'],
    //     checkoutData,
    //     totalProductQuantity,
    //   );

    //   // remove gift wrap if any
    //   if (giftWrap.id) {
    //     await removeBasketProduct(basketToken, giftWrap.id);
    //   }
    //   // change gifting to gift-pack std
    //   await updateBasketProduct(basketToken, product.id, {
    //     gift: true,
    //     gifting: {
    //       gift: true,
    //       giftPackProductId: giftPackPreset['std-gpk'].productId,
    //       type: GIFTING_OPTIONS.STANDARD_DELIVERY_GIFT_PACK,
    //     },
    //   });
    //   break;
    // }
    // case GIFTING_OPTIONS.WRAPPED: {
    //   // add/update the gift wrap quantity same as product quantity
    //   const newQuantity = giftWrap.quantity + product.quantity;
    //   await updateGiftWrapQuantity(basketToken, giftWrap, newQuantity, toast);

    //   // remove gift pack if any
    //   if (product.gifting.giftPackProductId) {
    //     const addedGiftPack = getGiftPackDetails(
    //       checkoutData.deals,
    //       product.gifting.giftPackProductId,
    //     );
    //     await removeGiftPackFromBasket(
    //       basketToken,
    //       addedGiftPack,
    //       totalProductQuantity,
    //     );
    //   }
    //   // change gifting to wrapped
    //   await updateBasketProduct(basketToken, product.id, {
    //     gift: true,
    //     gifting: {
    //       gift: true,
    //       giftProductId: giftWrap.id,
    //       type: GIFTING_OPTIONS.WRAPPED,
    //     },
    //   });
    //   break;
    // }
  }
  const newCheckoutData = await getCheckoutData(
    basketToken,
    getState().checkout.queryParams,
  );
  dispatch(newCheckoutData);
  dispatch(setCheckoutStatus(CHECKOUT_STATUS.READY));
};

/** remove excess giftPack/Wrap */
export const removeExcessGift = (bt, checkoutDeals) => async () => {
  const giftPack = getGiftPack(checkoutDeals);
  const giftWrap = getGiftWrap(checkoutDeals);
  let wasItemRemoved = false;

  // no gift pack or wrap
  if (giftPack.length <= 0 && !giftWrap) return false;

  if (giftPack.length > 0) {
    for (const giftPackItem of giftPack) {
      if (giftPackItem.quantity > 0) {
        const numberProductsWithGiftpack = getNumberProductsWithGiftpack(
          checkoutDeals,
          giftPackItem.id,
        );
        if (giftPackItem.quantity !== numberProductsWithGiftpack) {
          giftPackItem.quantity = numberProductsWithGiftpack;
          await updateGiftPackQuantity(bt, giftPackItem);
          wasItemRemoved = true;
        }
      }
    }
  }

  if (giftWrap && giftWrap.quantity) {
    const numberOfGiftwrap = getNumberOfGiftwrap(checkoutDeals);
    if (giftWrap.quantity !== numberOfGiftwrap) {
      // need to update the gift wrap number
      await updateGiftWrapQuantity(bt, giftWrap, numberOfGiftwrap);
      wasItemRemoved = true;
    }
  }

  return wasItemRemoved;
};

/** Dispatch version of removing a basket item */
export const removeProductFromBasket = (
  basketToken,
  product,
  giftPack,
  giftWrap,
) => async (dispatch, getState) => {
  dispatch(setCheckoutStatus(CHECKOUT_STATUS.UPDATING));
  if (giftPack) {
    // remove/decrement the giftPack if assigned to this product
    await removeGiftPackFromBasket(basketToken, giftPack, product.quantity);
  }
  if (product.gifting && product.gifting.type === GIFTING_OPTIONS.WRAPPED) {
    // remove/decrement the gift wrap if assigned to this product
    const newQuantity = giftWrap.quantity - product.quantity;
    await updateGiftWrapQuantity(basketToken, giftWrap, newQuantity);
  }
  // remove the actual product
  await removeBasketProduct(basketToken, product.id);
  // refresh the checkout
  const checkoutData = await getCheckoutData(
    basketToken,
    getState().checkout.queryParams,
  );
  dispatch(checkoutData);
  dispatch(setCheckoutStatus(CHECKOUT_STATUS.READY));
  trackEvent('cart_remove_item');
};

/** Dispatch remove sold out and spiked products from basket */
export const removeSoldOutSpikedFromBasket = (bt, checkoutDeals) => async (
  dispatch,
) => {
  // if any spiked or sold out
  const spikedOrSoldOutProducts = getSpikedOrSoldOutProducts(checkoutDeals);

  if (spikedOrSoldOutProducts && spikedOrSoldOutProducts.length > 0) {
    // remove the products
    for (const product of spikedOrSoldOutProducts) {
      await dispatch(removeProductFromBasket(bt, product));
    }

    return true;
  }

  return false;
};

/** Dispatch update deposit option */
export const updateProductDeposit = (bt, product, payDeposit) => async (
  dispatch,
  getState,
) => {
  dispatch(setCheckoutStatus(CHECKOUT_STATUS.UPDATING));
  await updateBasketProduct(bt, product.id, {
    payDeposit,
  });
  // refresh the checkout
  const checkoutData = await getCheckoutData(
    bt,
    getState().checkout.queryParams,
  );
  dispatch(checkoutData);
  dispatch(setCheckoutStatus(CHECKOUT_STATUS.READY));
};

/** Dispatch update delivery option type */
export const updateProductDeliveryOptionType = (
  basketToken,
  product,
  deliveryOptionType,
) => async (dispatch, getState) => {
  dispatch(setCheckoutStatus(CHECKOUT_STATUS.UPDATING));
  await updateBasketProduct(basketToken, product.id, {
    deliveryOptionType,
  });
  // refresh the checkout
  const checkoutData = await getCheckoutData(
    basketToken,
    getState().checkout.queryParams,
  );
  dispatch(checkoutData);
  dispatch(setCheckoutStatus(CHECKOUT_STATUS.READY));
};
