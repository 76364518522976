import { FOOTERS } from '../../config/constants/action-types';

const initialState = {
  show: false,
};

const footer = (state = initialState, { type, show }) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (type) {
    case FOOTERS.SET_FOOTER: {
      return {
        ...state,
        show,
      };
    }
    default:
      return state;
  }
};

export default footer;
