import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { parseWowcherPath } from '../../../../helpers/url';
import HeaderNavigationLink from '../HeaderNavigationLink';

export const DesktopTravelMenu = ({ list }) => {
  const [selectedSubCat, setSelectedSubCat] = useState('');
  const router = useRouter();
  const {
    details: { category, subCategory },
  } = parseWowcherPath(router.asPath);

  const handleHoveredSelection = (text) => {
    const hoveredText = text.toLowerCase();
    setSelectedSubCat(hoveredText);
  };

  const setPopoverMarginLeft = (index) => {
    const listLength = list.length;

    if (listLength - 2 === index) return '-79px';
    if (listLength - 1 === index) return '-214px';

    return '0';
  };

  return (
    <div className="travel-menu" data-testid="travel-menu">
      <div className="container">
        <ul className="list">
          {list?.map((listItem, index) => (
            <HeaderNavigationLink
              dataTestId={listItem.shortName}
              delay={0}
              handleSelection={handleHoveredSelection}
              href={listItem.url}
              isTravelSelected={false}
              key={listItem.shortName}
              popover={
                <div
                  className="navigation-popover"
                  data-testid="nested-list"
                  style={{ left: setPopoverMarginLeft(index) }}
                >
                  <ul className="nested-list">
                    {listItem.subNavigations?.map((subCatItem) => (
                      <HeaderNavigationLink
                        href={subCatItem.url}
                        isTravelSelected={false}
                        key={subCatItem.linkText}
                        selected={subCategory === subCatItem.shortName}
                      >
                        {subCatItem.linkText}
                      </HeaderNavigationLink>
                    ))}
                  </ul>
                </div>
              }
              selected={
                category === listItem.shortName ||
                selectedSubCat === listItem.shortName
              }
            >
              {listItem.linkText}
            </HeaderNavigationLink>
          ))}
        </ul>
      </div>
    </div>
  );
};

DesktopTravelMenu.propTypes = {
  list: PropTypes.array.isRequired,
};
