import { formatCurrency } from './currency';
import { makeUrlAbsolute } from './url';

export const getPopupWindowParameters = (config = {}) => {
  const parameters = {
    directories: 0,
    height: 600,
    left: window && window.screen ? window.screen.width / 2 - 275 : 100,
    location: 0,
    menubar: 0,
    noopener: true,
    noreferrer: true,
    scrollbars: 0,
    toolbar: 0,
    top: 100,
    width: 550,
    ...config,
  };
  const out = [];
  Object.keys(parameters).forEach((key) => {
    out.push(`${key}=${parameters[key]}`);
  });

  return out.join(',');
};

export function getNowOrFrom(deal) {
  return deal && deal.products && deal.products.length > 1 ? 'from' : 'now';
}

/** make share url absolute and appends the ito value for the platform and brand */
function getFullShareUrl(deal, ito) {
  if (!deal || !deal.shareUrl) {
    return makeUrlAbsolute('/');
  }

  return encodeURI(`${makeUrlAbsolute(deal.shareUrl)}?ito=${ito}${deal.id}`);
}
function getFullReferUrl(referralLink, ito) {
  return encodeURI(`${referralLink}&ito=${ito}`);
}

export function getFacebookShareUrl(deal, shareUrl, facebookId) {
  const APP_ID =
    facebookId[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
      process.env.NEXT_PUBLIC_SITE || 'wowcher'
    ];
  const url = getFullShareUrl(
    deal,
    shareUrl[process.env.NEXT_PUBLIC_SITE || 'wowcher'],
  );

  return `https://www.facebook.com/dialog/share?app_id=${APP_ID}&display=popup&href=${url}`;
}

export function getTwitterShareUrl(deal, shareUrl, twitterHandles) {
  const url = getFullShareUrl(
    deal,
    shareUrl[process.env.NEXT_PUBLIC_SITE || 'wowcher'],
  );

  const text = `${deal.headline} (${getNowOrFrom(deal)} ${formatCurrency(
    deal.currency,
    deal.price,
  )}) via ${twitterHandles[process.env.NEXT_PUBLIC_SITE || 'wowcher']}`;

  return `https://twitter.com/share?url=${url}&text=${encodeURIComponent(
    text,
  )}`;
}

export function getWhatsAppShareUrl(deal, shareUrl, webAddress) {
  const url = getFullShareUrl(
    deal,
    shareUrl[process.env.NEXT_PUBLIC_SITE || 'wowcher'],
  );

  return `https://wa.me/?text=${encodeURIComponent(
    `Your friend sent you a great deal on ${
      webAddress[process.env.NEXT_PUBLIC_SITE || 'wowcher']
    }: ${deal.headline}\n\n${url}`,
  )}`;
}

export function getEmailShareUrl(deal, shareUrl, webAddress) {
  const subject = `Your friend sent you a great deal on ${
    webAddress[process.env.NEXT_PUBLIC_SITE || 'wowcher']
  }: ${deal.headline}`;

  const body = `${`${deal.title}.\n\nSee deal now:`} ${getFullShareUrl(
    deal,
    shareUrl[process.env.NEXT_PUBLIC_SITE || 'wowcher'],
  )}`;

  return `mailto:?subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(body)}`;
}

export function getFacebookReferUrl(referralLink, facebookId, rafIto) {
  const APP_ID =
    facebookId[process.env.NEXT_PUBLIC_ENVIRONMENT || 'prod'][
      process.env.NEXT_PUBLIC_SITE || 'wowcher'
    ];

  const url = getFullReferUrl(referralLink, rafIto);

  return `https://www.facebook.com/dialog/share?app_id=${APP_ID}&display=popup&href=${url}`;
}

export function getTwitterReferUrl(referralLink, rafIto) {
  const url = getFullReferUrl(referralLink, rafIto);
  const text = `Make a purchase with Wowcher and we can each get up to £200! Use this link:\n${url}`;

  return `https://twitter.com/messages/compose?url=${url}&text=${encodeURIComponent(
    text,
  )}`;
}

export function getWhatsAppReferUrl(referralLink, rafIto) {
  const url = getFullReferUrl(referralLink, rafIto);
  const text = `Make a purchase with Wowcher and we can each get up to £200! Use this link: ${url}`;

  return `https://wa.me/?text=${encodeURIComponent(text)}`;
}

export function getEmailReferUrl(referralLink, rafIto) {
  const subject = `Wowcher Referral`;
  const url = getFullReferUrl(referralLink, rafIto);

  const body = `Make a purchase with Wowcher and we can each get up to £200! Use this link: ${url}`;

  return `mailto:?subject=${encodeURIComponent(
    subject,
  )}&body=${encodeURIComponent(body)}`;
}
