import Cookies from 'react-cookies';
import { LOCATIONS } from '../../config/constants/action-types';
import COOKIES from '../../config/cookies/cookies';
import { DEFAULT_LOCATION } from '../../config/setup/setup';
import { parseWowcherPath } from '../../helpers/url';
import { getStaticLocations } from '../../services/locations';

const setLocationCookie = (locationObject) => {
  // only name, shortName needed in cookie
  const cookieLocationObject = {
    lat: locationObject?.latLon?.lat,
    lon: locationObject?.latLon?.lon,
    name: locationObject.name,
    shortName: locationObject.shortName,
  };
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 1);
  Cookies.save(COOKIES.location, cookieLocationObject, {
    domain: `.${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
    expires,
    path: '/',
    samesite: 'none',
  });
};

export const setLocation = (location, overWriteCookie) => (dispatch) => {
  if (overWriteCookie) setLocationCookie(location);

  return dispatch({ location, type: LOCATIONS.SET_LOCATION });
};

export const getLocation = (locations, router) => async (dispatch) => {
  try {
    const locationCookie = Cookies.load(COOKIES.location);
    const isCookieSet = locationCookie && locationCookie !== 'undefined';

    // check if the cookie is set
    // check if site is wowcher ie then location cookie should be dublin instead of dublin-south
    if (isCookieSet) {
      // check we can find the location in the locations list
      const locationObject = locations.find(
        (loc) =>
          loc.shortName === locationCookie.shortName &&
          loc.countryCode === process.env.NEXT_PUBLIC_COUNTRY_CODE,
      );
      // only dispatch if we have a valid locationObj
      if (locationObject) {
        let overWriteCookie = false;
        // overwrite the cookie if we have a lat/lon and the cookie has none
        if (
          !locationCookie?.lat &&
          locationObject?.latLon?.lat &&
          locationObject?.latLon?.lon
        ) {
          overWriteCookie = true;
        }
        dispatch(setLocation(locationObject, overWriteCookie));
      }
    } else {
      // the cookie hasn't been set so we must set it to the location in the landing page, falling back to the default.
      // we store in the new cookie
      const { details } = parseWowcherPath(router.asPath);
      let location = DEFAULT_LOCATION;
      if (details?.localDealLocation) {
        const locationObject = locations.find(
          (loc) =>
            loc.shortName === details.localDealLocation &&
            loc.countryCode === process.env.NEXT_PUBLIC_COUNTRY_CODE,
        );
        if (locationObject) {
          location = locationObject;
        }
      }
      dispatch(setLocation(location, true));
    }

    return true;
  } catch {
    // TODO: Show error message if needed
    return false;
  }
};

export const setLocations = (locations) => (dispatch) => {
  return dispatch({ locations, type: LOCATIONS.SET_LOCATIONS });
};

export const getLocations = () => async (dispatch) => {
  try {
    // const result = await axios(URLLOCATIONS, {
    //   headers: {
    //     'app-platform': getAppPlatform(),
    //     brand: process.env.NEXT_PUBLIC_BRAND,
    //     'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
    //     version: 'v1.1',
    //     'sub-brand': '5pm',
    //     webapp: true,
    //   },
    //   method: 'GET',
    //   withCredentials: true,
    // });
    const locations = (await getStaticLocations()) || [];
    dispatch(setLocations(locations));

    return locations;
  } catch {
    // TODO: Show error message if needed
    return [];
  }
};
