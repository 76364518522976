import { DEAL_LOCATION_TYPES } from '../config/constants/page-types';
import {
  NUMBER_OF_RECENTLY_VIEWED_DEALS,
  RECENT_DEALS_LOCAL_STORE,
} from '../config/setup/setup';
import { parseWowcherPath } from './url';
/**
 * Store a main deal in localstorage
 */
export const storeMainDeal = (mainDealId) => {
  if (!localStorage) return;

  const storedDeals = JSON.parse(
    localStorage.getItem(RECENT_DEALS_LOCAL_STORE) || null,
  );
  const dealObject = { id: mainDealId };

  if (!storedDeals) {
    localStorage.setItem(
      RECENT_DEALS_LOCAL_STORE,
      JSON.stringify({
        recentDeals: [dealObject],
        storedDate: Date.now(),
      }),
    );

    return;
  }

  // deal already stored and at index 0
  if (storedDeals.recentDeals.findIndex((item) => item.id === mainDealId) === 0)
    return;

  storedDeals.recentDeals = storedDeals.recentDeals.filter(
    (item) => item.id !== mainDealId,
  );
  // add deal to beginning of array
  storedDeals.recentDeals.unshift(dealObject);
  storedDeals.recentDeals.slice(0, NUMBER_OF_RECENTLY_VIEWED_DEALS - 1);
  storedDeals.storedDate = Date.now();

  localStorage.setItem(RECENT_DEALS_LOCAL_STORE, JSON.stringify(storedDeals));
};

/**
 * returns the stored deals
 */
// export function getStoredDeals() {
export const getIdsStoredDeals = () => {
  if (typeof window === 'undefined') return;
  const storedDeals = JSON.parse(
    localStorage.getItem(RECENT_DEALS_LOCAL_STORE) || null,
  );
  if (!storedDeals) return;

  return storedDeals.recentDeals.map((deal) => deal.id);
};

export const getStoredDealsFromLocalStorage = () => {
  const ids = getIdsStoredDeals();

  return ids ? `?id=${ids.join('&id=')}` : [];
};

/**
 * Stores the ID of a recently viewed deal in the appropriate category in localStorage.
 *
 * @param {number} dealId - The ID of the deal to store.
 */
export const storeDealIdByCategory = (dealId) => {
  const url = window.location.pathname;
  if (!url) return;
  const { details } = parseWowcherPath(url);
  const { locationType, isTravel } = details;

  const categoryType =
    locationType || (isTravel ? 'travel' : DEAL_LOCATION_TYPES.national);
  const storageKey = `RECENT_DEALS_${categoryType.toUpperCase()}`;
  let storedDeals = JSON.parse(localStorage.getItem(storageKey) || '[]');

  if (!storedDeals.includes(dealId)) {
    storedDeals.unshift(dealId);
    storedDeals = storedDeals.slice(0, 5); // Keep only the first 5 deals
    localStorage.setItem(storageKey, JSON.stringify(storedDeals));
  }
};

/**
 * Retrieves the stored deal IDs for a specific category type from localStorage.
 *
 * @param {string} categoryType - The category type of the deals ('local', 'national', 'travel').
 * @returns {number[]} An array of up to 5 deal IDs stored for the specified category type.
 */
export const getStoredDealsByCategory = (categoryType) => {
  const storageKey = `RECENT_DEALS_${categoryType.toUpperCase()}`;
  const storedDeals = JSON.parse(localStorage.getItem(storageKey) || '[]');

  return storedDeals.slice(0, 5); // Return only the first 5 deal IDs
};
