import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { parseWowcherPath } from '../../../../helpers/url';
import HeaderNavigationLink from '../HeaderNavigationLink';
import { CustomToggle } from './CustomToggle';

export const MobileTravelMenu = ({ list }) => {
  const router = useRouter();
  const {
    details: { subCategory },
  } = parseWowcherPath(router.asPath);

  return (
    <>
      <div className="travel-menu-accordion">
        <Accordion>
          <CustomToggle
            buttonText={subCategory || 'All travel deals'}
            eventKey="0"
          />
          <Accordion.Collapse eventKey="0">
            <Accordion>
              {list?.map((listItem) => (
                <div key={listItem.linkText}>
                  <CustomToggle
                    buttonText={listItem.linkText}
                    eventKey={listItem.id}
                  />
                  <Accordion.Collapse eventKey={listItem.id}>
                    <div>
                      {listItem.subNavigations?.map((subCategoryLink) => (
                        <HeaderNavigationLink
                          href={subCategoryLink.url}
                          key={subCategoryLink.id}
                        >
                          {subCategoryLink.linkText}
                        </HeaderNavigationLink>
                      ))}
                    </div>
                  </Accordion.Collapse>
                </div>
              ))}
            </Accordion>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </>
  );
};

MobileTravelMenu.propTypes = {
  list: PropTypes.array.isRequired,
};
