/* eslint-disable eslint-comments/disable-enable-pair */
import useSWR from 'swr';
import axios from '../components/_generic/axiosSplunk/axiosSplunk';
import {
  URLREWARDSDETAILS,
  URLEXCHANGEPOINTS,
  URL_SHARE_POINTS,
  URL_VIEW_POINTS,
  URL_BULK_POINTS,
} from '../config/setup/setup';
import { trackError } from '../helpers/analytics';
import httpCommonHeaders from '../helpers/httpCommonHeaders';

export const CLUB_WOWCHER_LEVELS = {
  Bronze: 'bronze',
  Gold: 'gold',
  Pink: 'pink',
  Platinum: 'platinum',
  Silver: 'silver',
};

const fetchRewardsDetails = async (url) => {
  const { data } = await axios({
    headers: httpCommonHeaders(),
    method: 'GET',
    url,
    withCredentials: true,
  });

  const {
    currentLevel,
    lifetimePoints,
    nextLevel,
    pointsToNextLevel,
    exchangeRate,
    currentPoints,
    loyaltyActivityDescriptions,
    playerActivityCountVO: { points },
  } = data;
  const level = CLUB_WOWCHER_LEVELS[currentLevel] || CLUB_WOWCHER_LEVELS.Pink;

  return {
    currentLevel,
    currentPoints,
    exchangeRate,
    level,
    lifetimePoints,
    loyaltyActivityDescriptions,
    nextLevel,
    points,
    pointsToNextLevel,
  };
};

export const getPointsInputError = (points) => {
  if (points === 0) return `You don't have enough points`;

  return `Please select a number between 1 and ${points} to exchange your points.`;
};

export const getLevelText = ({
  selectedPoints,
  nextLevel,
  pointsToNextLevel,
  currentLevel,
  exchangeRate,
}) => {
  let result = {
    exchangeInfo: null,
    text: null,
  };
  const exchangeInfo = `You can exchange points at the <b>${currentLevel}</b> exchange rate. How many points would you like to exchange?`;

  if (currentLevel === 'Pink') {
    result = {
      text: `You currently have <b>${selectedPoints}</b> points. You need to be on <b>${nextLevel?.name}</b> level or above to exchange your points for Wallet Credit. You need <b>${pointsToNextLevel}</b> more points to reach <b>${nextLevel?.name}</b>.`,
    };
  }

  if (
    currentLevel === 'Bronze' ||
    currentLevel === 'Silver' ||
    currentLevel === 'Gold'
  ) {
    const currentConvertedAmount = (selectedPoints * exchangeRate).toFixed(2);
    const nextLevelConvertedAmount = (
      selectedPoints * nextLevel.exchangeRate
    ).toFixed(2);

    const currentLevelConvertedAmount = Number.isNaN(currentConvertedAmount)
      ? 0
      : currentConvertedAmount;
    const nextLevelConvertedAmoubnt = Number.isNaN(nextLevelConvertedAmount)
      ? 0
      : nextLevelConvertedAmount;

    result = {
      ...result,
      exchangeInfo,
      text: `At the <b>${currentLevel}</b> exchange rate this would convert to <b>£${currentLevelConvertedAmount}</b>. At the next level, <b>${nextLevel?.name}</b>, this will convert to <b>£${nextLevelConvertedAmoubnt}</b> Wallet Credit.`,
    };
  }

  if (currentLevel === 'Platinum') {
    const currentLevelConvertedAmount = (selectedPoints * exchangeRate).toFixed(
      2,
    );

    result = {
      ...result,
      exchangeInfo,
      text: `At the <b>${currentLevel}</b> exchange rate this would convert to <b>£${currentLevelConvertedAmount}</b>.`,
    };
  }

  return result;
};

export const useClubWowcherDetails = () => {
  const { data, error } = useSWR(URLREWARDSDETAILS, fetchRewardsDetails);

  return { error, loading: !data && !error, ...data };
};

export const exchangePoints = async (points) => {
  let result = null;

  try {
    const response = await axios({
      headers: httpCommonHeaders(),
      method: 'POST',
      url: `${URLEXCHANGEPOINTS}/${points}`,
      validateStatus: (status) => {
        return status < 500;
      },
      withCredentials: true,
    });

    result = response;
  } catch (error) {
    trackError({
      message: 'club wowcher issue',
      response: error,
    });
    result = error;
  }

  return result;
};

export const sendRewardShareDealPoints = async (sharedDealData) => {
  let response;

  try {
    response = await axios({
      cache: false,
      data: {
        shareDeal: {
          ...sharedDealData,
        },
      },
      headers: httpCommonHeaders(),
      method: 'POST',
      url: URL_SHARE_POINTS,
      validateStatus: (status) => {
        return status < 500;
      },
      withCredentials: true,
    });
  } catch (error) {
    console.error(error);
    response = error;
  }

  return response;
};

export const sendViewDealPoints = async (viewDealData) => {
  let response;

  try {
    response = await axios({
      cache: false,
      data: {
        viewDeal: {
          ...viewDealData,
        },
      },
      headers: httpCommonHeaders(),
      method: 'POST',
      url: URL_VIEW_POINTS,
      validateStatus: (status) => {
        return status < 500;
      },
      withCredentials: true,
    });
  } catch (error) {
    console.error(error);
    response = error;
  }

  return response;
};

export const sendBulkDealsPoints = async (bulkData) => {
  let response;

  try {
    const result = await axios({
      cache: false,
      data: bulkData,
      headers: httpCommonHeaders(),
      method: 'POST',
      url: URL_BULK_POINTS,
      validateStatus: (status) => {
        return status < 500;
      },
      withCredentials: true,
    });

    response = result;
  } catch (error) {
    trackError({
      message: 'club 5pm issue',
      response: error,
    });
    response = error;
  }

  return response;
};
