import { faUser } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import Icon from '../components/Icon';
import { LINK_LOGIN } from '../config/links/links';
import { BRAND_FIVEPM, BRAND_WOWCHER } from '../config/setup/setup';
import { redirectToURL } from './url';

export const getOperationsOnWishlist = (wishlist, deals) => {
  const operations = [];
  wishlist
    .filter((wishlistDeal) =>
      deals.some((deal) => wishlistDeal?.id === deal?.id),
    )
    .map((match) => {
      operations.push({
        op: 'remove',
        path: '/deals',
        value: match.id,
      });
    });

  return operations;
};

export const getConcatDeals = (dealData) => {
  return dealData
    ?.map((deal) => {
      return `&id=${deal.id}`;
    })
    .join('');
};

export const LoggedIn = ({ isAuthenticated, router, theme }) => {
  return (
    !isAuthenticated && (
      <div
        className="login-icon"
        data-qa="wishLogin"
        onClick={() => {
          redirectToURL(LINK_LOGIN, router);
        }}
        onKeyDown={() => {
          redirectToURL(LINK_LOGIN, router);
        }}
        role="button"
        tabIndex="0"
      >
        <Icon
          color={
            process.env.NEXT_PUBLIC_BRAND === BRAND_FIVEPM
              ? theme.colors.white
              : theme.colors.buttonprimary
          }
          faIcon={faUser}
          size="30px"
        />
        <div className="login-message">Login to see your full wish list</div>
        <style jsx>{`
          .login-icon {
            min-height: 5vh;
            text-align: center;
          }
          .login-message {
            min-height: 10vh;
            font-size: 2rem;
            color: ${process.env.NEXT_PUBLIC_BRAND === BRAND_FIVEPM
              ? theme.colors.white
              : theme.colors.buttonprimary};
          }
        `}</style>
      </div>
    )
  );
};

LoggedIn.propTypes = {
  isAuthenticated: PropTypes.bool,
  router: PropTypes.object,
  theme: PropTypes.object,
};
