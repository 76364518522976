import { logToSplunk } from '../components/_generic/axiosSplunk/axiosSplunk';

const errorTrackingService = {};

errorTrackingService.logError = (error) => {
  console.log(error);

  let userAgent = '';
  let referrer = '';
  let client = 'false';
  let server = 'true';
  let name = 'Error';
  let message = 'Javascript error';
  if (typeof window !== 'undefined') {
    userAgent = window.navigator.userAgent;
    referrer = document.referrer;
    client = 'true';
    server = 'false';
  }
  // error is an object
  if (error?.name) name = error.name;
  if (error?.message) message = error.message;

  logToSplunk({
    client,
    description: 'Javascript error',
    error: JSON.stringify(error),
    message,
    name,
    referrer,
    server,
    userAgent,
  });
};

export default errorTrackingService;
