import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

export const CustomToggle = ({ eventKey, buttonText }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const stateIcon = isCurrentEventKey ? faAngleUp : faAngleDown;
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log(eventKey),
  );

  return (
    <button
      className="custom-toggle"
      onClick={decoratedOnClick}
      style={{ borderBottom: isCurrentEventKey ? '0' : '1px solid #ccc' }}
      type="button"
    >
      {buttonText}
      <FontAwesomeIcon icon={stateIcon} />
    </button>
  );
};
