import { FREE } from '../config/text/text';
import { isMobileViewport } from './screen';

export const getCountryCurrency = () => {
  // TODO: Add more currencies
  const currencyPound = (process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb') === 'gb';

  return currencyPound ? '£' : '€';
};

export const getCountryCurrencyCode = () => {
  // TODO: Add more currencies
  if (process.env.NEXT_PUBLIC_COUNTRY_CODE === 'gb') return 'GBP';
  if (process.env.NEXT_PUBLIC_COUNTRY_CODE === 'ie') return 'EUR';

  return 'GBP';
};

/**
 * returns the currency sign from the currency string
 * ie. gbp => £
 */
export const symbolFromCurrency = (currency) => {
  // TODO: Add more currencies
  if (currency?.toLowerCase() === 'eur') return '€';

  return '£';
};

/**
 * Rounds price to maximum of 2 decimal places, e.g. 100 => 100, 100.3301 => 100.33
 * Only use this function to fix precision of price floats, e.g. after adding up two prices, do not use it for formatting
 *
 * @param price
 * @returns {number}
 */

export const fixPrecision = function (price) {
  return Math.round(price * 100) / 100;
};

/**
 * Formats a number into a currency. ints are displayed as is and
 * floats are formatted to 2dp (unless fixed).
 *
 * @param {number} number   the number to display
 * @param {boolean} fixed   always show to 2 dp
 * @param {boolean} round   whether to round number
 * @param {boolean} roundMobile whether to round mobile numbers FEF-2090, FEF-2639
 */
// eslint-disable-next-line max-params
export const formatPrice = (
  number,
  fixed,
  round = false,
  roundMobile = true,
) => {
  if (typeof number !== 'number') {
    return '0.00';
  } else if (!fixed && number % 1 === 0) {
    return number.toString();
  } else {
    if (number.toString().length > 5 && isMobileViewport() && roundMobile) {
      return Math.round(number).toString();
    }

    return round
      ? (Math.round(number * 100) / 100).toFixed(2)
      : number.toFixed(2);
  }
};

/**
 *
 * @param {string} currency   the currency
 * @param {number} number        the numeric value
 * @param {boolean} fixed     should it be fixed to 2dp?
 * @param {boolean} freeText  should the word FREE be used for 0?
 * @returns {string} the formatted price
 */
export const formatCurrency = (
  currency,
  number,
  fixed,
  freeText,
  isRounded = true,
) => {
  if (number === 0 && freeText) {
    return FREE;
  } else {
    const sign = number < 0 ? '-' : '';
    const symbol = symbolFromCurrency(currency);
    const price =
      number < 0
        ? formatPrice(Math.abs(number), fixed)
        : formatPrice(number, fixed, false, isRounded);

    return `${sign}${symbol}${price}`;
  }
};
