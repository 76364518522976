/* eslint-disable filenames/match-exported */
import { FILTERS } from '../../config/constants/action-types';

const initialState = {
  url: '',
};

const dealsReducer = (state = initialState, { type, url }) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (type) {
    case FILTERS.SET_URL_PATH: {
      return {
        ...state,
        url,
      };
    }
    default:
      return state;
  }
};

export default dealsReducer;
