import { MERCHANT } from '../../config/constants/action-types';
import { MERCHANT_URL } from '../../config/setup/setup';

const initialState = {
  url: MERCHANT_URL[process.env.NEXT_PUBLIC_ENVIRONMENT],
};

// eslint-disable-next-line default-param-last
const merchant = (state = initialState, { type, url }) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (type) {
    case MERCHANT.SET_MERCHANT: {
      return {
        ...state,
        url: url || MERCHANT_URL[process.env.NEXT_PUBLIC_ENVIRONMENT],
      };
    }
    default:
      return state;
  }
};

export default merchant;
