import Cookies from 'react-cookies';
import COOKIES from '../config/cookies/cookies';

export const isIE = () => {
  if (typeof window === 'undefined') return false;

  return (
    window.navigator.userAgent.includes('Microsoft Internet Explorer') ||
    window.navigator.userAgent.includes('Trident')
  );
};

export const isSamsung = () => {
  if (typeof window === 'undefined') return false;

  return window.navigator.userAgent.includes(`SamsungBrowser`);
};

export const isLightPage = () => {
  return Cookies.load(COOKIES.lightPage) === 'true';
};

export const isBotRequest = (userAgent) => {
  return /adsbot-google|adsbot-google-mobile|bingbot|googlebot|googlebot-image|googlebot-video|applebot|slurp|duckduckbot|chrome-lighthouse|prerender|google page speed insights/i.test(
    userAgent,
  );
};
